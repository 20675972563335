import { call, takeEvery, put } from 'redux-saga/effects';

import { API_ENDPOINTS } from '../constants/APi';

import { IGoal } from '../types/IGoal';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';
import { http } from '../utils/requests';

const resource = 'ALL_GOALS';
const actions = ['get'];
type reducerType = IGoal[];

export const allGoalsTypes = createActionTypes(resource, actions);
export const allGoalsActions = createActions<reducerType>(resource, actions);
export const allGoalsReducer = createReducers<reducerType>(resource, actions);

function* get() {
    try {
        const response = yield call(http.get, API_ENDPOINTS.GOAL_ALL, undefined, undefined);
        yield put(allGoalsActions.get.success(response));
    } catch (err) {
        yield put(allGoalsActions.get.fail(err));
    }
}

export function* allGoalsSaga() {
    yield takeEvery(allGoalsTypes.get.load, get);
}
