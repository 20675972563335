import { toast } from 'react-toastify';
import { call, takeEvery, put } from 'redux-saga/effects';

import { API_ENDPOINTS } from '../constants/APi';

import { IGoal, IGoalSetting } from '../types/IGoal';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';
import { http } from '../utils/requests';
import { getStore } from '../store/store';
import { assignFiltersParams } from '../utils/filters.util';
import { allGoalsActions } from './allGoals.saga';

const resource = 'GOAL';
const actions = ['get', 'update'];
type reducerType = IGoalSetting;

interface IGoalUpdateAction {
  payload: IGoalSetting[];
}

export const goalTypes = createActionTypes(resource, actions);
export const goalActions = createActions<reducerType>(resource, actions);
export const goalReducer = createReducers<reducerType>(resource, actions);

function* getGoal() {
  try {
    const { profile, goalSettingsFilters } = getStore();
    // @ts-expect-error
    const defaultQuery = (goalSettingsFilters?.companies?.length > 0 || goalSettingsFilters?.users?.length > 0) ? '' : `?companies=${profile.company?._id}&time=${new Date().getFullYear()}`;
    const query = assignFiltersParams(goalSettingsFilters || {}, defaultQuery)
    const response = yield call(http.get, API_ENDPOINTS.GOAL, undefined, undefined, query);
    yield put(goalActions.get.success(response));
  } catch (err) {
    yield put(goalActions.get.fail(err));
  }
}

function* updateGoal({ payload }: IGoalUpdateAction) {
  try {
    const { message, data } = yield call(http.patch, API_ENDPOINTS.GOAL, payload);
    yield put(goalActions.update.success({ data }));

    toast.success(message || 'Goal has been updated successfully');
    yield put(allGoalsActions.get.load());
  } catch (err) {
    console.log(err);
    yield put(goalActions.update.fail(err));

    toast.error('Goal update failed');
  }
}

export function* goalSaga() {
  yield takeEvery(goalTypes.get.load, getGoal);
  yield takeEvery(goalTypes.update.update, updateGoal);
}
