import moment from 'moment-timezone';

import { APP_CONSTANTS } from '../constants/global';

moment.tz.setDefault(APP_CONSTANTS.TIME_ZONE);

// @ts-ignore
window.moment = moment;

export default moment;
