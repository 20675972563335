import { call, takeEvery, put } from 'redux-saga/effects';

import { API_ENDPOINTS } from '../constants/APi';

import {IReportsGroupByType} from '../types/IReport';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';
import { http } from '../utils/requests';
import { getStore } from '../store/store';
import { assignFiltersParams } from '../utils/filters.util';

import { companyProjectionPaginationActions } from './companyProjectionPagination.saga';

const resource = 'COMPANY_PROJECTION_REPORTS_GROUP_BY_DATE';
const actions = ['get', 'update', 'delete'];
type reducerType = IReportsGroupByType[];

export const companyProjectionGroupedReportsTypes = createActionTypes(resource, actions);
export const companyProjectionGroupedReportsActions = createActions<reducerType>(resource, actions);
export const companyProjectionGroupedReportsReducer = createReducers<reducerType>(resource, actions);

function* getCompanyProjectionGroupedReports() {
  try {
    const { companyProjectionPagination: pagination, companyProjectionFilters } = getStore();

    const query = companyProjectionFilters
      ? assignFiltersParams(companyProjectionFilters, `?periodGrouped=true&page=${pagination.activePage}`)
      : `?periodGrouped=true&page=${pagination.activePage}`;

    const { type, reports, pages, prevPage, nextPage, creators } = yield call(http.get, API_ENDPOINTS.REPORTS_GROUPED, undefined, undefined, query);

    yield put(companyProjectionGroupedReportsActions.get.success({ type, reports, creators }));
    yield put(companyProjectionPaginationActions.set.update({
      activePage: pagination.activePage,
      pages,
      prevPage,
      nextPage,
    }))
  } catch (err) {
    yield put(companyProjectionGroupedReportsActions.get.fail(err));
  }
}

export function* companyProjectionGroupedReportsSaga() {
  yield takeEvery(companyProjectionGroupedReportsTypes.get.load, getCompanyProjectionGroupedReports);
}
