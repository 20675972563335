import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';

import { IStore } from '../types/IStore';
import { getInitStore } from '../utils/initStore.util';
import { rootReducer } from './rootReducer';
import rootSaga from './rootSaga';

export const history = createBrowserHistory();

const routerMiddleware = createRouterMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  // @ts-ignore
  getInitStore(),
  composeWithDevTools(applyMiddleware(routerMiddleware, sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export default store;

export const getStore = () => (store.getState() as unknown) as IStore;
