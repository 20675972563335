export const THEME = {
  LIGHT: 'light',
  DARK: 'dark',
};

export const DEFAULT_COLOR = {
  PRIMARY_COLOR: '#00467F',
  THEME: THEME.LIGHT,
  REGULAR_TEXT_COLOR: '#535353',
  SECONDARY_TEXT_COLOR: '#535353',
};
