import React from "react";
import moment from "moment";

import styles from "./MonthlyTable.module.scss";
import { IGoal } from "../../../types/IGoal";

interface IProps {
  editing: boolean;
  percentDollars: IGoal;
  indexOrder: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, month: number) => void;
}

const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const getMonthName = (monthNumber: number) => {
  return moment()
    .month(monthNumber - 1)
    .format("MMM");
};

const MonthlyTable = (props: IProps) => {
  const { editing, percentDollars, indexOrder, onChange } = props;
  
  const percentTotal = React.useMemo(() => {
    let total = 0;
    const values = percentDollars.items[indexOrder - 1].values;
    if(values) {
      values.map(el => total += Number(el.value));
    }
    
    return total.toFixed(2);
  }, [percentDollars]);

  const getValue = (value: any, editing: boolean) => {
    if(editing) {
      if(value === null) {
        return '0.00%';
      }
      if(value === '') {
        return undefined;
      }
      return value;
    } else {
      if(value === null) {
        return '0.00%';
      }
      if(value === '') {
        return undefined;
      }
      return value.toFixed(2) + '%';
    }
  }

  return (
    <div className={styles.monthlyTable}>
      <div className={styles.row}>
        {months.map((month) => (
          <div className={`${styles.column} ${styles.header}`}>
            <div key={month} className={`${styles.headerItem}`}>
              {getMonthName(month)}
            </div>
          </div>
        ))}

        <div className={`${styles.column} ${styles.header}`}>
          <div className={`${styles.headerItem}`}>Totals</div>
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.row}>
          {months.map((month) => {
            const matchingGoal = percentDollars.items[indexOrder - 1].values;
            if (matchingGoal) {
              // Month index start from zero in server
              const matchingValues = matchingGoal.find(
                (item) => item.month === month - 1
              );
              if (matchingValues) {
                return (
                  <div
                    key={percentDollars._id}
                    className={`${styles.column} ${styles.monthlyItem}`}
                  >
                    <input
                      onChange={(e) => onChange(e, month)}
                      type='text' 
                      maxLength={6}
                      value={getValue(matchingValues.value, editing)}
                      className={styles.goalInput}
                      disabled={!editing}
                    />
                  </div>
                );
              }
            }

            return (
              <div
                key={`percent-${month}`}
                className={`${styles.column} ${styles.monthlyItem}`}
              >
                <input
                  onChange={(e) => onChange(e, month)}
                  type="number"
                  value={undefined}
                  className={styles.goalInput}
                  disabled={!editing}
                />
              </div>
            );
          })}

          <div className={`${styles.column} ${styles.monthlyItem}`}>
            <input
              value={`${percentTotal}%`}
              className={styles.goalInput}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthlyTable;
