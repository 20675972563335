export function formatNumber(num: number, decimalCount: number = 0, decimal = ',', thousands = '.') {
    try {
        let amount = num;

        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? '-$' : '$';

        amount = Math.abs(Number(amount) || 0);
        const i = parseInt(amount.toFixed(decimalCount)).toString();
        const j = i.length > 3 ? i.length % 3 : 0;

        const result =
            negativeSign +
            (j ? i.substr(0, j) + thousands : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
            (decimalCount
                ? decimal +
                Math.abs(amount - parseInt(i))
                    .toFixed(decimalCount)
                    .slice(2)
                : '');
        return result;
    } catch (e) {
        console.log(e);
    }
}