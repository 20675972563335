import React, { FC } from 'react';
import styles from './ConfirmationModal.module.scss';

interface IOuterProps {
  onClose: () => void;
  onSave: () => void;
}

const ConfirmationModal: FC<IOuterProps> = ({
  onClose,
  onSave
}) => {
  const onClickSave = () => {
    onClose();
    onSave();
  }

  return (
    <div className={styles.modal}>
      <div className={styles.content}>
        Are you sure you want to save the goals changes?
      </div>
      <div className={styles.actions}>
        <button type="button" className={`${styles.button} ${styles.no}`} onClick={onClose}>No</button>
        <button type="button" className={styles.button} onClick={onClickSave}>Save</button>
      </div>
    </div>
  )
}

export default ConfirmationModal;