import { call, takeEvery, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { API_ENDPOINTS } from '../constants/APi';

import { IReport, INewReportResponse } from '../types/IReport';

import { recentReportsActions } from './recentReports.saga';
import { reportsActions } from './reports.saga';
import { totalsActions } from './totals.saga';
import { aggregatedDataActions } from './aggregatedData.saga';
import { groupedReportsActions } from './groupedByDateReports.saga';

import { createActionTypes, createActions, createReducers } from '../utils/reduxBoilerplate';
import { http } from '../utils/requests';
import { getError } from '../utils/valueState';
import { getStore } from '../store/store';

interface IActiveReport {
  active: boolean;
}

const resource = 'ACTIVE_REPORT';
const actions = ['openForm', 'get', 'add', 'update', 'delete'];
type reducerType = IReport & IActiveReport;

export const activeReportTypes = createActionTypes(resource, actions);
export const activeReportActions = createActions<reducerType>(resource, actions);
export const activeReportReducer = createReducers<reducerType>(resource, actions);

function* toggleReportForm({payload}: {payload: boolean}) {
  yield put(activeReportActions.add.success(payload));
}

function* getReport({payload}: {payload: number}) {
  try {
    const response = yield call(http.get, API_ENDPOINTS.GET_REPORT + payload);

    yield put(activeReportActions.get.success(response));
  } catch (err) {
    yield put(activeReportActions.get.fail(err));
  }
}

function* addNewReport({payload}: {payload: IReport}) {
  try {
    const response: INewReportResponse = yield call(http.post, API_ENDPOINTS.REPORTS, payload);
    const { message } = response;

    toast.success(message);

    yield put(recentReportsActions.get.load());
    yield put(groupedReportsActions.get.load());
    yield put(activeReportActions.openForm.update(false));
  } catch (err) {
    yield put(activeReportActions.add.fail(err));
    const { activeReport } = getStore();
    if ( getError(activeReport)?.message ) {
      toast.error(getError(activeReport).message);
    }
  }
}

function* updateReport({payload}: {payload: IReport}) {
  try {
    const response = yield call(http.patch, API_ENDPOINTS.REPORTS, payload);

    toast.success(response);

    yield put(recentReportsActions.update.update(payload));
    yield put(reportsActions.update.update(payload));
    yield put(groupedReportsActions.update.update(payload));
    yield put(activeReportActions.openForm.update(false));
    yield put(totalsActions.get.load());
    yield put(aggregatedDataActions.get.load());
  } catch (err) {
    yield put(activeReportActions.update.fail(err));
    yield put(activeReportActions.openForm.update(false));

    const { activeReport } = getStore();

    if ( getError(activeReport)?.message ) {
      toast.error(getError(activeReport).message);
    }
  }
}

function* deleteReport({payload}: {payload: string}) {
  try {
    const response = yield call(http.delete, API_ENDPOINTS.REPORTS, {reportID: payload});

    toast.success(response);

    yield put(recentReportsActions.delete.update(payload));
    yield put(reportsActions.delete.update(payload));
    yield put(groupedReportsActions.delete.update(payload));
    yield put(activeReportActions.openForm.update(false));
    yield put(totalsActions.get.load());
    yield put(aggregatedDataActions.get.load());
  } catch (err) {
    yield put(activeReportActions.delete.fail(err));
    yield put(activeReportActions.openForm.update(false));

    const { activeReport } = getStore();

    if ( getError(activeReport)?.message ) {
      toast.error(getError(activeReport).message);
    }
  }
}

export function* activeReportSaga() {
  yield takeEvery(activeReportTypes.openForm.update, toggleReportForm);
  yield takeEvery(activeReportTypes.get.load, getReport);
  yield takeEvery(activeReportTypes.add.load, addNewReport);
  yield takeEvery(activeReportTypes.update.update, updateReport);
  yield takeEvery(activeReportTypes.delete.update, deleteReport);
}
