import React, { Component, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import DatePicker, { range, getYear, getMonth } from 'react-datepicker';
import { Dispatch } from 'redux';

import { PICKER } from '../../../constants/datepicker.config';
import { FILTER_QUICK_PERIODS } from '../../../constants/Filters';
import { REPORT_EXPORT_TYPES } from '../../../constants/report';

import { IStore } from '../../../types/IStore';
import { IProfile } from '../../../types/IProfile';
import { IFilters } from '../../../types/IFilters';
import { ACCESS_LEVEL, IUser } from '../../../types/IUser';
import { ICompany } from '../../../types/ICompany';

import { filtersActions } from '../../../sagas/filters.saga';

import { isReady } from '../../../utils/valueState';
import { isCompanyAdmin, isCompanyAdminOrLower, isSeniorSalesManager, isSeniorSalesManagerOrHigher, isSuperAdmin } from '../../../utils/user.util';
import moment from '../../../utils/moment';
import { getQuickFilterDate } from '../../../utils/dates';
import { exportReport } from '../../../utils/report.util';

import styles from './Filters.module.scss';
import { IAggregatedData, IGroupId, IReportData } from '../../../types/IReport';
import { percentAnalyticsDataActions } from '../../../sagas/percentAnalyticsData.saga';

interface IPropsFromStore {
  profile: IProfile;
  users: IUser[];
  companies: ICompany[];
  aggregatedData: (IReportData & IAggregatedData)[];
}

interface IPropsFromOutside {
  label: string;
  isShowExportBtn: boolean;
}

interface IDispatchProps {
  setFilters: (payload: IFilters) => void;
  getPercentAnalyticsData: (payload) => any;
}

const mapStateToProps = ({ profile, users, companies, aggregatedData}: IStore): IPropsFromStore => ({
  profile,
  users,
  companies,
  aggregatedData
});

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  setFilters: (payload: IFilters) =>
    dispatch(filtersActions.set.update(payload)),
  getPercentAnalyticsData: (payload) =>
    dispatch(percentAnalyticsDataActions.get.load()),
});

type IProps = IPropsFromStore & IDispatchProps & IPropsFromOutside;

interface IState {
  increased: boolean;
  from: string | number | null;
  to: string | number | null;
  companiesField: string;
  usersField: string;
  selectedCompanies: ICompany[];
  usersIDs: string[];
  companiesIDs: string[];
  isShowSelected: boolean;
  companies: ICompany[];
  pdfExportEnabled: boolean | null;
  isExcludeDeactivated: boolean;
}

class Filters extends Component<IProps, IState> {
  public state: Readonly<IState> = {
    increased: false,
    from: '',
    to: '',
    companiesField: '',
    usersField: '',
    selectedCompanies: [],
    usersIDs: [],
    companiesIDs: [],
    isShowSelected: false,
    companies: [],
    pdfExportEnabled: null,
    isExcludeDeactivated: true,
  };
  private companiesParticipantsAdded: boolean = false;

  public componentDidMount(): void {
    const { companies, users, profile } = this.props;
    
    if ( isReady(companies) && isReady(users) ) {
      this.calcCompaniesParticipants();
    }
    if ( isReady(profile) && isReady(users) ) {
      const { profile } = this.props;
      const { companiesIDs } = this.state;
      if(companiesIDs.length === 0) {
        const defaultSelectedUsers = profile.company?.participants?.filter(
          (participant: string) => {
            const user = this.props.users.find((user) => user._id === participant);
            if (user) {
              return ![ACCESS_LEVEL.COMPANY_ADMIN, ACCESS_LEVEL.SUPER_ADMIN].includes(
                user.accessLevel
              )
            }
            return true;
          }
        ) || [];

        let renderedUsers: IUser[] = [];
        if (isSuperAdmin(profile)) {
          renderedUsers = users;
        } else if (isCompanyAdmin(profile)) {
          renderedUsers = users.filter(
            (user) => user.company === profile.company?._id
          );
        } else if (isSeniorSalesManager(profile)) {
          const assignedUsers = profile.assignedUsers;
          renderedUsers = assignedUsers
            ? users.filter((user) => assignedUsers.includes(user._id))
            : [];
        }
        const userForFilter = defaultSelectedUsers.filter(user => renderedUsers.find(u => u._id === user));
        this.props.setFilters({
          users: userForFilter,
          companies: profile.company?._id ? [profile.company?._id] : [],
          excludeDeactivated: this.state.isExcludeDeactivated
        });
        if ( isSeniorSalesManagerOrHigher(profile) ) {
          this.props.getPercentAnalyticsData({});
        }

        this.setState({
          usersIDs: userForFilter,
          companiesIDs: profile.company?._id ? [profile.company?._id] : [],
        });
      }
      this.setState({
        pdfExportEnabled: !isSuperAdmin(profile),
      })

      if (isReady(profile)) {
        this.setState({
          pdfExportEnabled: !isSuperAdmin(profile),
        });
      }
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { companies, users, profile, aggregatedData } = this.props;

    if ( isReady(companies) && isReady(users) && !this.companiesParticipantsAdded ) {
      this.calcCompaniesParticipants();
    }
    if ( isReady(profile) && this.state.pdfExportEnabled === null ) {
      this.setState({
        pdfExportEnabled: !isSuperAdmin(profile),
      })
    }
    if (!this.state.from && !this.state.to && aggregatedData.length) {
      if(prevProps.aggregatedData !== this.props.aggregatedData)  {
        let firstDay = '';
        let lastDay = '';
        if (aggregatedData.length > 1) {
          const main = (aggregatedData[0]._id as IGroupId).main;
          firstDay = main === 2 ? aggregatedData[0].firstDay : aggregatedData[1].firstDay;
          lastDay = aggregatedData[aggregatedData.length - 1].lastDay;
        }

        if (firstDay && lastDay) {
          this.setState({
            from: moment(firstDay).utcOffset(0, true).valueOf(),
            // to: moment(lastDay).utcOffset(0, true).valueOf(),
          });
        }
      }
    }
  }

  public componentWillUnmount(): void {
    this.props.setFilters({
      from: null,
      to: null,
      time: null,
      users: [],
      companies: [],
      excludeDeactivated: false,
    });

    this.setState({
      increased: false,
      from: '',
      to: '',
      companiesField: '',
      usersField: '',
      selectedCompanies: [],
      usersIDs: [],
      companiesIDs: [],
      isShowSelected: false,
      companies: [],
      pdfExportEnabled: null,
      isExcludeDeactivated: true,
    })
  }

  private calcCompaniesParticipants = () => {
    const { users } = this.props;
    const companies = [...this.props.companies];

    companies.forEach( company => {
      company.participants = users.filter( user => user.company === company._id).map( ({_id}) => _id);
    });

    this.setState({
      companies,
    }, () => this.companiesParticipantsAdded = true);
  };

  private increasedToggle = () =>
    this.setState({
      increased: !this.state.increased
    });

  private setFromDate = date => {
    this.setState({
      from: new Date(date).getTime(),
    });
  }

  private setToDate = date =>
    this.setState({
      to: moment(date).valueOf(),
    });

  private onClickApply = () => {
    const { from, to, usersIDs, isExcludeDeactivated } = this.state;
    this.props.setFilters({
      from: from
        ? moment(from).local().format('YYYY-MM-DD')
        : null,
      to: to
        ? moment(to).local().format('YYYY-MM-DD')
        : null,
      users: usersIDs,
      excludeDeactivated: isExcludeDeactivated,
    });
    const { profile } = this.props;
    if (isReady(profile) && isSeniorSalesManagerOrHigher(profile) ) {
      this.props.getPercentAnalyticsData({});
    }
  };

  private onSelectAllUsers = () => {
    const { users, profile } = this.props;
    let renderedUsers: IUser[] = [];

    if (isSuperAdmin(profile)) {
      renderedUsers = users;
    } else if (isCompanyAdmin(profile)) {
      renderedUsers = users.filter(
        (user) => user.company === profile.company?._id
      );
    } else if (isSeniorSalesManager(profile)) {
      const assignedUsers = profile.assignedUsers;
      renderedUsers = assignedUsers
        ? users.filter((user) => assignedUsers.includes(user._id))
        : [];
    }
    const userForFilter = renderedUsers.filter(u => ![ACCESS_LEVEL.COMPANY_ADMIN, ACCESS_LEVEL.SUPER_ADMIN].includes(u.accessLevel)).map(u => u._id);
    this.setState({
      usersIDs: userForFilter
    })
  }

  private onChangeSearchField = (e: SyntheticEvent, field: string) => {
    const { target } = e;

    this.setState(
      // @ts-ignore
      {
        [field]: (target as HTMLInputElement).value as string
      }
    );
  };

  private resetFilter = (field: string) => () => {
    this.setState(
      // @ts-ignore
      {
        [field]: "",
        usersIDs: [],
        companiesIDs: [],
      }
    );
  };

  private onUserSelect = (user: IUser) => () => {
    const usersIDs: string[] = [...this.state.usersIDs];
    const userIDIndex = usersIDs.findIndex(id => id === user._id);

    userIDIndex >= 0
      ? usersIDs.splice(userIDIndex, 1)
      : usersIDs.push(user._id);

    this.setState({
      usersIDs,
    }, () => {
      const companiesIDs: string[] = [...this.state.companiesIDs];

      this.state.companies.forEach( company => {
        // @ts-ignore
        const isUsersFromCompany = company.participants.every(participant => this.state.usersIDs.includes(participant));
        const companyIDIndex = companiesIDs.findIndex(id => id === company._id);

        if ( isUsersFromCompany && companyIDIndex < 0 ) {
          companiesIDs.push(company._id);
        } else if ( !isUsersFromCompany && companyIDIndex >= 0 ) {
          companiesIDs.splice(companyIDIndex, 1);
        }
      });

      this.setState({
        companiesIDs,
        pdfExportEnabled: this.state.usersIDs.length === 1,
      })
    });
  };

  private onCompanySelect = (company: ICompany) => () => {
    const companiesIDs: string[] = [...this.state.companiesIDs];
    const companyIDIndex = companiesIDs.findIndex(id => id === company._id);

    companyIDIndex >= 0
      ? companiesIDs.splice(companyIDIndex, 1)
      : companiesIDs.push(company._id);

    // @ts-ignore
    const notSelectedUsers = company.participants.filter(
      (participant: string) => {
        const user = this.props.users.find((user) => user._id === participant);
        if (user) {
          return (
            ![ACCESS_LEVEL.COMPANY_ADMIN, ACCESS_LEVEL.SUPER_ADMIN].includes(
              user.accessLevel
            ) && !this.state.usersIDs.some((id) => participant === id)
          );
        }
        return !this.state.usersIDs.some((id) => participant === id);
      }
    );

    this.setState({
      companiesIDs,
      usersIDs: [
        ...this.state.usersIDs,
        ...notSelectedUsers,
      ]
    })
  };

  private checkIsUserActive = (user: IUser): boolean =>
    this.state.usersIDs.some(id => id === user._id);

  private checkIsCompanyActive = (company: ICompany): boolean =>
    this.state.companiesIDs.some(id => id === company._id);

  private setQuickFilter = (period: string) => () => {
    const { LAST_WEEK, LAST_MONTH, LAST_YEAR, THIS_YEAR } = FILTER_QUICK_PERIODS;

    switch (period) {
      case LAST_WEEK.name:
        this.setState({
          from: getQuickFilterDate(LAST_WEEK),
          to: null,
        });
        return;
      case LAST_MONTH.name:
        this.setState({
          from: getQuickFilterDate(LAST_MONTH),
          to: null,
        });
        return;
      case LAST_YEAR.name:
        this.setState({
          from: getQuickFilterDate(LAST_YEAR),
        });
      case THIS_YEAR.name:
        this.setState({
          from: getQuickFilterDate(THIS_YEAR),
          to: null,
        });
        return;
      default:
        return;
    }
  };

  private onChangeExcludeDeactivated = (e: SyntheticEvent) => {
    const target = e.target as HTMLInputElement;

    this.setState({
      isExcludeDeactivated: target?.checked || false,
    })
  };

  private onChangeShowSelected = (e: SyntheticEvent) => {
    const target = e.target as HTMLInputElement;

    this.setState({
      isShowSelected: target?.checked || false,
    })
  };

  private exportReports = (type: string) => () => {
    const userID = this.state.usersIDs[0];

    exportReport(type, userID).then( ({data}) => window.open(data, '_self'));
  };

  private renderUsers = (users: IUser[], filterField: string) => {
    const { profile } = this.props;
    let renderedUsers: IUser[] = [];

    if (isSuperAdmin(profile)) {
      renderedUsers = users;
    } else if (isCompanyAdmin(profile)) {
      renderedUsers = users.filter(
        (user) => user.company === profile.company?._id
      );
    } else if (isSeniorSalesManager(profile)) {
      const assignedUsers = profile.assignedUsers;
      renderedUsers = assignedUsers
        ? users.filter((user) => assignedUsers.includes(user._id))
        : [];
    }

    return renderedUsers
    .filter(user => {
      const userName =
        user.firstName && user.lastName
          ? `${user.firstName} ${user.lastName}`
          : user.username || user.email;

      if ( !this.state.isShowSelected ) {
        if(!this.state.isExcludeDeactivated) {
          return userName.match(filterField);
        } else {
          return userName.match(filterField) && user.isActive;
        }
      } else {
        const selectedCompanies = this.state.companies.filter( company => this.state.companiesIDs.some( id => id === company._id));

        if(!this.state.isExcludeDeactivated) {
          // @ts-ignore
          return userName.match(filterField) && selectedCompanies.some(company => company.participants.includes(user._id));
        } else {
          // @ts-ignore
          return userName.match(filterField) && selectedCompanies.some(company => company.participants.includes(user._id) && user.isActive);
        }
      }
    })
    .filter(u => ![ACCESS_LEVEL.COMPANY_ADMIN, ACCESS_LEVEL.SUPER_ADMIN].includes(u.accessLevel))
    .map(user => (
      <li
        key={user._id}
        onClick={this.onUserSelect(user)}
        className={this.checkIsUserActive(user) ? styles.active : ''}
      >
        {user.firstName && user.lastName
          ? `${user.firstName} ${user.lastName}`
          : user.username || user.email}
      </li>
    ));
  }

  private renderCompanies = (companies: ICompany[], filterField: string) =>
    companies
      .filter(company => company.name.match(filterField))
      .map(company => (
        <li
          key={company._id}
          onClick={this.onCompanySelect(company)}
          className={this.checkIsCompanyActive(company) ? styles.active : ''}
        >
          {company.name}
        </li>
      ));

  public render() {
    const { increased, from, to, companiesField, usersField, isShowSelected, companies, pdfExportEnabled, isExcludeDeactivated } = this.state;
    const { label, isShowExportBtn, profile, users } = this.props;
    console.log('from', from);
    return (
      <div className={`${styles.filters} ${isShowExportBtn ? '' : styles.inlineFilters} ${increased ? styles.increased : ""}`}>
        <div className={styles.header}>
          <div className={styles.timeRange}>
            {label && <p className={styles.label}>{label}</p>}
            <div
              className={`${styles.pickerWrapper} picker--custom ${
                !from ? "empty" : ""
              }`}
            >
              <DatePicker
                {...PICKER}
                selected={from}
                className={`${styles.timeRangePicker} ${
                  !from ? styles.empty : ""
                }`}
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => {
                  console.log('date', date instanceof Date ? date.getFullYear() : moment.unix(date).year());
                  return (
                    (
                      <div
                        style={{
                          margin: 10,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button style={{ marginRight: '5px' }} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                          {'<'}
                        </button>
                        <select
                          value={date instanceof Date ? date.getFullYear() : new Date(date).getFullYear()}
                          onChange={({ target: { value } }) => changeYear(value)}
                          style={{ marginRight: '5px' }}
                        >
                          {[...Array(new Date().getFullYear() - 2019)].map((_, i) => (
                            <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
                          ))}
                        </select>
            
                        <select
                          value={date instanceof Date ? date.getMonth() : new Date(date).getMonth()}
                          onChange={({ target: { value } }) => changeMonth(value)}
                        >
                          {Array.from({ length: 12 }, (_, i) => (
                            <option key={i} value={i}>
                              {new Date(0, i).toLocaleString('default', { month: 'long' })}
                            </option>
                          ))}
                        </select>
                        
                        <button style={{ marginLeft: '5px' }} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                          {'>'}
                        </button>
                      </div>
                    )
                  )
                }}
                onChange={this.setFromDate}
                placeholderText="--/--/----"
                maxDate={to || Date.now()}
                isClearable={true}
              />
            </div>
            <span>to</span>
            <div
              className={`${styles.pickerWrapper} picker--custom ${
                !to ? "empty" : ""
              }`}
            >
              <DatePicker
                {...PICKER}
                selected={to|| Date.now()}
                className={`${styles.timeRangePicker} ${
                  !to ? styles.empty : ""
                }`}
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div
                    style={{
                      margin: 10,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button style={{ marginRight: '5px' }} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                      {'<'}
                    </button>
                    <select
                      value={date instanceof Date ? date.getFullYear() : new Date(date).getFullYear()}
                      onChange={({ target: { value } }) => changeYear(value)}
                      style={{ marginRight: '5px' }}
                    >
                      {[...Array(new Date().getFullYear() - 2019)].map((_, i) => (
                        <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
                      ))}
                    </select>
        
                    <select
                      value={date instanceof Date ? date.getMonth() : new Date(date).getMonth()}
                      onChange={({ target: { value } }) => changeMonth(value)}
                    >
                      {Array.from({ length: 12 }, (_, i) => (
                        <option key={i} value={i}>
                          {new Date(0, i).toLocaleString('default', { month: 'long' })}
                        </option>
                      ))}
                    </select>
                    
                    <button style={{ marginLeft: '5px' }} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                      {'>'}
                    </button>
                  </div>
                )}
                onChange={this.setToDate}
                placeholderText="--/--/----"
                minDate={from}
                isClearable={true}
              />
            </div>
            <ul className={styles.quickFiltersList}>
              <li>
                <button
                  onClick={this.setQuickFilter(
                    FILTER_QUICK_PERIODS.LAST_WEEK.name
                  )}
                >
                  last week
                </button>
              </li>
              <li>
                <button
                  onClick={this.setQuickFilter(
                    FILTER_QUICK_PERIODS.LAST_MONTH.name
                  )}
                >
                  last month
                </button>
              </li>
              <li>
                <button
                  onClick={this.setQuickFilter(
                    FILTER_QUICK_PERIODS.LAST_YEAR.name
                  )}
                >
                  last year
                </button>
              </li>
              <li>
                <button
                  onClick={this.setQuickFilter(
                    FILTER_QUICK_PERIODS.LAST_YEAR.name
                  )}
                >
                  this year
                </button>
              </li>
            </ul>
            {isSeniorSalesManagerOrHigher(profile) ? (
              <>
                <button
                  className={styles.moreFiltersBtn}
                  onClick={this.increasedToggle}
                >
                  More filters
                </button>
                <button className={styles.applyBtn} onClick={this.onClickApply}>
                  Apply
                </button>
              </>
            ) : (
              <button className={styles.applyBtn} onClick={this.onClickApply}>
                Apply
              </button>
            )}
          </div>
          {isShowExportBtn && (
            <div className={styles.export}>
              {pdfExportEnabled && (
                <button
                  className={styles.exportBtn}
                  onClick={this.exportReports(REPORT_EXPORT_TYPES.PDF.name)}
                >
                  PDF
                </button>
              )}
              <button
                className={styles.exportBtn}
                onClick={this.exportReports(REPORT_EXPORT_TYPES.CSV.name)}
              >
                XLSX
              </button>
            </div>
          )}
        </div>
        {isSeniorSalesManagerOrHigher(profile) && (
          <>
            <div className={styles.content}>
              {isSuperAdmin(profile) && (
                <div className={styles.area}>
                  <div className={styles.areaSearch}>
                    <label
                      htmlFor="companies"
                      className={styles.areaSearchTitle}
                    >
                      Companies
                    </label>
                    <input
                      id="companies"
                      type="text"
                      value={companiesField}
                      className={styles.areaSearchField}
                      onChange={(e) =>
                        this.onChangeSearchField(e, "companiesField")
                      }
                    />
                    <button
                      disabled={
                        !companiesField.length && !this.state.companiesIDs
                      }
                      onClick={this.resetFilter("companiesField")}
                      className={styles.areaSearchReset}
                    >
                      Reset
                    </button>
                  </div>
                  <ul className={styles.areaList}>
                    {this.renderCompanies(companies, companiesField)}
                  </ul>
                </div>
              )}
              <div className={styles.area}>
                <div className={styles.areaSearch}>
                  <label htmlFor="users" className={styles.areaSearchTitle}>
                    Users
                  </label>
                  <input
                    id="users"
                    type="text"
                    value={usersField}
                    className={styles.areaSearchField}
                    onChange={(e) => this.onChangeSearchField(e, "usersField")}
                  />
                  <button
                    disabled={!usersField.length && !this.state.usersIDs}
                    onClick={this.resetFilter("usersField")}
                    className={styles.areaSearchReset}
                  >
                    Reset
                  </button>
                </div>
                <ul className={`${styles.areaList} ${styles.areaListCheckbox}`}>
                  {isReady(users) && this.renderUsers(users, usersField)}
                </ul>
                {isReady(companies) && isSuperAdmin(profile) && (
                  <label
                    className={`${styles.fieldCheckbox} ${
                      !companies.length ? styles.disabled : ""
                    }`}
                  >
                    <input
                      checked={isShowSelected}
                      disabled={!companies.length}
                      type="checkbox"
                      onChange={this.onChangeShowSelected}
                    />
                    <span className={styles.checkerCheckbox} />
                    <p className={styles.fieldTitle}>
                      Show users only from selected companies
                    </p>
                  </label>
                )}
                {isReady(companies) && isSeniorSalesManagerOrHigher(profile) && (
                  <label
                    className={`${styles.fieldCheckbox}`}
                  >
                    <input
                      checked={isExcludeDeactivated}
                      type="checkbox"
                      onChange={this.onChangeExcludeDeactivated}
                    />
                    <span className={styles.checkerCheckbox} />
                    <p className={styles.fieldTitle}>
                      Exclude deactivated users
                    </p>
                  </label>
                )}
              </div>
            </div>
            <button
              className={`${styles.applyBtn} ${styles.applyBtnIncreased}`}
              onClick={this.onClickApply}
            >
              Apply
            </button>
            {
              isCompanyAdminOrLower(profile) && (
                <button className={styles.applyBtn} onClick={this.onSelectAllUsers}>
                  Select All
                </button>
              )
            }
          </>
        )}
      </div>
    );
  }
}

export default connect<IPropsFromStore, IDispatchProps, any, any>(
  mapStateToProps,
  dispatchProps
)(Filters);
