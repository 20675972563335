export const PICKER = {
  dateFormat: 'MM.dd.yyyy',
  todayButton: 'Today',
  maxDate: Date.now(),
};

export const YEAR_MONTH_PICKER = {
  dateFormat: 'yyyy',
  todayButton: 'Today',
};
