import { combineReducers } from 'redux';

import { authReducer } from '../sagas/auth.saga';
import { profileReducer } from '../sagas/profile.saga';
import { recentReportsReducer } from '../sagas/recentReports.saga';
import { activeReportReducer } from '../sagas/activeReport.saga';
import { reportsReducer } from '../sagas/reports.saga';
import { totalsReducer } from '../sagas/totals.saga';
import { goalTrackerTotalsReducer } from '../sagas/goalTrackerTotals.saga';
import { companyProjectionTotalsReducer } from '../sagas/companyProjectionTotals.saga';
import { paginationReducer } from '../sagas/pagination.saga';
import { goalTrackerPaginationReducer } from '../sagas/goalTrackerPagination.saga';
import { companyProjectionPaginationReducer } from '../sagas/companyProjectionPagination.saga';
import { filtersReducer } from '../sagas/filters.saga';
import { goalSettingsFiltersReducer } from '../sagas/goalSettingsFilters.saga';
import { goalTrackerFiltersReducer } from '../sagas/goalTrackerFilters.saga';
import { companyProjectionFiltersReducer } from '../sagas/companyProjectionFilters.saga';
import { usersReducer } from '../sagas/users.saga';
import { companiesReducer } from '../sagas/companies.saga';
import { aggregatedDataReducer } from '../sagas/aggregatedData.saga';
import { goalTrackerAggregatedDataReducer } from '../sagas/goalTrackerAggregatedData.saga';
import { companyProjectionAggregatedDataReducer } from '../sagas/companyProjectionAggregatedData.saga';
import { tooltipReducer } from '../reducers/tooltip';
import { selectedCompanyReducer } from '../sagas/selectedCompany.saga';
import { selectedUserReducer } from '../sagas/selectedUser.saga';
import { ratingsReducer } from '../sagas/ratings.saga';
import { notificationsReducer } from '../sagas/notifications.saga';
import { popupsReducer } from '../reducers/popups';
import { groupedReportsReducer } from '../sagas/groupedByDateReports.saga';
import { goalTrackerGroupedReportsReducer } from '../sagas/goalTrackerGroupedByDateReports.saga';
import { companyProjectionGroupedReportsReducer } from '../sagas/companyProjectionGroupedByDateReports.saga';
import { goalReducer } from '../sagas/goal.saga';
import { goalQuestionsReducer } from '../sagas/goalQuestions.saga';
import { allGoalsReducer } from '../sagas/allGoals.saga';
import { companyGoalReducer } from '../sagas/companyGoal.saga';
import { percentAnalyticsDataReducer } from '../sagas/percentAnalyticsData.saga';

export const appReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  recentReports: recentReportsReducer,
  activeReport: activeReportReducer,
  reports: reportsReducer,
  pagination: paginationReducer,
  goalTrackerPagination: goalTrackerPaginationReducer,
  companyProjectionPagination: companyProjectionPaginationReducer,
  totals: totalsReducer,
  goalTrackerTotals: goalTrackerTotalsReducer,
  companyProjectionTotals: companyProjectionTotalsReducer,
  filters: filtersReducer,
  goalSettingsFilters: goalSettingsFiltersReducer,
  goalTrackerFilters: goalTrackerFiltersReducer,
  companyProjectionFilters: companyProjectionFiltersReducer,
  users: usersReducer,
  companies: companiesReducer,
  aggregatedData: aggregatedDataReducer,
  percentAnalyticsData: percentAnalyticsDataReducer,
  goalTrackerAggregatedData: goalTrackerAggregatedDataReducer,
  companyProjectionAggregatedData: companyProjectionAggregatedDataReducer,
  tooltip: tooltipReducer,
  selectedCompany: selectedCompanyReducer,
  selectedUser: selectedUserReducer,
  ratings: ratingsReducer,
  notifications: notificationsReducer,
  popups: popupsReducer,
  groupedReports: groupedReportsReducer,
  goalTrackerGroupedReports: goalTrackerGroupedReportsReducer,
  companyProjectionGroupedReports: companyProjectionGroupedReportsReducer,
  goal: goalReducer,
  companyGoal: companyGoalReducer,
  allGoals: allGoalsReducer,
  goalQuestions: goalQuestionsReducer,
});
