import React from "react";
import { Collapse } from "react-collapse";

import { IUser } from "../../../types/IUser";
import {
  EReportType,
  GroupType,
  GroupedReportItem,
  IReportData,
  IReportsGroupByType,
} from "../../../types/IReport";
import { IProfile } from "../../../types/IProfile";

import { isReady } from "../../../utils/valueState";
import { isSuperAdmin } from "../../../utils/user.util";

import styles from "./Table.module.scss";
import { formatNumber } from "../../../utils/formatNumber";
import { getNeedsValue } from "../../../utils/goal.util";
import { IGoal, IGoalSetting } from "../../../types/IGoal";
import MonthlyTable from "../../GoalSettings/MonthlyTable/MonthlyTable";
import { PERCENT_DOLLAR_BUILT_MONTHLY_QUESTION_ORDER } from "../../../constants/goal";

interface IProps {
  users: IUser[];
  onUserMouseOver: (user: IUser | IProfile) => void;
  profile: IProfile;
  creator: { company: string; _id: string };
  groupedReport: GroupedReportItem[];
  groupedReports: IReportsGroupByType;
  isExpandable?: boolean;
  expand: boolean;
  type: GroupType;
  label: string;
  goalType: EReportType;
  allGoals: IGoal[];
  totals: IReportData;
}

export const UserNeedsRow: React.FC<IProps> = ({
  groupedReports,
  allGoals,
  goalType,
  label,
  type,
  users,
  creator,
  groupedReport,
  onUserMouseOver,
  profile,
  isExpandable,
  expand,
  totals,
}) => {
  const [showPercentDollar, setShowPercentDollar] = React.useState(false);
  const user =
    (isReady(users) && users.find((user) => user._id === creator?._id)) ||
    profile;

  const needToFormatCurrency = (label: string) =>
    !!["Dollars Built", "Dollars Booked"].find((el) => label.includes(el));

  const percentDollars = React.useMemo(() => {
    const result: any[] = [];
    if (isReady(creator) && isReady(groupedReport)) {
      let minColumns = 0;
      switch (type) {
        case GroupType.day:
          minColumns = 7;
          break;
        case GroupType.month:
          minColumns = 12;
          break;
        case GroupType.week:
          minColumns = 4;
          break;
      }

      if (groupedReport.length < minColumns) {
        groupedReport
          .concat(new Array(minColumns - groupedReport.length).fill(0))
          .forEach((groupedItem, idx) => {
            if (groupedItem) {
              const year = new Date(groupedItem.firstDay).getFullYear();
              const month = new Date(groupedItem.firstDay).getMonth();
              const goals = allGoals?.find(el => el.year === year)?.items[PERCENT_DOLLAR_BUILT_MONTHLY_QUESTION_ORDER - 1];
              if(goals && goals.values) {
                const percent = goals.values.find(el => el.month === month);
                result.push(percent?.value || 0);
              } else {
                result.push('N/A');
              }
            }
        });
      } else {
        groupedReport.forEach((groupedItem, idx) => {
          const year = new Date(groupedItem.firstDay).getFullYear();
          const month = new Date(groupedItem.firstDay).getMonth();
          const goals = allGoals?.find(el => el.year === year)?.items[PERCENT_DOLLAR_BUILT_MONTHLY_QUESTION_ORDER - 1];
          if(goals && goals.values) {
            const percent = goals.values.find(el => el.month === month);
            result.push(percent?.value || 0);
          } else {
            result.push('N/A');
          }
        });
      }

      const total = result.reduce((a, b) => a + (b === 'N/A' ? 0 : b), 0);
      result.push(total);
    }

    return result;
  }, [groupedReport, allGoals]);

  const renderValue = React.useCallback(() => {
    if (isReady(creator) && isReady(groupedReport)) {
      let minColumns = 0;
      switch (type) {
        case GroupType.day:
          minColumns = 7;
          break;
        case GroupType.month:
          minColumns = 12;
          break;
        case GroupType.week:
          minColumns = 4;
          break;
      }
      if (groupedReport.length < minColumns) {
        return groupedReport
          .concat(new Array(minColumns - groupedReport.length).fill(0))
          .map((groupedItem, idx) => {
            if (groupedItem) {
              const year = new Date(groupedItem.firstDay).getFullYear();
              const needs = getNeedsValue({
                type: goalType,
                // questions: goalSetting.items || [],
                questions:
                  allGoals?.find ? (allGoals?.find(
                    (goal) =>
                      goal.userId === creator?._id && year === goal.year
                  )?.items || []) : [],
                firstDay: groupedItem.firstDay,
                groupType: type,
                dollarBuiltActual: groupedReports.reports.dollarsClosed[
                  idx
                ].reports.reduce((sum, currVal) => sum + currVal.actual, 0),
                dollarsClosed: totals.dollarsClosed || 0,
                projectsTurnedIn: totals.projectsTurnedIn || 0,
              }) || 0;
              if (needToFormatCurrency(label)) {
                return (
                  <p className={styles.column}>
                    {needs ? `${formatNumber(needs, 0, ".", ",")}` : ""}
                  </p>
                );
              }
              return <p className={styles.column}>{needs.toFixed(0) || 0}</p>;
            }
            return <p className={styles.column}></p>;
          });
      }
      return groupedReport.map((groupedItem, idx) => {
        const year = new Date(groupedItem.firstDay).getFullYear();
        const needs =
          getNeedsValue({
            type: goalType,
            questions: allGoals?.find
              ? allGoals?.find(
                  (goal) => goal.userId === creator?._id && goal.year === year
                )?.items || []
              : [],
            firstDay: groupedItem.lastDay,
            groupType: type,
            dollarBuiltActual: groupedReports.reports.dollarsClosed[
              idx
            ].reports.reduce((sum, currVal) => sum + currVal.actual, 0),
            dollarsClosed: totals.dollarsClosed || 0,
            projectsTurnedIn: totals.projectsTurnedIn || 0,
          }) || 0;
        if (needToFormatCurrency(label)) {
          return (
            <p className={styles.column}>
              {needs ? `${formatNumber(needs, 0, ".", ",")}` : "0"}
            </p>
          );
        }
        return <p className={styles.column}>{needs.toFixed(0) || 0}</p>;
      });
    }
  }, [groupedReport, allGoals]);

  const total = React.useMemo(() => {
    let initial = 0;
    if (isReady(creator) && isReady(groupedReport)) {
      groupedReport.map((groupedItem, idx) => {
        const year = new Date(groupedItem.firstDay).getFullYear();
        const needs = getNeedsValue({
          type: goalType,
          questions:
            allGoals?.find ? (allGoals?.find(
              (goal) => goal.userId === creator?._id && goal.year === year
            )?.items || []) : [],
          firstDay: groupedItem.lastDay,
          groupType: type,
          dollarBuiltActual: groupedReports.reports.dollarsClosed[
            idx
          ].reports.reduce((sum, currVal) => sum + currVal.actual, 0),
          dollarsClosed: totals.dollarsClosed || 0,
          projectsTurnedIn: totals.projectsTurnedIn || 0,
        }) || 0;
        initial += needs;
      });
    }

    return initial;
  }, []);

  const handleToggleCollapse = React.useCallback(() => setShowPercentDollar(prev => !prev), []);

  return (
    <>
      <div className={`${styles.row} ${styles.value}`}>
        <p className={styles.column}>
          {/* {!isExpandable && formatReportTimeShort(report.createdAt)} */}
          {!isExpandable && isSuperAdmin(profile) && user && (
            <span
              className={styles.user}
              data-tip="user"
              onMouseOver={() => onUserMouseOver(user)}
            >
              {user.profileImg ? (
                <img src={user.profileImg} alt={user.profileImg} />
              ) : (
                <span>
                  {user.firstName
                    ? user.firstName.charAt(0)
                    : user.username
                    ? user.username.charAt(0)
                    : user.email.charAt(0)}
                </span>
              )}
            </span>
          )}
          {isExpandable && user && (
            <span
              className={styles.userMail}
              data-tip="user"
              onMouseOver={() => onUserMouseOver(user)}
            >
              {user.email}
            </span>
          )}

          <button
            type="button"
            className={`${styles.collapseBtn} ${
              showPercentDollar ? styles.open : styles.close
            }`}
            onClick={handleToggleCollapse}
          />
        </p>
        {expand ? (
          <>
            {renderValue()}
            <p className={styles.column}>
              {needToFormatCurrency(label)
                ? `${formatNumber(total, 0, ".", ",")}`
                : total.toFixed(0)}
            </p>
          </>
        ) : null}
      </div>
      <Collapse isOpened={showPercentDollar}>
        <div className={`${styles.row} ${styles.value}`}>
          <p className={styles.column}>
            <span
              className={`${styles.userMail} ${styles.title}`}
              title="Percent Dollars Built Monthly"
            >
              Percent Dollars Built Monthly
            </span>
          </p>
          {percentDollars.map((el, idx) => {
            if(el) {
              return (
                <p key={`goTracker_collapse${el}_${idx}`} className={`${styles.column} ${styles.positiveDiff}`}>
                  {el}
                </p>
              )
            }
          })}
        </div>
      </Collapse>
    </>
  );
};
