import { takeEvery, put } from 'redux-saga/effects';

import { IFilters, IFiltersPayload } from '../types/IFilters';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';
import { isSuperAdmin } from '../utils/user.util';
import { getStore } from '../store/store';

import { reportsActions } from './reports.saga';
import { totalsActions } from './totals.saga';
import { aggregatedDataActions } from './aggregatedData.saga';
import { paginationActions } from './pagination.saga';
import { groupedReportsActions } from './groupedByDateReports.saga';
import { percentAnalyticsDataActions } from './percentAnalyticsData.saga';

const resource = 'FILTERS';
const actions = ['set'];
type reducerType = IFilters;

export const filtersTypes = createActionTypes(resource, actions);
export const filtersActions = createActions<reducerType>(resource, actions);
export const filtersReducer = createReducers<reducerType>(resource, actions);

function* setFilters({payload}: IFiltersPayload) {
  yield put(filtersActions.set.success(payload));
  yield put(paginationActions.reset.update());
  yield isSuperAdmin(getStore().profile) ? put(groupedReportsActions.get.load()) : put(reportsActions.get.load());
  yield put(totalsActions.get.load());
  yield put(aggregatedDataActions.get.load());
}

export function* filtersSaga() {
  yield takeEvery(filtersTypes.set.update, setFilters);
}
