export const ROUTE_ACCESS = {
  PUBLIC: 0,
  PROTECTED: 1,
  SUPER_ADMIN: 3,
  SENIOR_SALES_MANAGER: 4,
  SALES_MANAGER: 5,
};

export const ROUTES = {
  Root: {
    path: '/',
    access: ROUTE_ACCESS.PROTECTED,
    exact: true,
  },
  Login: {
    path: '/login',
    access: ROUTE_ACCESS.PUBLIC,
    exact: true,
  },
  ForgotPassStepEmail: {
    path: '/forgot-password',
    access: ROUTE_ACCESS.PUBLIC,
    exact: true,
  },
  ForgotPassStepReset: {
    path: '/reset-password',
    access: ROUTE_ACCESS.PUBLIC,
    exact: true,
  },
  Analytics: {
    path: '/analytics',
    access: ROUTE_ACCESS.PROTECTED,
    exact: true,
  },
  GoalTracker: {
    path: '/goal-tracker',
    access: ROUTE_ACCESS.SALES_MANAGER,
    exact: true,
  },
  CompanyProjection: {
    path: '/company-projection',
    access: ROUTE_ACCESS.SENIOR_SALES_MANAGER,
    exact: true,
  },
  GoalSettings: {
    path: '/goal-settings',
    access: ROUTE_ACCESS.SENIOR_SALES_MANAGER,
    exact: true,
  },
  Rating: {
    path: '/rating',
    access: ROUTE_ACCESS.PROTECTED,
    exact: true,
  },
  Admin: {
    path: '/admin',
    access: ROUTE_ACCESS.SUPER_ADMIN,
    exact: true,
  },
  Settings: {
    path: '/settings',
    access: ROUTE_ACCESS.PROTECTED,
    exact: true,
  },
  CompanyEditor: {
    path: '/edit-company',
    access: ROUTE_ACCESS.PROTECTED,
    exact: true,
  },
  UserEditor: {
    path: '/edit-user',
    access: ROUTE_ACCESS.PROTECTED,
    exact: true,
  },
  NoMatch: {
    path: '*',
    access: ROUTE_ACCESS.PUBLIC,
  },
};
