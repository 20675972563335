import React, { Component, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { Dispatch } from 'redux';

import { PICKER } from '../../../constants/datepicker.config';
import { FILTER_QUICK_PERIODS } from '../../../constants/Filters';
import { REPORT_EXPORT_TYPES } from '../../../constants/report';

import { IStore } from '../../../types/IStore';
import { IProfile } from '../../../types/IProfile';
import { IFilters } from '../../../types/IFilters';
import { ACCESS_LEVEL, IUser } from '../../../types/IUser';
import { ICompany } from '../../../types/ICompany';

import { goalTrackerFiltersActions } from '../../../sagas/goalTrackerFilters.saga';

import { isReady } from '../../../utils/valueState';
import { isSuperAdmin, isCompanyAdmin, isSalesPerson, isSeniorSalesManager, isSalesManager, isCompanyAdminOrLower, isSeniorSalesManagerOrHigher } from '../../../utils/user.util';
import moment from '../../../utils/moment';
import { getQuickFilterDate } from '../../../utils/dates';
import { exportGoalTracker } from '../../../utils/goal.util';

import styles from './Filters.module.scss';
import { GroupType, IGroupId } from '../../../types/IReport';

interface IPropsFromStore {
  profile: IProfile;
  users: IUser[];
  companies: ICompany[];
  goalTrackerAggregatedData: any;
}

interface IDispatchProps {
  setGoalTrackerFilters: (payload: IFilters) => void;
}

const mapStateToProps = ({ profile, users, companies, goalTrackerAggregatedData}: IStore): IPropsFromStore => ({
  profile,
  users,
  companies,
  goalTrackerAggregatedData
});

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  setGoalTrackerFilters: (payload: IFilters) =>
    dispatch(goalTrackerFiltersActions.set.update(payload)),
});

interface IOuterProps {
  usersIDs: string[];
  companiesIDs: string[];
  setGoalTrackerState: (nextState: Partial<any>) => void;
}

type IProps = IPropsFromStore & IDispatchProps & IOuterProps;

interface IState {
  increased: boolean;
  from: string | number | null;
  to: string | number | null;
  companiesField: string;
  usersField: string;
  selectedCompanies: ICompany[];
  isShowSelected: boolean;
  isExcludeDeactivated: boolean;
  companies: ICompany[];
  pdfExportEnabled: boolean | null;
  displayType: GroupType | null;
}

class Filters extends Component<IProps, IState> {
  public state: Readonly<IState> = {
    increased: false,
    from: "",
    to: "",
    companiesField: "",
    usersField: "",
    selectedCompanies: [],
    isShowSelected: false,
    isExcludeDeactivated: true,
    companies: [],
    displayType: null,
    pdfExportEnabled: null,
  };
  private companiesParticipantsAdded: boolean = false;

  public componentDidMount(): void {
    const { companies, users, profile } = this.props;

    if (isReady(companies) && isReady(users)) {
      this.calcCompaniesParticipants();
    }
    if (isReady(profile) && isReady(users)) {
      let renderedUsers: IUser[] = [];

      if (isSuperAdmin(profile)) {
        renderedUsers = users;
      } else if (isCompanyAdmin(profile)) {
        renderedUsers = users.filter(
          (user) => user.company === profile.company?._id
        );
      } else if (isSeniorSalesManager(profile)) {
        const assignedUsers = profile.assignedUsers;
        renderedUsers = assignedUsers
          ? users.filter((user) => assignedUsers.includes(user._id))
          : [];
      }
      let filtered = renderedUsers.filter(u => ![ACCESS_LEVEL.COMPANY_ADMIN, ACCESS_LEVEL.SUPER_ADMIN].includes(u.accessLevel)).map(u => u._id);

      const defaultSelectedUsers = profile.company?.participants?.filter(
        (participant: string) => {
          const user = this.props.users.find((u) => u._id === participant);
          if (user) {
            return ![ACCESS_LEVEL.COMPANY_ADMIN, ACCESS_LEVEL.SUPER_ADMIN].includes(
              user.accessLevel
            )
          }
          return true;
        }
      ) || [];
      filtered = defaultSelectedUsers.filter(user => renderedUsers.find(u => u._id === user));

      this.setState({
        pdfExportEnabled: !isSuperAdmin(profile),
      });

      this.props.setGoalTrackerState({
        companiesIDs: [profile.company?._id],
        usersIDs: filtered,
        excludeDeactivated: this.state.isExcludeDeactivated
      });

      this.props.setGoalTrackerFilters({
        users: filtered,
        companies: profile.company?._id ? [profile.company?._id] : [],
        excludeDeactivated: this.state.isExcludeDeactivated
      });
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { companies, users, profile, goalTrackerAggregatedData } = this.props;

    if (
      isReady(companies) &&
      isReady(users) &&
      !this.companiesParticipantsAdded
    ) {
      this.calcCompaniesParticipants();
    }
    if (isReady(profile) && this.state.pdfExportEnabled === null) {
      this.setState({
        pdfExportEnabled: !isSuperAdmin(profile),
      });
    }
    if (!this.state.from && !this.state.to && goalTrackerAggregatedData.length) {
      if(prevProps.goalTrackerAggregatedData !== this.props.goalTrackerAggregatedData)  {
        let firstDay = '';
        let lastDay = '';
        if (goalTrackerAggregatedData.length > 1) {
          const main = (goalTrackerAggregatedData[0]._id as IGroupId).main;
          firstDay = main === 2 ? goalTrackerAggregatedData[0].firstDay : goalTrackerAggregatedData[1].firstDay;
          lastDay = goalTrackerAggregatedData[goalTrackerAggregatedData.length - 1].lastDay;
        }

        if (firstDay && lastDay) {
          this.setState({
            from: moment(firstDay).utcOffset(0, true).valueOf(),
            // to: this.getLastDateOfYear(moment(lastDay).utcOffset(0, true).toDate()).getTime(),
          });
        }
      }
    }
  }

  public componentWillUnmount(): void {
    this.props.setGoalTrackerState({
      from: null,
      to: null,
    });

    this.props.setGoalTrackerFilters({
      from: null,
      to: null,
      companies: [],
      users: [],
      excludeDeactivated: false,
    });
  }

  private calcCompaniesParticipants = () => {
    const { users } = this.props;
    const companies = [...this.props.companies];

    companies.forEach((company) => {
      company.participants = users
        .filter((user) => user.company === company._id)
        .map(({ _id }) => _id);
    });

    this.setState(
      {
        companies,
      },
      () => (this.companiesParticipantsAdded = true)
    );
  };

  private increasedToggle = () =>
    this.setState({
      increased: !this.state.increased,
    });

  private setFromDate = (date) => {
    this.setState({
      from: moment.utc(date).valueOf(),
    });
  };

  private setToDate = (date) =>
    this.setState({
      to: moment.utc(date).valueOf(),
    });

  private onSelectAllUsers = () => {
    const { users, profile } = this.props;
    let renderedUsers: IUser[] = [];

    if (isSuperAdmin(profile)) {
      renderedUsers = users;
    } else if (isCompanyAdmin(profile)) {
      renderedUsers = users.filter(
        (user) => user.company === profile.company?._id
      );
    } else if (isSeniorSalesManager(profile)) {
      const assignedUsers = profile.assignedUsers;
      renderedUsers = assignedUsers
        ? users.filter((user) => assignedUsers.includes(user._id))
        : [];
    }
    const userForFilter = renderedUsers
      .filter(
        (u) =>
          ![ACCESS_LEVEL.COMPANY_ADMIN, ACCESS_LEVEL.SUPER_ADMIN].includes(
            u.accessLevel
          )
      )
      .map((u) => u._id);
    this.props.setGoalTrackerState({
      usersIDs: userForFilter,
    });
  };

  private onClickApply = () => {
    const { from, to } = this.state;
    const { usersIDs, companiesIDs } = this.props;
    this.props.setGoalTrackerFilters({
      from: from
        ? moment(from)
            .local()
            .format("YYYY-MM-DD")
        : null,
      to: to
        ? moment(to)
            .local()
            .format("YYYY-MM-DD")
        : null,
      users: usersIDs,
      companies: companiesIDs,
      excludeDeactivated: this.state.isExcludeDeactivated,
      displayType: this.state.displayType,
    });
  };

  private onChangeSearchField = (e: SyntheticEvent, field: string) => {
    const { target } = e;

    this.setState(
      // @ts-ignore
      {
        [field]: (target as HTMLInputElement).value as string,
      }
    );
  };

  private resetSearchField = (field: string) => () => {
    this.setState(
      // @ts-ignore
      {
        [field]: "",
      }
    );

    switch (field) {
      case "companiesField":
        this.setState({
          companiesField: "",
        });
        this.props.setGoalTrackerState({
          companiesIDs: [],
        });
        break;
      case "usersField":
        this.setState({
          usersField: "",
        });
        this.props.setGoalTrackerState({
          usersIDs: [],
        });
        break;
      default:
        break;
    }
  };

  private onUserSelect = (user: IUser) => () => {
    const usersIDs: string[] = [...this.props.usersIDs];
    const userIDIndex = usersIDs.findIndex((id) => id === user._id);

    userIDIndex >= 0
      ? usersIDs.splice(userIDIndex, 1)
      : usersIDs.push(user._id);

    this.props.setGoalTrackerState({
      usersIDs,
    });

    const companiesIDs: string[] = [...this.props.companiesIDs];

    this.state.companies.forEach((company) => {
      // @ts-ignore
      const isUsersFromCompany = company.participants.every((participant) =>
        this.props.usersIDs.includes(participant)
      );
      const companyIDIndex = companiesIDs.findIndex((id) => id === company._id);

      if (isUsersFromCompany && companyIDIndex < 0) {
        companiesIDs.push(company._id);
      } else if (!isUsersFromCompany && companyIDIndex >= 0) {
        companiesIDs.splice(companyIDIndex, 1);
      }
    });

    this.props.setGoalTrackerState({
      companiesIDs,
    });
    this.setState({
      pdfExportEnabled: this.props.usersIDs.length === 1,
    });
  };

  private onCompanySelect = (company: ICompany) => () => {
    const companiesIDs: string[] = [...this.props.companiesIDs];
    const companyIDIndex = companiesIDs.findIndex((id) => id === company._id);

    companyIDIndex >= 0
      ? companiesIDs.splice(companyIDIndex, 1)
      : companiesIDs.push(company._id);

    // @ts-ignore
    const notSelectedUsers = company.participants.filter(
      (participant: string) => {
        const user = this.props.users.find((user) => user._id === participant);
        if (user) {
          return (
            ![ACCESS_LEVEL.COMPANY_ADMIN, ACCESS_LEVEL.SUPER_ADMIN].includes(
              user.accessLevel
            ) && !this.props.usersIDs.some((id) => participant === id)
          );
        }
        return !this.props.usersIDs.some((id) => participant === id);
      }
    );
    if(companyIDIndex < 0) {
      this.props.setGoalTrackerState({
        usersIDs: [...this.props.usersIDs, ...notSelectedUsers],
        companiesIDs,
      });
    } else {
      this.props.setGoalTrackerState({
        usersIDs: this.props.usersIDs.filter((id) => !company.participants?.find((participant) => participant === id)),
        companiesIDs,
      });
    }
  };

  private checkIsUserActive = (user: IUser): boolean =>
    this.props.usersIDs.some((id) => id === user._id);

  private checkIsCompanyActive = (company: ICompany): boolean =>
    this.props.companiesIDs.some((id) => id === company._id);

    private setQuickFilter = (period: string) => () => {
      const { LAST_WEEK, LAST_MONTH, LAST_YEAR, THIS_YEAR } = FILTER_QUICK_PERIODS;
  
      switch (period) {
        case LAST_WEEK.name:
          this.setState({
            from: getQuickFilterDate(LAST_WEEK),
            to: null,
          });
          return;
        case LAST_MONTH.name:
          this.setState({
            from: getQuickFilterDate(LAST_MONTH),
            to: null,
          });
          return;
        case LAST_YEAR.name:
          this.setState({
            from: getQuickFilterDate(LAST_YEAR),
            to: null,
          });
          return;
        case THIS_YEAR.name:
          this.setState({
            from: getQuickFilterDate(THIS_YEAR),
            to: null,
          });
          return;
        default:
          return;
      }
    };

  private setDisplayFilter = (type: GroupType | null) => () => {
    if (this.state.displayType === type) {
      this.setState({
        displayType: null,
      });
    } else {
      this.setState({
        displayType: type,
      });
    }
  };

  private onChangeShowSelected = (e: SyntheticEvent) => {
    const target = e.target as HTMLInputElement;

    this.setState({
      isShowSelected: target?.checked || false,
    });
  };

  private onChangeExcludeDeactivated = (e: SyntheticEvent) => {
    const target = e.target as HTMLInputElement;

    this.setState({
      isExcludeDeactivated: target?.checked || false,
    });
  };

  private exportGoalTracker = (type: string) => () => {
    exportGoalTracker(type).then( ({data}) => window.open(data, '_self'));
  };

  private renderUsers = (users: IUser[], filterField: string) => {
    const { profile } = this.props;
    let renderedUsers: IUser[] = [];
    if (isSuperAdmin(profile)) {
      renderedUsers = users;
    } else if (isCompanyAdmin(profile)) {
      renderedUsers = users.filter(
        (user) => user.company === profile.company?._id
      );
    } else if (isSeniorSalesManager(profile) || isSalesManager(profile)) {
      const assignedUsers = profile.assignedUsers;
      renderedUsers = assignedUsers
        ? users.filter((user) => assignedUsers.includes(user._id))
        : [];
    }
    return renderedUsers
      .filter(
        (u) =>
          ![ACCESS_LEVEL.COMPANY_ADMIN, ACCESS_LEVEL.SUPER_ADMIN].includes(
            u.accessLevel
          )
      )
      .filter((user) => {
        const userName =
          user.firstName && user.lastName
            ? `${user.firstName} ${user.lastName}`
            : user.username || user.email;

        if (!this.state.isShowSelected) {
          if (!this.state.isExcludeDeactivated) {
            return userName.match(filterField);
          } else {
            return userName.match(filterField) && user.isActive;
          }
        } else {
          const selectedCompanies = this.state.companies.filter((company) =>
            this.props.companiesIDs.some((id) => id === company._id)
          );

          if (!this.state.isExcludeDeactivated) {
            // @ts-ignore
            return (
              userName.match(filterField) &&
              selectedCompanies.some((company) =>
                company.participants?.includes(user._id)
              )
            );
          } else {
            // @ts-ignore
            return (
              userName.match(filterField) &&
              selectedCompanies.some(
                (company) =>
                  company.participants?.includes(user._id) && user.isActive
              )
            );
          }
        }
      })
      .map((user) => (
        <li
          key={user._id}
          onClick={this.onUserSelect(user)}
          className={this.checkIsUserActive(user) ? styles.active : ""}
        >
          {user.firstName && user.lastName
            ? `${user.firstName} ${user.lastName}`
            : user.username || user.email}
        </li>
      ));
  };

  private renderCompanies = (companies: ICompany[], filterField: string) =>
    companies
      .filter((company) => company.name.match(filterField))
      .map((company) => (
        <li
          key={company._id}
          onClick={this.onCompanySelect(company)}
          className={this.checkIsCompanyActive(company) ? styles.active : ""}
        >
          {company.name}
        </li>
      ));

  public render() {
    const {
      increased,
      from,
      to,
      companiesField,
      usersField,
      isShowSelected,
      companies,
      isExcludeDeactivated,
    } = this.state;
    const { profile, users } = this.props;

    return (
      <div className={`${styles.filters} ${increased ? styles.increased : ""}`}>
        <div className={styles.header}>
          <div className={styles.timeRange}>
            <div
              className={`${styles.pickerWrapper} picker--custom ${
                !from ? "empty" : ""
              }`}
            >
              <DatePicker
                {...PICKER}
                selected={from}
                className={`${styles.timeRangePicker} ${
                  !from ? styles.empty : ""
                }`}
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div
                    style={{
                      margin: 10,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button style={{ marginRight: '5px' }} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                      {'<'}
                    </button>
                    <select
                      value={date instanceof Date ? date.getFullYear() : new Date(date).getFullYear()}
                      onChange={({ target: { value } }) => changeYear(value)}
                      style={{ marginRight: '5px' }}
                    >
                      {[...Array(new Date().getFullYear() - 2019)].map((_, i) => (
                        <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
                      ))}
                    </select>
        
                    <select
                      value={date instanceof Date ? date.getMonth() : new Date(date).getMonth()}
                      onChange={({ target: { value } }) => changeMonth(value)}
                    >
                      {Array.from({ length: 12 }, (_, i) => (
                        <option key={i} value={i}>
                          {new Date(0, i).toLocaleString('default', { month: 'long' })}
                        </option>
                      ))}
                    </select>
                    
                    <button style={{ marginLeft: '5px' }} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                      {'>'}
                    </button>
                  </div>
                )}
                onChange={this.setFromDate}
                placeholderText="--/--/----"
                maxDate={to || Date.now()}
                isClearable={true}
              />
            </div>
            <span>to</span>
            <div
              className={`${styles.pickerWrapper} picker--custom ${
                !to ? "empty" : ""
              }`}
            >
              <DatePicker
                {...PICKER}
                selected={to || Date.now()}
                className={`${styles.timeRangePicker} ${
                  !to ? styles.empty : ""
                }`}
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div
                    style={{
                      margin: 10,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button style={{ marginRight: '5px' }} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                      {'<'}
                    </button>
                    <select
                      value={date instanceof Date ? date.getFullYear() : new Date(date).getFullYear()}
                      onChange={({ target: { value } }) => changeYear(value)}
                      style={{ marginRight: '5px' }}
                    >
                      {[...Array(new Date().getFullYear() - 2019)].map((_, i) => (
                        <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
                      ))}
                    </select>
        
                    <select
                      value={date instanceof Date ? date.getMonth() : new Date(date).getMonth()}
                      onChange={({ target: { value } }) => changeMonth(value)}
                    >
                      {Array.from({ length: 12 }, (_, i) => (
                        <option key={i} value={i}>
                          {new Date(0, i).toLocaleString('default', { month: 'long' })}
                        </option>
                      ))}
                    </select>
                    
                    <button style={{ marginLeft: '5px' }} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                      {'>'}
                    </button>
                  </div>
                )}
                onChange={this.setToDate}
                placeholderText="--/--/----"
                minDate={from}
                isClearable={true}
              />
            </div>
            <ul className={styles.quickFiltersList}>
              <li>
                <button
                  onClick={this.setQuickFilter(
                    FILTER_QUICK_PERIODS.LAST_WEEK.name
                  )}
                >
                  last week
                </button>
              </li>
              <li>
                <button
                  onClick={this.setQuickFilter(
                    FILTER_QUICK_PERIODS.LAST_MONTH.name
                  )}
                >
                  last month
                </button>
              </li>
              <li>
                <button
                  onClick={this.setQuickFilter(
                    FILTER_QUICK_PERIODS.LAST_YEAR.name
                  )}
                >
                  last year
                </button>
              </li>
              <li>
                <button
                  onClick={this.setQuickFilter(
                    FILTER_QUICK_PERIODS.THIS_YEAR.name
                  )}
                >
                  this year
                </button>
              </li>
            </ul>
            {!isSalesPerson(profile) ? (
              <>
                <button
                  className={styles.moreFiltersBtn}
                  onClick={this.increasedToggle}
                >
                  More filters
                </button>
                <ul
                  className={`${styles.quickFiltersList} ${styles.displayTypeList}`}
                >
                  <li
                    className={`${
                      this.state.displayType === GroupType.day
                        ? styles.active
                        : ""
                    }`}
                  >
                    <button onClick={this.setDisplayFilter(GroupType.day)}>
                      daily
                    </button>
                  </li>
                  <li
                    className={`${
                      this.state.displayType === GroupType.week
                        ? styles.active
                        : ""
                    }`}
                  >
                    <button onClick={this.setDisplayFilter(GroupType.week)}>
                      weekly
                    </button>
                  </li>
                  <li
                    className={`${
                      this.state.displayType === GroupType.month
                        ? styles.active
                        : ""
                    }`}
                  >
                    <button onClick={this.setDisplayFilter(GroupType.month)}>
                      monthly
                    </button>
                  </li>
                </ul>
                <button className={styles.applyBtn} onClick={this.onClickApply}>
                  Apply
                </button>
              </>
            ) : (
              <button className={styles.applyBtn} onClick={this.onClickApply}>
                Apply
              </button>
            )}
          </div>
          <div className={styles.export}>
            <button
              className={styles.exportBtn}
              onClick={this.exportGoalTracker(REPORT_EXPORT_TYPES.CSV.name)}
            >
              XLSX
            </button>
          </div>
        </div>
        {!isSalesPerson(profile) && increased && (
          <>
            <div className={styles.content}>
              {isSuperAdmin(profile) && (
                <div className={styles.area}>
                  <div className={styles.areaSearch}>
                    <label
                      htmlFor="companies"
                      className={styles.areaSearchTitle}
                    >
                      Companies
                    </label>
                    <input
                      id="companies"
                      type="text"
                      value={companiesField}
                      className={styles.areaSearchField}
                      onChange={(e) =>
                        this.onChangeSearchField(e, "companiesField")
                      }
                    />
                    <button
                      disabled={
                        !companiesField.length &&
                        this.props.companiesIDs.length === 0
                      }
                      onClick={this.resetSearchField("companiesField")}
                      className={styles.areaSearchReset}
                    >
                      Reset
                    </button>
                  </div>
                  <ul className={styles.areaList}>
                    {this.renderCompanies(companies, companiesField)}
                  </ul>
                </div>
              )}
              <div className={styles.area}>
                <div className={styles.areaSearch}>
                  <label htmlFor="users" className={styles.areaSearchTitle}>
                    Users
                  </label>
                  <input
                    id="users"
                    type="text"
                    value={usersField}
                    className={styles.areaSearchField}
                    onChange={(e) => this.onChangeSearchField(e, "usersField")}
                  />
                  <button
                    disabled={
                      !usersField.length && this.props.usersIDs.length === 0
                    }
                    onClick={this.resetSearchField("usersField")}
                    className={styles.areaSearchReset}
                  >
                    Reset
                  </button>
                </div>
                <ul className={`${styles.areaList} ${styles.areaListCheckbox}`}>
                  {isReady(users) && this.renderUsers(users, usersField)}
                </ul>
                {isReady(companies) && isSuperAdmin(profile) && (
                  <label
                    className={`${styles.fieldCheckbox} ${
                      !companies.length ? styles.disabled : ""
                    }`}
                  >
                    <input
                      checked={isShowSelected}
                      disabled={!companies.length}
                      type="checkbox"
                      onChange={this.onChangeShowSelected}
                    />
                    <span className={styles.checkerCheckbox} />
                    <p className={styles.fieldTitle}>
                      Show users only from selected companies
                    </p>
                  </label>
                )}

                {isReady(companies) && isSeniorSalesManagerOrHigher(profile) && (
                  <label
                    className={`${styles.fieldCheckbox}`}
                  >
                    <input
                      checked={isExcludeDeactivated}
                      type="checkbox"
                      onChange={this.onChangeExcludeDeactivated}
                    />
                    <span className={styles.checkerCheckbox} />
                    <p className={styles.fieldTitle}>
                      Exclude deactivated users
                    </p>
                  </label>
                )}
              </div>
            </div>
            <button
              className={`${styles.applyBtn} ${styles.applyBtnIncreased}`}
              onClick={this.onClickApply}
            >
              Apply
            </button>
            {isCompanyAdminOrLower(profile) && (
              <button
                className={styles.applyBtn}
                onClick={this.onSelectAllUsers}
              >
                Select All
              </button>
            )}
          </>
        )}
      </div>
    );
  }
}

export default connect<IPropsFromStore, IDispatchProps, any, any>(
  mapStateToProps,
  dispatchProps
)(Filters);
