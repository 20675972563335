import { takeEvery, put } from 'redux-saga/effects';

import { IFilters, IFiltersPayload } from '../types/IFilters';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';

import { companyProjectionTotalsActions } from './companyProjectionTotals.saga';
import { companyProjectionAggregatedDataActions } from './companyProjectionAggregatedData.saga';
import { companyProjectionPaginationActions } from './companyProjectionPagination.saga';
import { companyProjectionGroupedReportsActions } from './companyProjectionGroupedByDateReports.saga';

const resource = 'COMPANY_PROJECTION_FILTERS';
const actions = ['set'];
type reducerType = IFilters;

export const companyProjectionFiltersTypes = createActionTypes(resource, actions);
export const companyProjectionFiltersActions = createActions<reducerType>(resource, actions);
export const companyProjectionFiltersReducer = createReducers<reducerType>(resource, actions);

function* setCompanyProjectionFilters({payload}: IFiltersPayload) {
  yield put(companyProjectionFiltersActions.set.success(payload));
  yield put(companyProjectionPaginationActions.reset.update());
  yield put(companyProjectionGroupedReportsActions.get.load());
  yield put(companyProjectionTotalsActions.get.load());
  yield put(companyProjectionAggregatedDataActions.get.load());
}

export function* companyProjectionFiltersSaga() {
  yield takeEvery(companyProjectionFiltersTypes.set.update, setCompanyProjectionFilters);
}
