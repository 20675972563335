import { all, fork } from 'redux-saga/effects';

import { authSaga } from '../sagas/auth.saga';
import { profileSaga } from '../sagas/profile.saga';
import { recentReportsSaga } from '../sagas/recentReports.saga';
import { activeReportSaga } from '../sagas/activeReport.saga';
import { reportsSaga } from '../sagas/reports.saga';
import { totalsSaga } from '../sagas/totals.saga';
import { goalTrackerTotalsSaga } from '../sagas/goalTrackerTotals.saga';
import { companyProjectionTotalsSaga } from '../sagas/companyProjectionTotals.saga';
import { paginationSaga } from '../sagas/pagination.saga';
import { goalTrackerPaginationSaga } from '../sagas/goalTrackerPagination.saga';
import { companyProjectionPaginationSaga } from '../sagas/companyProjectionPagination.saga';
import { filtersSaga } from '../sagas/filters.saga';
import { goalSettingsFiltersSaga } from '../sagas/goalSettingsFilters.saga';
import { goalTrackerFiltersSaga } from '../sagas/goalTrackerFilters.saga';
import { companyProjectionFiltersSaga } from '../sagas/companyProjectionFilters.saga';
import { usersSaga } from '../sagas/users.saga';
import { companiesSaga } from '../sagas/companies.saga';
import { aggregatedDataSaga } from '../sagas/aggregatedData.saga';
import { goalTrackerAggregatedDataSaga } from '../sagas/goalTrackerAggregatedData.saga';
import { companyProjectionAggregatedDataSaga } from '../sagas/companyProjectionAggregatedData.saga';
import { selectedCompanySaga } from '../sagas/selectedCompany.saga';
import { selectedUserSaga } from '../sagas/selectedUser.saga';
import { ratingsSaga } from '../sagas/ratings.saga';
import { notificationsSaga } from '../sagas/notifications.saga';
import { groupedReportsSaga } from '../sagas/groupedByDateReports.saga';
import { goalTrackerGroupedReportsSaga } from '../sagas/goalTrackerGroupedByDateReports.saga';
import { companyProjectionGroupedReportsSaga } from '../sagas/companyProjectionGroupedByDateReports.saga';
import { goalSaga } from '../sagas/goal.saga';
import { goalQuestionsSaga } from '../sagas/goalQuestions.saga';
import { allGoalsSaga } from '../sagas/allGoals.saga';
import { companyGoalSaga } from '../sagas/companyGoal.saga';
import { percentAnalyticsDataSaga } from '../sagas/percentAnalyticsData.saga';

const allSagas: any = [
  authSaga,
  profileSaga,
  recentReportsSaga,
  activeReportSaga,
  reportsSaga,
  totalsSaga,
  goalTrackerTotalsSaga,
  companyProjectionTotalsSaga,
  paginationSaga,
  goalTrackerPaginationSaga,
  companyProjectionPaginationSaga,
  filtersSaga,
  goalSettingsFiltersSaga,
  goalTrackerFiltersSaga,
  companyProjectionFiltersSaga,
  usersSaga,
  companiesSaga,
  aggregatedDataSaga,
  goalTrackerAggregatedDataSaga,
  companyProjectionAggregatedDataSaga,
  selectedCompanySaga,
  selectedUserSaga,
  ratingsSaga,
  notificationsSaga,
  groupedReportsSaga,
  goalTrackerGroupedReportsSaga,
  companyProjectionGroupedReportsSaga,
  goalSaga,
  goalQuestionsSaga,
  allGoalsSaga,
  companyGoalSaga,
  percentAnalyticsDataSaga
];

export default function* appSagas() {
  yield all(allSagas.map(fork));
}
