import React, { Component } from 'react';
import Select, { components } from "react-select";
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import GoalFilters from './Filters/Filters';
import AnalyticsFilters from '../Analytics/Filters/Filters';
import Content from './Content/Content';

import { didNotStartLoading, isReady } from '../../utils/valueState';
import { isSeniorSalesManagerOrHigher, isSuperAdmin } from '../../utils/user.util';

import { IStore } from '../../types/IStore';
import { IProfile } from '../../types/IProfile';
import { IGoalSetting } from '../../types/IGoal';
import { IUser } from '../../types/IUser';
import { ICompany } from '../../types/ICompany';
import { IReportData, IAggregatedData, IGroupId, IPercentAnalyticsData } from '../../types/IReport';

import { goalActions } from '../../sagas/goal.saga';
import { usersActions } from '../../sagas/users.saga';
import { companiesActions } from '../../sagas/companies.saga';
import { aggregatedDataActions } from '../../sagas/aggregatedData.saga';

import styles from './GoalSettings.module.scss';
import { IOption } from '../../types/ISelect';
import { IFilters } from '../../types/IFilters';
import { filtersActions } from '../../sagas/filters.saga';
import UserGroupIcon from '../../assets/imgs/user-group.svg';
import CompanyIcon from '../../assets/imgs/company.svg';
import { getStore } from '../../store/store';
import { percentAnalyticsDataActions } from '../../sagas/percentAnalyticsData.saga';

const { Option, SingleValue } = components;
const IconOption = props => (
  <Option {...props}>
    <img
      src={props.data.icon}
      style={{ width: 24 }}
      alt={props.data.label}
    />
  </Option>
);

const IconValue = props => (
  <SingleValue {...props}>
    <img
      src={props.data.icon}
      style={{ width: 24 }}
      alt={props.data.label}
    />
  </SingleValue>
)

interface IPropsFromStore {
  profile: IProfile;
  goal: IGoalSetting;
  users: IUser[];
  companies: ICompany[];
  aggregatedData: (IReportData & IAggregatedData)[];
  percentAnalyticsData: IPercentAnalyticsData[];
}

interface IDispatchProps {
  getGoal: () => void;
  getUsers: () => void;
  getCompanies: () => void;
  getAggregatedData: (payload) => void;
  setFilters: (payload: IFilters) => void;
  getPercentAnalyticsData: (payload) => void;
}

const mapStateToProps = ({ profile, goal, users, companies, aggregatedData, companyGoal, percentAnalyticsData }: IStore): IPropsFromStore => ({
  profile,
  goal,
  users,
  companies,
  aggregatedData,
  percentAnalyticsData,
});

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  getCompanies: () => dispatch(companiesActions.get.load()),
  getUsers: () => dispatch(usersActions.get.load()),
  getGoal: () => dispatch(goalActions.get.load()),
  getAggregatedData: (payload) => dispatch(aggregatedDataActions.get.load()),
  setFilters: (payload: IFilters) =>
  dispatch(filtersActions.set.update(payload)),
  getPercentAnalyticsData: (payload) =>
    dispatch(percentAnalyticsDataActions.get.load()),
});

type IProps = IPropsFromStore & IDispatchProps;

const options = [
  {
    label: 'Company',
    value: 'company',
    icon: CompanyIcon,
  },
  {
    label: 'Selected Users',
    value: 'selectedUsers',
    icon: UserGroupIcon,
  }
]

interface IState {
  time: {
    value: number,
    label: string,
  };
  yearOptions: IOption[],
  selectedYear: IOption;
  usersIDs: string[];
  groupType: string | null;
  companiesIDs: string[];
}

class GoalSettings extends Component<IProps> {
  public state: Readonly<IState> = {
    yearOptions: [],
    selectedYear: null as unknown as IOption,
    time: {
      value: new Date().getFullYear(),
      label: new Date().getFullYear().toString()
    },
    groupType: null,
    usersIDs: [],
    companiesIDs: [],
  };

  public componentDidMount(): void {
    const { profile, goal, users, companies, aggregatedData } = this.props;

    if ( isReady(profile) ) {
      if ( isSeniorSalesManagerOrHigher(profile) ) {
        if ( didNotStartLoading(users) ) {
          this.props.getUsers();
        }
        if ( didNotStartLoading(companies) && isSuperAdmin(profile) ) {
          this.props.getCompanies();
        }
        if ( didNotStartLoading(goal) ) {
          // this.props.getGoal();
        }
        if ( didNotStartLoading(aggregatedData) ) {
          this.props.getAggregatedData({});
        }
        
        if (!this.state.yearOptions.length) {
          if (!isReady(aggregatedData) || aggregatedData.length === 0) {
            this.setState({
              yearOptions: [],
            });
          } else if (aggregatedData.length === 1) {
            this.setState({
              yearOptions: [
                {
                  value: (aggregatedData[0]._id as IGroupId).year,
                  label: (aggregatedData[0]._id as IGroupId).year.toString(),
                },
              ],
            });
          }
          else {
            const firstYear = (aggregatedData[0]._id as IGroupId).year;
            const lastYear = (aggregatedData[aggregatedData.length - 1]._id as IGroupId).year;
            let yearList: number[] = [];
            for (let i = lastYear; i >= firstYear; i--) {
              yearList.push(i);
            }
            this.setState({
              yearOptions: yearList.map((year) => ({
                value: year,
                label: year.toString(),
              })),
            });
          }
        }
      }

      if(!getStore().filters?.averageBy) {
        this.props.setFilters({
          averageBy: options[0].value
        });
      }
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>, prevStates: Readonly<IState>): void {
    const { profile, goal, companies, aggregatedData } = this.props;

    if ( isReady(profile) ) {
      if ( isSeniorSalesManagerOrHigher(profile) ) {
        if ( didNotStartLoading(companies) && isSuperAdmin(profile) ) {
          this.props.getCompanies();
        }
        if ( didNotStartLoading(goal) ) {
          // this.props.getGoal();

          // this.setState({
          //   users: this.props.goal.users,
          // });
        }
        if ( didNotStartLoading(aggregatedData) ) {
          this.props.getAggregatedData({});
        }

        if (!this.state.yearOptions.length) {
          if(prevProps.aggregatedData !== this.props.aggregatedData) {
            if (!isReady(aggregatedData) || aggregatedData.length === 0) {
              this.setState({
                yearOptions: [],
              });
            } else if (aggregatedData.length === 1) {
              this.setState({
                yearOptions: [
                  {
                    value: (aggregatedData[0]._id as IGroupId).year,
                    label: (aggregatedData[0]._id as IGroupId).year.toString(),
                  },
                ],
              });
            }
            else {
              const firstYear = (aggregatedData[0]._id as IGroupId).year;
              const lastYear = (aggregatedData[aggregatedData.length - 1]._id as IGroupId).year;
              let yearList: number[] = [];
              for (let i = lastYear; i >= firstYear; i--) {
                yearList.push(i);
              }
              this.setState({
                yearOptions: yearList.map((year) => ({
                  value: year,
                  label: year.toString(),
                })),
              });
            }
          }
        }
      }
    }
  }

  public componentWillUnmount(): void {
    this.props.setFilters({
      averageBy: null,
    })
  }

  private setSelectedYear = (year: IOption) => {
    this.setState({
      selectedYear: year,
    });
  }

  handleSetState = (nextState: Partial<IState>) => {
    this.setState(nextState);
  }

  handleSwitchAverageType = (newValue: any) => {
    this.props.setFilters({
      averageBy: newValue.value
    });
    this.props.getPercentAnalyticsData({});
  }

  public render() {
    const { goal, percentAnalyticsData } = this.props;

    return (
      <div className={styles.goalSettings}>
        {isReady(this.props.users) && (
          <GoalFilters
            label="Goal:"
            yearOptions={this.state.yearOptions}
            groupType={this.state.groupType}
            time={this.state.time}
            usersIDs={this.state.usersIDs}
            companiesIDs={this.state.companiesIDs}
            setGoalSettingState={this.handleSetState}
            selectedYear={this.state.selectedYear}
            goalId={goal}
          />
        )}

        <div className={styles.analyticsFilter}>
          {
            isReady(this.props.users) && (
              <AnalyticsFilters isShowExportBt={false} label="Analytics:" />
            )
          }
          <div className={styles.averageTypeSwitch}>
            {/* <p>Total analytics of: </p> */}
            <Select
              defaultValue={options[0]}
              options={options}
              className="settings-selector switch"
              classNamePrefix="settings-selector"
              isSearchable={false}
              onChange={this.handleSwitchAverageType}
              components={{ Option: IconOption, SingleValue: IconValue }}
            />
          </div>
        </div>

        {isReady(goal) && (
          <Content
            time={this.state.time}
            yearOptions={this.state.yearOptions}
            usersIDs={this.state.usersIDs}
            companiesIDs={this.state.companiesIDs}
            selectedYear={this.state.selectedYear}
            setGoalSettingState={this.handleSetState}
            setSelectedYear={(year: IOption) => this.setSelectedYear(year)}
            percentAnalyticsData={percentAnalyticsData}
          />
        )}
      </div>
    );
  }
}

export default connect<IPropsFromStore, IDispatchProps, any, any>(
  mapStateToProps,
  dispatchProps
)(GoalSettings);
