import React, { useState } from "react";

import { GroupType, GroupedReportItem } from "../../../types/IReport";
import { IUser } from "../../../types/IUser";
import { IProfile } from "../../../types/IProfile";

import { UserRow } from "./UserRow";

import styles from "./Table.module.scss";
import { isReady } from "../../../utils/valueState";
import { formatNumber } from "../../../utils/formatNumber";

interface IProps {
  report: GroupedReportItem[];
  users: IUser[];
  creators: { company: string, _id: string }[];
  onUserMouseOver: (user: IUser | IProfile) => void;
  profile: IProfile;
  label: string;
  type: GroupType;
  totalActual: number;
}

export const AdminRow: React.FC<IProps> = ({
  type,
  report,
  profile,
  users,
  creators,
  onUserMouseOver,
  label,
  totalActual
}) => {
  const [expand, setExpand] = useState<boolean>(false);
  const needToFormatCurrency = (labelRow: string) => ['Dollars Built', 'Dollars Booked'].find(el => labelRow.includes(el));
  // const totals = getReportsTotals(report.reports);

  const onClickExpand = () => setExpand(!expand);

  const renderReports = () =>
    creators.map((item) => {
      return (
        <UserRow
          key={item?._id}
          groupedReport={report}
          creator={item}
          profile={profile}
          users={users}
          onUserMouseOver={onUserMouseOver}
          isExpandable={true}
          expand={expand}
          type={type}
          label={label}
        />
      );
    });
  const renderTotalOfActual = () => {
    let minColumns = 0;
    switch (type) {
      case GroupType.day:
        minColumns = 7;
        break;
      case GroupType.month:
        minColumns = 12;
        break;
      case GroupType.week:
        minColumns = 4;
        break;
    }
    if (report.length < minColumns) {
      return report.concat(new Array(minColumns - report.length).fill(0)).map((data, idx) => {
        if(data) {
          if(needToFormatCurrency(label)) {
            return <p className={styles.column}>{data.totalActual ? `${formatNumber(data.totalActual, 0, '.', ',')}` : '0'}</p>
          }
          return <p className={styles.column}>{data.totalActual || '0'}</p>
        }
        return <p className={styles.column} key={`total_${label}_goal_row_${idx}`}/>;
      });
    }
    return report.map((data, idx) => {
      if(data) {
        if(needToFormatCurrency(label)) {
          return <p className={styles.column} key={`goTracker_${data._id.startDay}_${idx}`}>{formatNumber(data.totalActual, 0, '.', ',') || '0'}</p>;
        }
        return <p key={`goTracker_${data._id.startDay}_${idx}`} className={styles.column}>{data.totalActual || '0'}</p>;
      }
      return <p className={styles.column} key={`total_${label}_goal_row_${idx}`}/>;
    });
  };


  return (
    <div className={`${styles.row} ${styles.rowExpand} ${styles.value}`}>
      <p className={styles.column} onClick={onClickExpand}>
        <span className={styles.label} title={label}>
          {label}
        </span>{" "}
        <button className={`${styles.expand} ${expand ? styles.active : ""}`} />
      </p>
      {isReady(report) && renderTotalOfActual()}
      <p className={styles.column}>
        {needToFormatCurrency(label) ? `${formatNumber(totalActual, 0, '.', ',')}` : totalActual}
      </p>
      <div className={`${styles.expandSection} ${expand ? styles.active : ""}`}>
        {expand && renderReports()}
      </div>
    </div>
  );
};
