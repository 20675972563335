import { createReducer } from 'redux-create-reducer';
import { getInitStore } from './initStore.util';

import {
  createLoadingState,
  createNotLoadedState,
  passState,
  createSuccessState,
  createErrorState,
} from './valueState';

enum IActionType {
  load = 'load',
  update = 'update',
  success = 'success',
  fail = 'fail',
}

export const payloadAction = (type: string) => (payload?: any) => ({
  type,
  payload,
});

export const successAction = <T>(type: string) => (data?: T) => ({
  type,
  data,
});

export const errorAction = (type: string) => (error: Error) => ({
  type,
  error,
});

export const createActionTypes = (resource: string, actions: string[]): any =>
  actions.reduce(
    (types, action) => ({
      ...types,
      [action]: {
        [IActionType.load]: `${resource}:${action}::${IActionType.load}`,
        [IActionType.update]: `${resource}:${action}::${IActionType.update}`,
        [IActionType.success]: `${resource}:${action}::${IActionType.success}`,
        [IActionType.fail]: `${resource}:${action}::${IActionType.fail}`,
      },
    }),
    {}
  );

export const createActions = <T>(resource: string, actions: string[]): any =>
  actions.reduce(
    (prevActions, action) => ({
      ...prevActions,
      [action]: {
        [IActionType.load]: payloadAction(`${resource}:${action}::${IActionType.load}`),
        [IActionType.update]: payloadAction(`${resource}:${action}::${IActionType.update}`),
        [IActionType.success]: successAction<T>(`${resource}:${action}::${IActionType.success}`),
        [IActionType.fail]: errorAction(`${resource}:${action}::${IActionType.fail}`),
      },
    }),
    {}
  );

export const createReducers = <T>(resource: string, actions: string[], reducerName?: string): any =>
  createReducer(
    // @ts-ignore
    reducerName ? getInitStore()[reducerName] : createNotLoadedState(),
    actions.reduce(
      (reducers, action) => ({
        // @ts-ignore
        ...reducers,
        [`${resource}:${action}::${IActionType.load}`]: state => createLoadingState(state),
        [`${resource}:${action}::${IActionType.update}`]: state => passState(state),
        [`${resource}:${action}::${IActionType.success}`]: (state, { data }: { data: T }): T =>
          createSuccessState(state, data),
        [`${resource}:${action}::${IActionType.fail}`]: (state, { error }) => createErrorState(state, error),
      }),
      // @ts-ignore
      {}
    )
  );
