import React from 'react';

import styles from './ChartTooltip.module.scss';

import { IChartTooltipParams } from '../../types/IChartTooltip';

type IProps = {
  params: IChartTooltipParams;
};

export const ChartTooltip: React.FC<IProps> = ({ params }) => {
  let { time, value } = params;

  if ( !params.time ) {
    const paramsSplit = params.value.split(':');
    time = [...paramsSplit].splice(0, paramsSplit.length - 1).join(':') as string;
    value = [...paramsSplit].pop() as string;
  }
  return (
    <div className={styles.tooltipChart} style={params.style}>
      <span className={styles.tooltipTime}>{time}</span>
      <span className={styles.tooltipValue}>{value}</span>
    </div>
  );
};
