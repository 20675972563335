import { call, takeEvery, put } from 'redux-saga/effects';

import { API_ENDPOINTS } from '../constants/APi';

import { ICompanyGoalSetting } from '../types/IGoal';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';
import { http } from '../utils/requests';
import { getStore } from '../store/store';

const resource = 'COMPANY_GOAL';
const actions = ['get', 'update'];
type reducerType = ICompanyGoalSetting;

interface ICompanyGoalGetAction {
  payload: {
    year: number;
  };
}

export const companyGoalTypes = createActionTypes(resource, actions);
export const companyGoalActions = createActions<reducerType>(resource, actions);
export const companyGoalReducer = createReducers<reducerType>(resource, actions);

function* getCompanyGoal(data: ICompanyGoalGetAction) {
    try {
      const { profile, goalSettingsFilters } = getStore();

      const response = yield call(
        http.get,
        API_ENDPOINTS.GOAL_COMPANY +
          `/${goalSettingsFilters?.companies?.[0] || profile.company?._id}`,
        undefined,
        undefined,
        `?year=${data.payload}`
      );
      yield put(companyGoalActions.get.success(response));
    } catch (err) {
      yield put(companyGoalActions.get.fail(err));
    }
}

export function* companyGoalSaga() {
    yield takeEvery(companyGoalTypes.get.load, getCompanyGoal);
}