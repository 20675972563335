import { takeEvery, put } from 'redux-saga/effects';

import { IPagination } from '../types/IPagination';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';

import { goalTrackerGroupedReportsActions } from './goalTrackerGroupedByDateReports.saga';

const resource = 'GOAL_TRACKER_PAGINATION';
const actions = ['set', 'activePage', 'reset'];
type reducerType = IPagination;

export const goalTrackerPaginationTypes = createActionTypes(resource, actions);
export const goalTrackerPaginationActions = createActions<reducerType>(resource, actions);
export const goalTrackerPaginationReducer = createReducers<reducerType>(resource, actions);

function* setPagination({payload}: {[payload: string]: IPagination}) {
  yield put(goalTrackerPaginationActions.set.success(payload));
}

function* setActivePage({payload}: {[payload: string]: number}) {
  yield put(goalTrackerPaginationActions.activePage.success({
    activePage: payload,
  }));
  yield put(goalTrackerGroupedReportsActions.get.load());
}

function* reset() {
  yield put(goalTrackerPaginationActions.reset.success({
    activePage: 1,
    pages: 1,
    prevPage: null,
    nextPage: null,
  }));
}

export function* goalTrackerPaginationSaga() {
  yield takeEvery(goalTrackerPaginationTypes.set.update, setPagination);
  yield takeEvery(goalTrackerPaginationTypes.activePage.update, setActivePage);
  yield takeEvery(goalTrackerPaginationTypes.reset.update, reset);
}
