import { call, takeEvery, put } from 'redux-saga/effects';

import { API_ENDPOINTS } from '../constants/APi';

import { IReportData } from '../types/IReport';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';
import { http } from '../utils/requests';
import { getStore } from '../store/store';
import { assignFiltersParams } from '../utils/filters.util';
import { IFilters } from '../types/IFilters';

const resource = 'TOTALS';
const actions = ['get'];
type reducerType = IReportData;

interface IGetTotalPayload {
  payload: IFilters;
}

export const totalsTypes = createActionTypes(resource, actions);
export const totalsActions = createActions<reducerType>(resource, actions);
export const totalsReducer = createReducers<reducerType>(resource, actions);

function* getTotals({ payload }: IGetTotalPayload) {
  try {
    const filters = payload || getStore().filters;

    const query = filters
      ? assignFiltersParams(filters)
      : '';

    const response = yield call(http.get, API_ENDPOINTS.TOTALS, undefined, undefined, query);

    yield put(totalsActions.get.success(response));
  } catch (err) {
    yield put(totalsActions.get.success({
      doorsKnocked: 0,
      hoursKnocking: 0,
      evaluations: 0,
      insuranceLooks: 0,
      adjusterMeetings: 0,
      approvals: 0,
      totalColorMeetings: 0,
      projectsTurnedIn: 0,
    }));
  }
}

export function* totalsSaga() {
  yield takeEvery(totalsTypes.get.load, getTotals);
}
