import React, { Component } from 'react';
import { connect } from 'react-redux';

import Report from '../../components/Report/Report';
import Notifications from '../../components/Notifications/Notifications';

import { IStore } from '../../types/IStore';
import { IReport } from '../../types/IReport';
import { IPopups } from '../../types/IPopups';

import styles from './Popup.module.scss';

interface IPropsFromStore {
  activeReport: IReport | boolean;
  popups: IPopups;
}

const mapStateToProps = ({ activeReport,popups }: IStore): IPropsFromStore => ({
  activeReport,
  popups,
});

type IProps = IPropsFromStore;

class Popup extends Component<IProps> {
  public render() {
    const { activeReport, popups } = this.props;

    return activeReport || popups.notifications ? (
      <div className={styles.popup}>
        {activeReport && <Report />}
        {popups.notifications && <Notifications />}
      </div>
    ) : null;
  }
}

export default connect<IPropsFromStore, any, any, any>(mapStateToProps)(Popup);
