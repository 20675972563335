import moment from 'moment';
import { IFiltersQuickPeriods } from '../types/IFilters';

export const FILTER_QUICK_PERIODS: {[KEY: string]: IFiltersQuickPeriods} = {
  LAST_WEEK: {
    name: 'lastWeek',
    amountOf: 'days',
    amount: 7,
  },
  LAST_MONTH: {
    name: 'lastMonth',
    amountOf: 'months',
    amount: 1,
  },
  LAST_YEAR: {
    name: 'lastYear',
    amountOf: 'years',
    amount: 1,
  },
  THIS_YEAR: {
    name: 'thisYear',
    amountOf: 'days',
    amount: moment().diff(moment().startOf('year'), 'days'),
  },
};
