import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';

import { ROUTES } from '../../constants/router';
import { DEFAULT_COLOR } from '../../constants/color';

import { authActions } from '../../sagas/auth.saga';
import { profileActions } from '../../sagas/profile.saga';
import { popupsActions } from '../../reducers/popups';

import { IStore } from '../../types/IStore';
import { IProfile } from '../../types/IProfile';
import { INotification } from '../../types/INotification';

import { didNotStartLoading, isReady } from '../../utils/valueState';

import styles from './Header.module.scss';

import Logo from '../../assets/imgs/logo.svg';
import UserIcon from '../../assets/imgs/user_icon.svg';

interface IPropsFromStore {
  profile: IProfile;
  notifications: INotification[];
}

interface IDispatchProps {
  logOut: (payload: string) => void;
  getProfile: () => void;
  toggleNotifications: () => void;
}

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  logOut: payload => dispatch(authActions.logOut.update(payload)),
  getProfile: () => dispatch(profileActions.get.load()),
  toggleNotifications: () => dispatch(popupsActions.toggleNotifications()),
});

const mapStateToProps = ({ profile, notifications }: IStore): IPropsFromStore => ({
  profile,
  notifications,
});

type IProps = IDispatchProps & IPropsFromStore;

class Header extends Component<IProps> {
  public componentDidMount(): void {
    if (didNotStartLoading(this.props.profile)) {
      this.props.getProfile();
    }
  }

  private onClickLogOut = () => {
    // reset colors to initial values
    document.documentElement.style.setProperty('--primaryColor', DEFAULT_COLOR.PRIMARY_COLOR);
    document.documentElement.style.setProperty('--regularTextColor', DEFAULT_COLOR.REGULAR_TEXT_COLOR);

    this.props.logOut(this.props.profile._id);
  }

  private onClickNotifications = () => this.props.toggleNotifications();

  public render() {
    const { profile, notifications } = this.props;

    return (
      <header className={styles.header}>
        <div className={styles.headerLogo}>
          <Link to={ROUTES.Root.path}>
            <img src={ profile.company?.logo || Logo} alt="SuperStorm Salesperson Tracker" />
          </Link>
        </div>
        <div className={styles.headerInteractions}>
          <button
            disabled={!isReady(notifications)}
            className={`${styles.headerNotifications} ${
              notifications.length > 0 ? styles.notifExist : ""
            }`}
            onClick={this.onClickNotifications}
          />
          <div className={styles.headerUser}>
            <div className={styles.headerUserContainer}>
              <div className={styles.headerUserSection}>
                <p className={styles.headerUserName}>
                  {profile?.username || profile?.email}
                </p>
                <div className={styles.headerUserLogo}>
                  <img
                    src={profile.profileImg || UserIcon}
                    alt="Some user"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = UserIcon;
                    }}
                  />
                </div>
              </div>
              <button onClick={this.onClickLogOut} className={styles.logoutBtn}>
                <span className={styles.iconSvgArrowRightOnRectangle}></span>
              </button>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default connect<IPropsFromStore, IDispatchProps, any, any>(
  mapStateToProps,
  dispatchProps
)(Header);
