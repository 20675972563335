import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import Filters from './Filters/Filters';
import Chart from '../Chart/Chart';
import { Table } from './Table/Table';

import { didNotStartLoading, isReady } from '../../utils/valueState';
import { isSuperAdmin } from '../../utils/user.util';

import { IReport, IReportData, IReportsGroupByType } from '../../types/IReport';
import { IUser } from '../../types/IUser';
import { IStore } from '../../types/IStore';
import { IProfile } from '../../types/IProfile';
import { ICompany } from '../../types/ICompany';

import { CHART_TYPES } from '../../constants/chart.config';

import { reportsActions } from '../../sagas/reports.saga';
import { goalTrackerTotalsActions } from '../../sagas/goalTrackerTotals.saga';
import { usersActions } from '../../sagas/users.saga';
import { goalTrackerAggregatedDataActions } from '../../sagas/goalTrackerAggregatedData.saga';
import { companiesActions } from '../../sagas/companies.saga';
import { activeReportActions } from '../../sagas/activeReport.saga';
import { goalTrackerGroupedReportsActions } from '../../sagas/goalTrackerGroupedByDateReports.saga';

import styles from './GoalTracker.module.scss';
import { IGoal, IGoalSetting } from '../../types/IGoal';
import { allGoalsActions } from '../../sagas/allGoals.saga';
import { goalActions } from '../../sagas/goal.saga';

interface IPropsFromStore {
  profile: IProfile;
  reports: IReport[];
  goal: IGoalSetting;
  allGoals: IGoal[];
  goalTrackerTotals: IReportData;
  users: IUser[];
  goalTrackerAggregatedData: any;
  companies: ICompany[];
  goalTrackerGroupedReports: IReportsGroupByType;
}

interface IDispatchProps {
  getReports: () => void;
  getGoalSetting: () => void;
  getAllGoals: () => void;
  getGoalTrackerTotals: () => void;
  getUsers: () => void;
  getGoalTrackerAggregatedData: () => void;
  getCompanies: () => void;
  openReportForm: (payload: IReport) => void;
  getGoalTrackerGroupedReports: () => void;
}

const mapStateToProps = ({ goal, reports, goalTrackerTotals, users, profile, goalTrackerAggregatedData, companies, goalTrackerGroupedReports, allGoals }: IStore): IPropsFromStore => ({
  allGoals,
  goal,
  reports,
  goalTrackerTotals,
  users,
  profile,
  goalTrackerAggregatedData,
  companies,
  goalTrackerGroupedReports,
});

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  getReports: () => dispatch(reportsActions.get.load()),
  getGoalTrackerTotals: () => dispatch(goalTrackerTotalsActions.get.load()),
  getUsers: () => dispatch(usersActions.get.load()),
  getGoalSetting: () => dispatch(goalActions.get.load()),
  getGoalTrackerAggregatedData: () => dispatch(goalTrackerAggregatedDataActions.get.load()),
  getCompanies: () => dispatch(companiesActions.get.load()),
  getAllGoals: () => dispatch(allGoalsActions.get.load()),
  openReportForm: (payload: IReport) => dispatch(activeReportActions.openForm.update(payload)),
  getGoalTrackerGroupedReports: () => dispatch(goalTrackerGroupedReportsActions.get.load()),
});

type IProps = IPropsFromStore & IDispatchProps;

interface IState {
  usersIDs: string[];
  companiesIDs: string[];
}

class GoalTracker extends Component<IProps> {
  public state: Readonly<IState> = {
    usersIDs: [],
    companiesIDs: [],
  };

  public componentDidMount(): void {
    const {
      goal,
      goalTrackerTotals,
      users,
      profile,
      goalTrackerAggregatedData,
      companies,
      goalTrackerGroupedReports,
      allGoals,
    } = this.props;

    if (isReady(profile)) {
      if (isSuperAdmin(profile)) {
        if (didNotStartLoading(companies)) {
          this.props.getCompanies();
        }
      }
    }
    if (didNotStartLoading(users)) {
      this.props.getUsers();
    }
    if (didNotStartLoading(goalTrackerGroupedReports)) {
      this.props.getGoalTrackerGroupedReports();
    }
    if (didNotStartLoading(goalTrackerTotals)) {
      this.props.getGoalTrackerTotals();
    }

    if (didNotStartLoading(allGoals)) {
      this.props.getAllGoals();
    }
    if (didNotStartLoading(goal)) {
      this.props.getGoalSetting();
    }
    if (didNotStartLoading(goalTrackerAggregatedData)) {
      this.props.getGoalTrackerAggregatedData();
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    const {
      profile,
      users,
      companies,
      goalTrackerGroupedReports,
      allGoals,
    } = this.props;

    if (isReady(profile)) {
      if (isSuperAdmin(profile)) {
        if (didNotStartLoading(companies)) {
          this.props.getCompanies();
        }
      }
      if (didNotStartLoading(users)) {
        this.props.getUsers();
      }
      if (didNotStartLoading(goalTrackerGroupedReports)) {
        this.props.getGoalTrackerGroupedReports();
      }
      if (didNotStartLoading(goalTrackerGroupedReports)) {
        this.props.getGoalSetting();
      }
      if (didNotStartLoading(allGoals)) {
        this.props.getAllGoals();
      }
    }
  }

  handleSetState = (nextState: Partial<IState>) => {
    this.setState(nextState);
  };

  public render() {
    const {
      goal,
      reports,
      goalTrackerTotals,
      users,
      goalTrackerAggregatedData,
      profile,
      goalTrackerGroupedReports,
      allGoals,
    } = this.props;
    return (
      <div className={styles.goalTracker}>
        <Filters
          usersIDs={this.state.usersIDs}
          companiesIDs={this.state.companiesIDs}
          setGoalTrackerState={this.handleSetState}
        />
        <Table
          allGoals={allGoals}
          reports={reports}
          totals={goalTrackerTotals}
          users={users}
          profile={profile}
          groupedReports={goalTrackerGroupedReports}
          companiesIDs={this.state.companiesIDs}
          usersIDs={this.state.usersIDs}
          goalSetting={goal}
        />
        <div className={styles.chartsRow}>
          {isReady(goalTrackerAggregatedData) && (
            <>
              <Chart
                selectorEnabled={true}
                chartType={CHART_TYPES.PIE}
                aggregatedData={goalTrackerAggregatedData}
              />
              <Chart
                selectorEnabled={true}
                chartType={CHART_TYPES.LINE}
                aggregatedData={goalTrackerAggregatedData}
              />
            </>
          )}
        </div>
        <div className={styles.chartsRow}>
          {isReady(goalTrackerAggregatedData) && (
            <Chart
              selectorEnabled={true}
              chartType={CHART_TYPES.BAR}
              aggregatedData={goalTrackerAggregatedData}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect<IPropsFromStore, IDispatchProps, any, any>(
  mapStateToProps,
  dispatchProps
)(GoalTracker);
