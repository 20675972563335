import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";

import moment from "moment";

import {
  EReportType,
  GroupType,
  GroupedReportItem,
  IReport,
  IReportData,
  IReportsGroupByType,
} from "../../../types/IReport";
import { IUser } from "../../../types/IUser";
import { IProfile } from "../../../types/IProfile";

import Pagination from "./Pagination/Pagination";

import { isReady } from "../../../utils/valueState";
import { isSalesPerson } from "../../../utils/user.util";

import styles from "./Table.module.scss";
import UserIcon from "../../../assets/imgs/user_icon.svg";
import { AdminRow } from "./AdminRow";
import { DifferentRow } from "./DifferentRow";
import { AdminNeedsRow } from "./AdminNeedsRow";
import { IGoal, IGoalSetting } from "../../../types/IGoal";
import { getNeedsValue, mappingGroupedReportObject } from "../../../utils/goal.util";
import { getLastDayOfWeeks } from "../../../utils/dates";

const rows = {
  leadsActual: {
    label: "Leads",
  },
  evaluations: {
    label: "Inspect",
  },
  insuranceLooks: {
    label: "Signed Agreements",
  },
  adjusterMeetings: {
    label: "Adjuster Meet",
  },
  approvals: {
    label: "Job Aprvls",
  },
  projectsTurnedIn: {
    label: "Jobs Built",
  },
  dollarsBooked: {
    label: "Dollars Booked",
  },
  dollarsClosed: {
    label: "Dollars Built",
  },
};

interface IProps {
  reports: IReport[];
  totals: IReportData;
  users: IUser[];
  profile: IProfile;
  usersIDs: string[];
  companiesIDs: string[];
  groupedReports: IReportsGroupByType;
  allGoals: IGoal[];
  goalSetting: IGoalSetting;
}

function existsInArrayWhile(allGoals, userId, year) {
  let index = 0;
  let foundGoal: any = null;

  do {
      const goal = allGoals[index];
      if (goal && goal.userId === userId && goal.year === year) {
          foundGoal = goal;
          break;
      }
      index++;
  } while (index < allGoals.length);

  return foundGoal ? foundGoal.items : [];
}


export const Table: React.FC<IProps> = ({
  goalSetting,
  totals,
  users,
  profile,
  groupedReports,
  allGoals,
}) => {
  const [user, setUser] = useState<IUser | IProfile | null>(null);
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const onMouseOverUser = (userData: IUser | IProfile) => setUser(userData);

  const renderGroupedReports = React.useCallback(() => {
    Object.keys(groupedReports.reports).forEach((key) =>
      groupedReports[key] === undefined ? delete groupedReports[key] : {}
    );
    const mappedReports = mappingGroupedReportObject(groupedReports);

    return mappedReports.map(({ data, key }, idx) => {
      let totalNeeds = 0;
      const report = data as GroupedReportItem[];

      const diff: any[] = [];
      let minColumns = 0;
      switch (groupedReports.type) {
        case GroupType.day:
          minColumns = 7;
          break;
        case GroupType.month:
          minColumns = 12;
          break;
        case GroupType.week:
          minColumns = 4;
          break;
      }
      let reportData = report;
      if (report.length < minColumns) {
        reportData = report
          .concat(new Array(minColumns - report.length).fill(0))
      }
      reportData
        .map((reportRow, index) => {
          if ((reportRow as any) !== 0) {
            let needs = 0;
            groupedReports.creators.forEach((creator) => {
              const year = new Date(reportRow.firstDay).getFullYear();
              const items = existsInArrayWhile(allGoals, creator?._id, year);
              needs += getNeedsValue({
                type: key as EReportType,
                questions: items,
                firstDay: groupedReports.type !== GroupType.week ? reportRow.firstDay: reportRow.lastDay,
                groupType: groupedReports.type,
                dollarBuiltActual: groupedReports.reports.dollarsClosed?.length ? groupedReports.reports.dollarsClosed[
                  index
                ].reports.reduce((sum, currVal) => sum + currVal.actual, 0) : 0,
                dollarsClosed: totals.dollarsClosed || 0,
                projectsTurnedIn: totals.projectsTurnedIn || 0,
              }) || 0;
            });
            let totalReport = reportRow.totalActual - needs;
            diff.push({
              label: rows[key].label,
              value: Math.round(totalReport) === 0 ?  0 : totalReport,
            });
            report[index].needs = needs

            totalNeeds += needs;
          } else {
            diff.push({
              label: rows[key].label,
              value: '',
            });
            totalNeeds += 0;
          }
          
      });

      const totalActual = report.reduce(
        (accumulator, currentValue) => accumulator + currentValue.totalActual,
        0
      );

      let total = totalActual - totalNeeds;
      diff.push({
        label: 'Totals',
        value: Math.round(total) === 0 ?  0 : total,
      });

      return (
        <div className={styles.rowWrapper} key={key}>
          <AdminNeedsRow
            users={users}
            profile={profile}
            allGoals={allGoals || []}
            creators={groupedReports.creators}
            report={report}
            onUserMouseOver={onMouseOverUser}
            label={`${rows[key].label} Needs`}
            goalType={key as EReportType}
            type={groupedReports.type}
            groupedReports={groupedReports}
            totals={totals}
            totalNeeds={totalNeeds}
            goalSetting={goalSetting}
          />
          <AdminRow
            users={users}
            profile={profile}
            creators={groupedReports.creators}
            report={report}
            onUserMouseOver={onMouseOverUser}
            label={`${rows[key].label} Actual`}
            type={groupedReports.type}
            totalActual={totalActual}
          />

          <DifferentRow diff={diff} type={rows[key]} />
        </div>
      );
    });
  }, [groupedReports]);

  const renderGroupedReportsHeader = React.useCallback(() => {
    Object.keys(groupedReports.reports).forEach((key) =>
      groupedReports[key] === undefined ? delete groupedReports[key] : {}
    );
    const sampleValue = groupedReports.reports.adjusterMeetings;
    switch (groupedReports.type) {
      case GroupType.day:
        if (sampleValue.length < 7) {
          return sampleValue
            .concat(new Array(7 - sampleValue.length).fill(0))
            .map((report, idx ) => {
              if (report) {
                const day = report.firstDay;
                return (
                  <p className={`${styles.column} ${styles.headerName}`}>
                    {moment.utc(day).format("MM/DD/YYYY")}
                  </p>
                );
              }

              return (
                <p className={`${styles.column} ${styles.headerName}`} key={`format_date_${idx}`} />
              );
            });
        }
        return sampleValue.map((report, idx) => {
          const day = report.firstDay;
          return (
            <p className={`${styles.column} ${styles.headerName}`} key={report.firstDay.toString()}>
              {moment.utc(day).format("MM/DD/YYYY")}
            </p>
          );
        });
      case GroupType.month:
        if (sampleValue.length < 12) {
          return sampleValue
            .concat(new Array(12 - sampleValue.length).fill(0))
            .map((report, idx) => {
              if (report) {
                const month = report._id.month;

                return (
                  <p className={`${styles.column} ${styles.headerName}`}>
                    {`${moment()
                      .month(month - 1)
                      .format("MMM")} ${moment.utc(report.firstDay).year()}`}
                  </p>
                );
              }

              return (
                <p className={`${styles.column} ${styles.headerName}`} key={`format_month_${idx}`}  />
              );
            });
        }
        return sampleValue.map((report) => {
          const month = report._id.month;

          return (
            <p className={`${styles.column} ${styles.headerName}`} key={report.firstDay.toString()}>
              {`${moment()
                .month(month - 1)
                .format("MMM")} ${moment.utc(report.firstDay).year()}`}
            </p>
          );
        });
      case GroupType.week:
        if (sampleValue.length < 4) {
          return sampleValue
            .concat(new Array(4 - sampleValue.length).fill(0))
            .map((report, idx) => {
              if (report._id) {
                let weekOfMonth = report?.weekOfMonth;
                const day = report.lastDay;
                if (day && !weekOfMonth) {
                  const weeks = getLastDayOfWeeks(moment.utc(day).year(), moment.utc(day).month() + 1)
                  if (weeks) {
                    const weekIndex = weeks.findIndex(item => item.date === moment.utc(day).format('M/D/YY'))
                    if (weekIndex >= 0) {
                      weekOfMonth = weekIndex + 1;
                    }
                  }
                }
                return (
                  <p className={`${styles.column} ${styles.headerName}`}>
                    <p>{`Week-${(weekOfMonth || 1)}`}</p>
                    <p className={styles.weekText}>({moment.utc(day).format("MMM Do YYYY")})</p>
                  </p>
                );
              }
              return (
                <p className={`${styles.column} ${styles.headerName}`} key={`format_week_${idx}`}  />
              );
            });
        }
        return sampleValue.map((report, idx) => {
          if (report) {
            let weekOfMonth = report?.weekOfMonth;
            const day = report.lastDay;
            if (day && !weekOfMonth) {
              const weeks = getLastDayOfWeeks(moment.utc(day).year(), moment.utc(day).month() + 1)
              if (weeks) {
                const weekIndex = weeks.findIndex(item => item.date === moment.utc(day).format('M/D/YY'))
                if (weekIndex >= 0) {
                  weekOfMonth = weekIndex + 1;
                }
              }
            }
            return (
              <p className={`${styles.column} ${styles.headerName}`}>
                <p>{`Week-${(weekOfMonth || 1)}`}</p>
                <p className={styles.weekText}>({moment.utc(day).format("MMM Do YYYY")})</p>
              </p>
            );
          }
          return <p className={`${styles.column} ${styles.headerName}`}  key={`format_week_${idx}`}/>;
        });
    }
  }, [groupedReports]);

  return (
    <div className={styles.table}>
      <div className={`${styles.row} ${styles.header}`}>
        <p className={`${styles.column} ${styles.headerName}`}>
          Budget Category
        </p>
        {isReady(groupedReports) && (
          <>
            {renderGroupedReportsHeader()}
            <p className={`${styles.column} ${styles.headerName}`}>Totals</p>
          </>
        )}
      </div>
      {isReady(profile) && !isSalesPerson(profile) && isReady(groupedReports) && (
        <>
          <div className={styles.tableContent}>{renderGroupedReports()}</div>
          <Pagination />
        </>
      )}
      <ReactTooltip
        delayShow={300}
        type="light"
        className={styles.tooltip}
        effect="solid"
      >
        {user && (
          <div className={styles.userTooltip}>
            <div className={styles.userTooltipImg}>
              <img src={user.profileImg || UserIcon} alt={user.profileImg} />
            </div>
            {user.email && (
              <p className={styles.userTooltipInfo}>
                <span>Email:</span>
                <span>{user.email}</span>
              </p>
            )}
            {user.username && (
              <p className={styles.userTooltipInfo}>
                <span>Username:</span>
                <span>{user.username}</span>
              </p>
            )}
            {user.firstName && (
              <p className={styles.userTooltipInfo}>
                <span>First name:</span>
                <span>{user.firstName}</span>
              </p>
            )}
            {user.lastName && (
              <p className={styles.userTooltipInfo}>
                <span>Last name:</span>
                <span>{user.lastName}</span>
              </p>
            )}
          </div>
        )}
      </ReactTooltip>
    </div>
  );
};
