import { call, takeEvery, put } from 'redux-saga/effects';

import { API_ENDPOINTS } from '../constants/APi';

import { IPercentAnalyticsData } from '../types/IReport';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';
import { http } from '../utils/requests';
import { getStore } from '../store/store';
import { assignFiltersParams } from '../utils/filters.util';

const resource = 'PERSON_ANALYTICS_DATA';
const actions = ['get'];
type reducerType = IPercentAnalyticsData[];

export const percentAnalyticsDataTypes = createActionTypes(resource, actions);
export const percentAnalyticsDataActions = createActions<reducerType>(resource, actions);
export const percentAnalyticsDataReducer = createReducers<reducerType>(resource, actions);

function* getPercentAnalyticsData({ payload }) {
  try {
    const { filters } = getStore();

    const query = filters ? payload ? payload : assignFiltersParams(filters, '?') : '';

    const { values } = yield call(http.get, API_ENDPOINTS.PERCENT_ANALYTICS_DATA, undefined, undefined, query);

    yield put(percentAnalyticsDataActions.get.success(values.map(value => value)));
  } catch (err) {
    yield put(percentAnalyticsDataActions.get.fail(err));
  }
}

export function* percentAnalyticsDataSaga() {
  yield takeEvery(percentAnalyticsDataTypes.get.load, getPercentAnalyticsData);
}
