import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import Filters from './Filters/Filters';
import Chart from '../Chart/Chart';
import { Table } from './Table/Table';
import { CalculationsTable } from '../CalculationsTable/CalculationsTable';

import { didNotStartLoading, isReady } from '../../utils/valueState';
import { isSeniorSalesManagerOrHigher, isSuperAdmin } from '../../utils/user.util';

import { IReport, IReportData, IReportsGroupByDate } from '../../types/IReport';
import { IUser } from '../../types/IUser';
import { IStore } from '../../types/IStore';
import { IProfile } from '../../types/IProfile';
import { ICompany } from '../../types/ICompany';

import { CHART_TYPES } from '../../constants/chart.config';

import { reportsActions } from '../../sagas/reports.saga';
import { totalsActions } from '../../sagas/totals.saga';
import { usersActions } from '../../sagas/users.saga';
import { aggregatedDataActions } from '../../sagas/aggregatedData.saga';
import { companiesActions } from '../../sagas/companies.saga';
import { activeReportActions } from '../../sagas/activeReport.saga';
import { groupedReportsActions } from '../../sagas/groupedByDateReports.saga';

import styles from './Analytics.module.scss';

interface IPropsFromStore {
  profile: IProfile;
  reports: IReport[];
  totals: IReportData;
  users: IUser[];
  aggregatedData: any;
  companies: ICompany[];
  groupedReports: IReportsGroupByDate[];
}

interface IDispatchProps {
  getReports: () => void;
  getTotals: () => void;
  getUsers: () => void;
  getAggregatedData: () => void;
  getCompanies: () => void;
  openReportForm: (payload: IReport) => void;
  getGroupedReports: () => void;
}

const mapStateToProps = ({ reports, totals, users, profile, aggregatedData, companies, groupedReports}: IStore): IPropsFromStore => ({
  reports,
  totals,
  users,
  profile,
  aggregatedData,
  companies,
  groupedReports,
});

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  getReports: () => dispatch(reportsActions.get.load()),
  getTotals: () => dispatch(totalsActions.get.load()),
  getUsers: () => dispatch(usersActions.get.load()),
  getAggregatedData: () => dispatch(aggregatedDataActions.get.load()),
  getCompanies: () => dispatch(companiesActions.get.load()),
  openReportForm: (payload: IReport) => dispatch(activeReportActions.openForm.update(payload)),
  getGroupedReports: () => dispatch(groupedReportsActions.get.load()),
});

type IProps = IPropsFromStore & IDispatchProps;

class Analytics extends Component<IProps> {
  public componentDidMount(): void {
    const { reports, totals, users, profile, aggregatedData, companies, groupedReports } = this.props;

    if ( isReady(profile) ) {
      if( isSeniorSalesManagerOrHigher(profile)) {
        if ( didNotStartLoading(users) ) {
          this.props.getUsers();
        }
      }
      if ( isSuperAdmin(profile) ) {
        if ( didNotStartLoading(groupedReports) ) {
          this.props.getGroupedReports();
        }
        if ( didNotStartLoading(companies) ) {
          this.props.getCompanies();
        }
      } else {
        if ( didNotStartLoading(reports) ) {
          this.props.getReports();
        }
      }
    }
    if (didNotStartLoading(totals)) {
      this.props.getTotals();
    }
    if ( didNotStartLoading(aggregatedData) ) {
      this.props.getAggregatedData();
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { profile, users, companies, groupedReports, reports } = this.props;

    if ( isReady(profile) ) {
      if( isSeniorSalesManagerOrHigher(profile) ) { 
        if ( didNotStartLoading(users) ) {
          this.props.getUsers();
        }
      }
      if ( isSuperAdmin(profile) ) {
        if ( didNotStartLoading(groupedReports) ) {
          this.props.getGroupedReports();
        }
        if ( didNotStartLoading(companies) ) {
          this.props.getCompanies();
        }
      } else {
        if ( didNotStartLoading(reports) ) {
          this.props.getReports();
        }
      }
    }
  }

  private onReportSelect = (report: IReport) => isSuperAdmin(this.props.profile) && this.props.openReportForm(report);

  public render() {
    const { reports, totals, users, aggregatedData, profile, groupedReports } = this.props;

    return (
      <div className={styles.analytics}>
        <Filters isShowExportBtn={true} />
        <Table
          onReportSelect={this.onReportSelect}
          reports={reports}
          totals={totals}
          users={users}
          profile={profile}
          groupedReports={groupedReports}
        />
        <div className={styles.chartsRow}>
          <CalculationsTable reports={totals} />
          {isReady(aggregatedData) && <Chart selectorEnabled={true} chartType={CHART_TYPES.PIE} aggregatedData={aggregatedData} />}
        </div>
        <div className={styles.chartsRow}>
          {isReady(aggregatedData) && <Chart selectorEnabled={true} chartType={CHART_TYPES.BAR} aggregatedData={aggregatedData} />}
        </div>
        <div className={styles.chartsRow}>
          {isReady(aggregatedData) && <Chart selectorEnabled={true} chartType={CHART_TYPES.LINE} aggregatedData={aggregatedData} />}
        </div>
      </div>
    );
  }
}

export default connect<IPropsFromStore, IDispatchProps, any, any>(
  mapStateToProps,
  dispatchProps
)(Analytics);
