import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, RouteProps, Redirect } from 'react-router';

import { IStore } from '../../types/IStore';
import { IProfile } from '../../types/IProfile';

import { ROUTES, ROUTE_ACCESS } from '../../constants/router';

import { isReady } from '../../utils/valueState';
import { isSalesPerson, isSeniorSalesManagerOrHigher, isSuperAdmin } from '../../utils/user.util';

interface IPropsFromStore {
  auth: string | null;
  profile: IProfile;
}

interface IOuterProps {
  access: number;
}

const mapStateToProps = ({ auth, profile }: IStore) => ({
  auth,
  profile,
});

type IProps = IPropsFromStore & RouteProps & IOuterProps;

class RouteInterceptor extends Component<IProps> {
  public render() {
    const { auth, access, profile } = this.props;

    if ( access === ROUTE_ACCESS.PUBLIC ) {
      if ( isReady(auth) && auth ) {
        return <Redirect to={ROUTES.Root.path} />;
      } else {
        return <Route {...this.props} />;
      }
    } else {
      if ( isReady(auth) && auth ) {
        if ( access === ROUTE_ACCESS.SUPER_ADMIN ) {
          if ( isReady(profile) && isSuperAdmin(profile) ) {
            return <Route {...this.props} />;
          } else {
            return <Redirect to={ROUTES.Root.path} />;
          }
        } else if (access === ROUTE_ACCESS.SENIOR_SALES_MANAGER) {
          if ( isReady(profile) && isSeniorSalesManagerOrHigher(profile) ) {
            return <Route {...this.props} />;
          } else {
            return <Redirect to={ROUTES.Root.path} />;
          }
        } else if (access === ROUTE_ACCESS.SALES_MANAGER) {
          if ( isReady(profile) && !isSalesPerson(profile) ) {
            return <Route {...this.props} />;
          } else {
            return <Redirect to={ROUTES.Root.path} />;
          }
        } else {
          return <Route {...this.props} />;
        }
      } else {
        return <Redirect to={ROUTES.Login.path} />;
      }
    }
  }
}

export default connect<IPropsFromStore, any, any, any>(mapStateToProps)(
  RouteInterceptor
);
