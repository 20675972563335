import * as Yup from 'yup';
import { ERRORS } from './errors';
import { DAY_RATE_NAME, AMOUNT_TIME_NAME, MET_GOALS_NAME, FEELING_EXPLAIN } from './rating';

export const LOGIN_FORM_SCHEMA: Yup.object = Yup.object().shape({
  email: Yup.string().email(ERRORS.EMAIL.incorrect).required(ERRORS.EMAIL.required),
  password: Yup.string().required(ERRORS.PASSWORD),
});

export const REPORT_FORM_SCHEMA = Yup.object().shape({
  doorsKnocked: Yup.number().min(0, ERRORS.NUMBER_LESS_THEN_MIN).required(ERRORS.DOORS_KNOCKED),
  hoursKnocking: Yup.number().min(0, ERRORS.NUMBER_LESS_THEN_MIN).max(24, ERRORS.HOURS_KNOCKING.max).required(ERRORS.HOURS_KNOCKING.required),
  evaluations: Yup.number().min(0, ERRORS.NUMBER_LESS_THEN_MIN).required(ERRORS.EVALUATIONS),
  insuranceLooks: Yup.number().min(0, ERRORS.NUMBER_LESS_THEN_MIN).required(ERRORS.INSURANCE_LOOKS),
  adjusterMeetings: Yup.number().min(0, ERRORS.NUMBER_LESS_THEN_MIN).required(ERRORS.ADJUSTER_MEETINGS),
  approvals: Yup.number().min(0, ERRORS.NUMBER_LESS_THEN_MIN).required(ERRORS.APPROVALS),
  totalColorMeetings: Yup.number().min(0, ERRORS.NUMBER_LESS_THEN_MIN).required(ERRORS.TOTAL_COLOR_MEETINGS),
  projectsTurnedIn: Yup.number().min(0, ERRORS.NUMBER_LESS_THEN_MIN).required(ERRORS.PROJECTS_TURNED_IN),
  leadsActual: Yup.number().min(0, ERRORS.NUMBER_LESS_THEN_MIN),
  dollarsBooked: Yup.number().min(0, ERRORS.NUMBER_LESS_THEN_MIN),
  dollarsClosed: Yup.number().min(0, ERRORS.NUMBER_LESS_THEN_MIN),
  [DAY_RATE_NAME]: Yup.number().required(ERRORS.DAY_RATE_REQUIRED),
  [AMOUNT_TIME_NAME]: Yup.number().required(ERRORS.AMOUNT_TIME_REQUIRED),
  [MET_GOALS_NAME]: Yup.number(),
  [FEELING_EXPLAIN]: Yup.string(),
});

export const FORGOT_PASS_EMAIL_SCHEMA = Yup.object().shape({
  email: Yup.string().email(ERRORS.EMAIL.incorrect).required(ERRORS.EMAIL.required),
});

export const FORGOT_PASS_RESET_SCHEMA = Yup.object().shape({
  password: Yup.string().required(ERRORS.PASSWORD),
  rePassword: Yup.string().oneOf([Yup.ref('password'), null], ERRORS.REPEAT_PASSWORD.match).required(ERRORS.REPEAT_PASSWORD.required),
});

export const SETTINGS_GENERAL_SCHEMA = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string().email(ERRORS.EMAIL.incorrect).required(ERRORS.EMAIL.required),
  phone: Yup.string(),
  dateOfBirth: Yup.string(),
  address: Yup.string(),
  username: Yup.string(),
  company: Yup.string(),
  receiveReportEvery: Yup.string(),
  isEmailNotif: Yup.boolean(),
  isAppNotif: Yup.boolean(),
});

export const SETTINGS_PASSWORD_SCHEMA = Yup.object().shape({
  oldPassword: Yup.string().required(ERRORS.PASSWORD),
  password: Yup.string().required(ERRORS.PASSWORD),
  rePassword: Yup.string().oneOf([Yup.ref('password'), null], ERRORS.REPEAT_PASSWORD.match).required(ERRORS.REPEAT_PASSWORD.required),
});

export const COMPANY_EDITOR_SCHEMA = Yup.object().shape({
  companyName: Yup.string().required(ERRORS.COMPANY_EDITOR.required),
});

export const USER_EDITOR_CREATE_SCHEMA = Yup.object().shape({
  email: Yup.string().email(ERRORS.EMAIL.incorrect).required(ERRORS.EMAIL.required),
  firstName: Yup.string(),
  lastName: Yup.string(),
  username: Yup.string(),
  password: Yup.string().required(ERRORS.PASSWORD),
  phone: Yup.string(),
  company: Yup.string(),
  accessLevel: Yup.number().required(ERRORS.ACCESS_LEVEL),
});

export const USER_EDITOR_EDIT_SCHEMA = Yup.object().shape({
  email: Yup.string().email(ERRORS.EMAIL.incorrect).required(ERRORS.EMAIL.required),
  firstName: Yup.string(),
  lastName: Yup.string(),
  username: Yup.string(),
  password: Yup.string(),
  phone: Yup.string(),
  company: Yup.string(),
  accessLevel: Yup.number().required(ERRORS.ACCESS_LEVEL),
});
