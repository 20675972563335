import { call, takeEvery, put } from 'redux-saga/effects';

import { API_ENDPOINTS } from '../constants/APi';

import { IReportData, IAggregatedData } from '../types/IReport';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';
import { http } from '../utils/requests';
import { getStore } from '../store/store';
import { assignFiltersParams } from '../utils/filters.util';

const resource = 'GOAL_TRACKER_AGGREGATED_DATA';
const actions = ['get'];
type reducerType = (IReportData & IAggregatedData)[];

export const goalTrackerAggregatedDataTypes = createActionTypes(resource, actions);
export const goalTrackerAggregatedDataActions = createActions<reducerType>(resource, actions);
export const goalTrackerAggregatedDataReducer = createReducers<reducerType>(resource, actions);

function* getGoalTrackerAggregatedData() {
  try {
    const { goalTrackerFilters } = getStore();

    const query = goalTrackerFilters ? assignFiltersParams(goalTrackerFilters, '?') : '';

    const { reports, groupIDKey } = yield call(http.get, API_ENDPOINTS.AGGREGATED_DATA, undefined, undefined, query);

    yield put(goalTrackerAggregatedDataActions.get.success(reports.map( report => ({...report, groupIDKey}))));
  } catch (err) {
    yield put(goalTrackerAggregatedDataActions.get.fail(err));
  }
}

export function* goalTrackerAggregatedDataSaga() {
  yield takeEvery(goalTrackerAggregatedDataTypes.get.load, getGoalTrackerAggregatedData);
}
