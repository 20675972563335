import { ChartOptions } from 'chart.js';
import { Moment } from 'moment';

function reverse(array: any[]){
  return array.map((item, idx) => array[array.length - 1 - idx]);
}


interface IChartTypes {
  PIE: number;
  BAR: number;
  LINE: number;
  BAR_DASHBOARD: number;
}

export const CHART_TYPES: IChartTypes = {
  PIE: 1,
  BAR: 2,
  LINE: 3,
  BAR_DASHBOARD: 4,
};

export const PIE_CHART_CONFIG: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    reverse: true,
    display: false,
    position: 'right',
    labels: {
      fontFamily: 'Roboto',
      fontColor: '#0356A7',
    },
  },
  legendCallback: (chart: Chart): string => {
    const { data } = chart;
    const reversed = reverse(data.labels || []);
    let legendHTML = '<ul class="chart-legend">';

    try {
      if ( data ) {
        reversed.forEach( (label, index) => {
          // @ts-ignore
          legendHTML += `<li class="chart-legend__item" style="border-color: ${data?.datasets[0]?.backgroundColor[index]}">`
            + label + '</li>';
        });
      }
    } catch (err) {
      console.error(err);
    }

    return legendHTML + '</ul>';
  },
};

export const HORIZONTAL_BAR_CHART_CONFIG: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    reverse: true,
    display: false,
    position: 'right',
    labels: {
      boxWidth: 0,
      fontFamily: 'Roboto',
      fontColor: '#0356A7',
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        }
      }
    ],
    xAxes: [
      {
        display: false,
        ticks: {
          beginAtZero: true,
        }
      }
    ]
  },
  tooltips: {
    intersect: false,
  },
  legendCallback: (chart: Chart): string => {
    const { data } = chart;
    const reversed = reverse(data.datasets || []);
    let legendHTML = '<ul class="chart-legend">';
    try {
      reversed.forEach( ({label, _meta }, idx) => {
        // _meta[1].data[0]._datasetIndex = reversed.length - 1 - idx;
        legendHTML += '<li class="chart-legend__item" onclick="updateDataset(event, ' + chart + ',' + _meta?.["1"]?._datasetIndex + '\'' + ')">' + label + '</li>';
        // onclick="updateDataset(event, ' + '\'' + chart.legend.legendItems[i].datasetIndex + '\'' + ')"
        // legendHtml.push('<td class="chart-legend-label-text" onclick="">' + chart.data.datasets[i].label + '</td>');
      });
    } catch (err) {
      console.error(err);
    }

    return legendHTML + '</ul>';
  }
};      

export const LINE_CHART_CONFIG: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    reverse: true,
    display: false,
    position: 'right',
    labels: {
      fontFamily: 'Roboto',
      fontColor: '#0356A7',
    },
  },
  layout: {
    padding: {
      right: 5,
    },
  },
  scales: {
    yAxes: [
      {
        scaleLabel: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
          beginAtZero: true,
        },
      },
    ],
  },
  elements: {
    line: {
      cubicInterpolationMode: 'monotone',
      backgroundColor: 'transparent',
      borderWidth: 1,
    },
  },
  tooltips: {
    intersect: false,
    enabled: false,
  },
  legendCallback: (chart: Chart): string => {
    const { data } = chart;
    const reversed = reverse(data.labels || []);
    let legendHTML = '<ul class="chart-legend">';

    try {
      reversed.forEach(label => {
        legendHTML += '<li class="chart-legend__item">' + label.toString() + '</li>';
      });
    } catch (err) {
      console.error(err);
    }

    return legendHTML + '</ul>';
  },
};
