import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import Filters from './Filters/Filters';
import Chart from '../Chart/Chart';
import { Table } from './Table/Table';

import { didNotStartLoading, isReady } from '../../utils/valueState';
import { isSuperAdmin } from '../../utils/user.util';

import { IReportData, IReportsGroupByType } from '../../types/IReport';
import { IUser } from '../../types/IUser';
import { IStore } from '../../types/IStore';
import { IProfile } from '../../types/IProfile';
import { ICompany } from '../../types/ICompany';

import { CHART_TYPES } from '../../constants/chart.config';

import { companyProjectionTotalsActions } from '../../sagas/companyProjectionTotals.saga';
import { usersActions } from '../../sagas/users.saga';
import { companyProjectionAggregatedDataActions } from '../../sagas/companyProjectionAggregatedData.saga';
import { companiesActions } from '../../sagas/companies.saga';
import { companyProjectionGroupedReportsActions } from '../../sagas/companyProjectionGroupedByDateReports.saga';

import styles from './CompanyProjection.module.scss';
import { IGoal, IGoalSetting } from '../../types/IGoal';
import { allGoalsActions } from '../../sagas/allGoals.saga';
import { goalActions } from '../../sagas/goal.saga';

interface IPropsFromStore {
  profile: IProfile;
  allGoals: IGoal[];
  goal: IGoalSetting;
  companyProjectionTotals: IReportData;
  users: IUser[];
  companyProjectionAggregatedData: any;
  companies: ICompany[];
  companyProjectionGroupedReports: IReportsGroupByType;
}

interface IDispatchProps {
  getAllGoals: () => void;
  getGoalSetting: () => void;
  getCompanyProjectionTotals: () => void;
  getUsers: () => void;
  getCompanyProjectionAggregatedData: () => void;
  getCompanies: () => void;
  getCompanyProjectionGroupedReports: () => void;
}

const mapStateToProps = ({ companyProjectionTotals, users, profile, companyProjectionAggregatedData, companies, companyProjectionGroupedReports, allGoals, goal}: IStore): IPropsFromStore => ({
  companyProjectionTotals,
  users,
  profile,
  companyProjectionAggregatedData,
  companies,
  companyProjectionGroupedReports,
  allGoals,
  goal
});

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  getGoalSetting: () => dispatch(goalActions.get.load()),
  getCompanyProjectionTotals: () => dispatch(companyProjectionTotalsActions.get.load()),
  getUsers: () => dispatch(usersActions.get.load()),
  getCompanyProjectionAggregatedData: () => dispatch(companyProjectionAggregatedDataActions.get.load()),
  getCompanies: () => dispatch(companiesActions.get.load()),
  getAllGoals: () => dispatch(allGoalsActions.get.load()),
  getCompanyProjectionGroupedReports: () => dispatch(companyProjectionGroupedReportsActions.get.load()),
});

type IProps = IPropsFromStore & IDispatchProps;

class CompanyProjection extends Component<IProps> {
  public componentDidMount(): void {
    const { allGoals, goal, companyProjectionTotals, users, profile, companyProjectionAggregatedData, companies, companyProjectionGroupedReports } = this.props;

    if ( isReady(profile) ) {
      if ( isSuperAdmin(profile) ) {
        if ( didNotStartLoading(companies) ) {
          this.props.getCompanies();
        }
      }
      if ( didNotStartLoading(users) ) {
        this.props.getUsers();
      }
    }
    if ( didNotStartLoading(companyProjectionGroupedReports) ) {
      this.props.getCompanyProjectionGroupedReports();
    }
    if (didNotStartLoading(companyProjectionTotals)) {
      this.props.getCompanyProjectionTotals();
    }
    if ( didNotStartLoading(companyProjectionAggregatedData) ) {
      this.props.getCompanyProjectionAggregatedData();
    }
    if ( didNotStartLoading(allGoals) ) {
      this.props.getAllGoals();
    }
    if ( didNotStartLoading(goal) ) {
      this.props.getGoalSetting();
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { goal, allGoals, profile, users, companies, companyProjectionGroupedReports } = this.props;

    if ( isReady(profile) ) {
      if ( isSuperAdmin(profile) ) {
        if ( didNotStartLoading(companies) ) {
          this.props.getCompanies();
        }
      }
      if ( didNotStartLoading(users) ) {
        this.props.getUsers();
      }
    }
    if ( didNotStartLoading(allGoals) ) {
      this.props.getAllGoals();
    }
    if ( didNotStartLoading(goal) ) {
      this.props.getGoalSetting();
    }
    if ( didNotStartLoading(companyProjectionGroupedReports) ) {
      this.props.getCompanyProjectionGroupedReports();
    }
  }

  public render() {
    const {
      companyProjectionTotals,
      users,
      companyProjectionAggregatedData,
      profile,
      companyProjectionGroupedReports,
      allGoals,
      goal
    } = this.props;

    return (
      <div className={styles.companyProjection}>
        <Filters />
        <Table
          allGoals={allGoals}
          totals={companyProjectionTotals}
          users={users}
          profile={profile}
          groupedReports={companyProjectionGroupedReports}
          goalSetting={goal}
        />
        <div className={styles.chartsRow}>
          {isReady(companyProjectionAggregatedData) && (
            <>
              <Chart selectorEnabled={true} chartType={CHART_TYPES.PIE} aggregatedData={companyProjectionAggregatedData} />
              <Chart selectorEnabled={true} chartType={CHART_TYPES.LINE} aggregatedData={companyProjectionAggregatedData} />
            </>
          )}
        </div>
        <div className={styles.chartsRow}>
          {isReady(companyProjectionAggregatedData) && <Chart selectorEnabled={true} chartType={CHART_TYPES.BAR} aggregatedData={companyProjectionAggregatedData} />}
        </div>
      </div>
    );
  }
}

export default connect<IPropsFromStore, IDispatchProps, any, any>(
  mapStateToProps,
  dispatchProps
)(CompanyProjection);
