import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import { ROUTES } from "../../constants/router";

import { IReport } from "../../types/IReport";
import { IProfile } from "../../types/IProfile";
import { IStore } from "../../types/IStore";

import Report from '../../assets/imgs/report-btn.svg';
import Logo from '../../assets/imgs/5e5ece5c6245456ad28b1fe2.png';

import styles from "./Sidebar.module.scss";
import {
  isSalesPerson,
  isSeniorSalesManagerOrHigher,
  isSuperAdmin,
} from "../../utils/user.util";
import { ACCESS_LEVEL } from "../../types/IUser";

interface IOuterProps {
  blurred: boolean | IReport;
  onClickReport: () => void;
}

interface IPropsFromStore extends Partial<IStore> {
  profile: IProfile;
}

const mapStateToProps = ({ profile }: IStore): IPropsFromStore => ({
  profile,
});

const Sidebar: React.FC<IOuterProps & IPropsFromStore> = ({
  blurred,
  onClickReport,
  profile,
}) => {
  const [toggle, setToggle] = useState(true);

  const toggleSidebar = () => setToggle(!toggle);

  const showButtonReport = React.useMemo(() => {
    return [ACCESS_LEVEL.SUPER_ADMIN, ACCESS_LEVEL.COMPANY_ADMIN].includes(
      profile.accessLevel
    );
  }, []);

  return (
    <div
      className={`${styles.sidebar} ${toggle ? styles.open : ""} ${
        blurred ? styles.blurred : ""
      }`}
    >
      <div className={styles.sidebarTop}>
        <button className={styles.sidebarToggle} onClick={toggleSidebar}>
          <span className={styles.iconSvgArrowLeft} />
        </button>
        <nav className={styles.sidebarMenu}>
          <NavLink
            activeClassName={styles.sidebarMenuItemActive}
            to={ROUTES.Root.path}
            className={styles.sidebarMenuItem}
            exact={true}
          >
            <div className={styles.sidebarMenuItemContainer}>
              <div
                className={`${styles.sidebarMenuItemIcon} ${styles.iconSvgDashboard}`}
              />
              <p className={styles.sidebarMenuItemName}>Dashboard</p>
            </div>
          </NavLink>
          <NavLink
            activeClassName={styles.sidebarMenuItemActive}
            to={ROUTES.Analytics.path}
            className={styles.sidebarMenuItem}
          >
            <div className={styles.sidebarMenuItemContainer}>
              <div
                className={`${styles.sidebarMenuItemIcon} ${styles.iconSvgAnalytics}`}
              />
              <p className={styles.sidebarMenuItemName}>Analytics</p>
            </div>
          </NavLink>
          {!isSalesPerson(profile) && (
            <NavLink
              activeClassName={styles.sidebarMenuItemActive}
              to={ROUTES.GoalTracker.path}
              className={styles.sidebarMenuItem}
            >
              <div className={styles.sidebarMenuItemContainer}>
                <div
                  className={`${styles.sidebarMenuItemIcon} ${styles.iconSvgGoalTracker}`}
                />
                <p className={styles.sidebarMenuItemName}>Goal Tracker</p>
              </div>
            </NavLink>
          )}
          {isSeniorSalesManagerOrHigher(profile) && (
            <NavLink
              activeClassName={styles.sidebarMenuItemActive}
              to={ROUTES.CompanyProjection.path}
              className={styles.sidebarMenuItem}
              title={"Company Projection"}
            >
              <div className={styles.sidebarMenuItemContainer}>
                <div
                  className={`${styles.sidebarMenuItemIcon} ${styles.iconSvgCompanyProjection}`}
                />
                <p className={styles.sidebarMenuItemName}>Company Projection</p>
              </div>
            </NavLink>
          )}
          {isSeniorSalesManagerOrHigher(profile) && (
            <NavLink
              activeClassName={styles.sidebarMenuItemActive}
              to={ROUTES.GoalSettings.path}
              className={styles.sidebarMenuItem}
            >
              <div className={styles.sidebarMenuItemContainer}>
                <div
                  className={`${styles.sidebarMenuItemIcon} ${styles.iconSvgGoalSettings}`}
                />
                <p className={styles.sidebarMenuItemName}>Goal Settings</p>
              </div>
            </NavLink>
          )}
          <NavLink
            activeClassName={styles.sidebarMenuItemActive}
            to={ROUTES.Rating.path}
            className={styles.sidebarMenuItem}
          >
            <div className={styles.sidebarMenuItemContainer}>
              <div
                className={`${styles.sidebarMenuItemIcon} ${styles.iconSvgRating}`}
              />
              <p className={styles.sidebarMenuItemName}>Rating</p>
            </div>
          </NavLink>
          {isSuperAdmin(profile) && (
            <NavLink
              activeClassName={styles.sidebarMenuItemActive}
              to={ROUTES.Admin.path}
              className={styles.sidebarMenuItem}
            >
              <div className={styles.sidebarMenuItemContainer}>
                <div
                  className={`${styles.sidebarMenuItemIcon} ${styles.iconSvgAdmin}`}
                />
                <p className={styles.sidebarMenuItemName}>Admin panel</p>
              </div>
            </NavLink>
          )}
          <NavLink
            activeClassName={styles.sidebarMenuItemActive}
            to={ROUTES.Settings.path}
            className={styles.sidebarMenuItem}
          >
            <div className={styles.sidebarMenuItemContainer}>
              <div
                className={`${styles.sidebarMenuItemIcon} ${styles.iconSvgSettings}`}
              />
              <p className={styles.sidebarMenuItemName}>Settings</p>
            </div>
          </NavLink>
        </nav>
        {showButtonReport && (
          <div className={styles.sidebarBottom}>
            <button
              className={`${styles.sidebarReportBtn} ${styles.hidden}`}
              onClick={onClickReport}
              style={{ background: profile.company?.primaryColor || "#fff" }}
            >
              <img
                src={profile.company?.logo || Logo}
                className={styles.sidebarReportLogo}
              />
              <p className={styles.sidebarReportText}>Report</p>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect<IPropsFromStore, any, any, any>(mapStateToProps)(
  Sidebar
);
