import moment from './moment';

import { GROUP_ID_KEYS } from '../constants/report';

import { IFiltersQuickPeriods } from '../types/IFilters';

export const formatReportTime = (date: string | number, withWeekDay: boolean = false): string =>
  moment(date).format(withWeekDay ? 'dddd MM.DD.YYYY' : 'MM/DD/YYYY');

export const formatReportTimeShort = (date: string | number): string =>
  moment(date).format('MMM DD YYYY');

export const formatGoalTrackerRowTime = (date: string | number, idKey: string): string => {

  if (idKey === GROUP_ID_KEYS.DAY) {
    return moment(date).format('MM/DD/YYYY');
  } else if (idKey === GROUP_ID_KEYS.WEEK) {
    return `Week-${moment(date).format('WW')} (${moment(date).format('MMM Do YYYY')})`;
  }

  return moment(date).format('MMM YYYY');
};

export const formatChartTime = (date: string | number, idKey: string): string => {

  if (idKey === GROUP_ID_KEYS.DAY) {
    return 'Day: ' + moment(date).format('MM.DD.YYYY');
  } else if (idKey === GROUP_ID_KEYS.WEEK) {
    return 'Week: ' + moment(date).format('Wo, YYYY');
  }

  return 'Month: ' + moment(date).format('MMMM, YYYY');
};

export const getQuickFilterDate = ({ amount, amountOf }: IFiltersQuickPeriods): string =>
  // @ts-ignore
  moment.utc().subtract(amount, amountOf).valueOf();

export function getStartAndEndOfWeek(date) {
  const day = date.getDay();
  const startOfWeek = new Date(date);
  const endOfWeek = new Date(date);

  startOfWeek.setDate(date.getDate() - day + 2);
  startOfWeek.setUTCHours(0, 0, 0, 0);

  endOfWeek.setDate(date.getDate() - day + 8);
  endOfWeek.setUTCHours(23, 59, 59, 999);

  return {
    start: startOfWeek.toISOString(),
    end: endOfWeek.toISOString()
  };
}
export function getAllWeeksOfMonth(time) {
  const weeks: any = [];
  const now = new Date(time);
  const year = now.getFullYear();
  const month = now.getMonth() + 1; // JavaScript months are 0-based
  const date = new Date(year, month - 1, 1);

  while (date.getMonth() === month - 1) {
    const week = getStartAndEndOfWeek(date);
    weeks.push({
      start: week.start,
      end: week.end
    });

    date.setDate(date.getDate() + 7);
  }

  return weeks;
}

export function getStartAndEndOfAllMonthsInCurrentYear(time) {
  const now = new Date(time);
  const year = now.getFullYear();
  const months: any = [];
  for (let month = 0; month < 12; month++) {
    const startOfMonth = new Date(year, month, 1);
    startOfMonth.setUTCHours(0, 0, 0, 0);

    const endOfMonth = new Date(year, month + 1, 0);
    endOfMonth.setUTCHours(23, 59, 59, 999);

    months.push({
      month: month + 1, // JavaScript months are 0-based, so we add 1 to get the correct month number
      start: startOfMonth.toISOString(),
      end: endOfMonth.toISOString()
    });
  }
  return months;
}

export function getThisWeekDates(time) {
  var weekDates: any = [];

  for (var i = 1; i <= 7; i++) {
    weekDates.push({
      start: moment(time).day(i).startOf('day'),
      end: moment(time).day(i).endOf('day'),
    });
  }

  return weekDates;
}

interface IDaysOfWeeks { date: any, days: number }

export function getLastDayOfWeeks(year: number, month: number): IDaysOfWeeks[] {
  let date = new Date(year, month - 1, 1);
  let weekEndings: IDaysOfWeeks[] = [];

  // Find the first Saturday of the month
  while (date.getDay() !== 6) {
    date.setDate(date.getDate() + 1);
  }

  // Add the first Saturday regardless of its date
  weekEndings.push({ date: new Date(year, month - 1, date.getDate()), days: date.getDate() });

  // Find the remaining Saturdays of the month
  while (date.getMonth() === month - 1) {
    let prevDate = date.getDate();
    date.setDate(date.getDate() + 7);
    if (date.getMonth() === month - 1) {
      weekEndings.push({ date: new Date(year, month - 1, date.getDate()), days: date.getDate() - prevDate });
    }
  }

  // If the last Saturday is not the last day of the month, add the last day of the month
  let lastDay = new Date(year, month, 0).getDate();
  if (weekEndings[weekEndings.length - 1].date.getDate() !== lastDay) {
    weekEndings.push({ date: new Date(year, month - 1, lastDay), days: lastDay - weekEndings[weekEndings.length - 1].date.getDate() });
  }

  // Format the dates to 'MM/DD/YY' and keep the days count
  weekEndings = weekEndings.map(week => {
    let day = week.date.getDate();
    let month = week.date.getMonth() + 1;
    let year = week.date.getFullYear().toString().substr(-2);
    return { date: `${month}/${day}/${year}`, days: week.days };
  });

  return weekEndings;
};

export function countDaysInWeekOfMonth(weekEnding: string) {
  let [month, day, year] = weekEnding.split('/');
  let date = new Date(`${year.padStart(4, '20')}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`);

  // Get the week endings for the month and year of the week ending date
  let weekEndings = getLastDayOfWeeks(date.getFullYear(), date.getMonth() + 1);
  // Find the week with the given week ending
  let week = weekEndings.find(week => week.date === weekEnding);

  // If the week is found, return the count of days in that week
  if (week) {
    return week.days;
  }

  // If the week is not found, return 0
  return 0;
};