import { call, takeEvery, put } from 'redux-saga/effects';

import { API_ENDPOINTS } from '../constants/APi';

import { IReportData, IAggregatedData } from '../types/IReport';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';
import { http } from '../utils/requests';
import { getStore } from '../store/store';
import { assignFiltersParams } from '../utils/filters.util';

const resource = 'COMPANY_PROJECTION_AGGREGATED_DATA';
const actions = ['get'];
type reducerType = (IReportData & IAggregatedData)[];

export const companyProjectionAggregatedDataTypes = createActionTypes(resource, actions);
export const companyProjectionAggregatedDataActions = createActions<reducerType>(resource, actions);
export const companyProjectionAggregatedDataReducer = createReducers<reducerType>(resource, actions);

function* getCompanyProjectionAggregatedData() {
  try {
    const { companyProjectionFilters } = getStore();

    const query = companyProjectionFilters ? assignFiltersParams(companyProjectionFilters, '?') : '';

    const { reports, groupIDKey } = yield call(http.get, API_ENDPOINTS.AGGREGATED_DATA, undefined, undefined, query);

    yield put(companyProjectionAggregatedDataActions.get.success(reports.map( report => ({...report, groupIDKey}))));
  } catch (err) {
    yield put(companyProjectionAggregatedDataActions.get.fail(err));
  }
}

export function* companyProjectionAggregatedDataSaga() {
  yield takeEvery(companyProjectionAggregatedDataTypes.get.load, getCompanyProjectionAggregatedData);
}
