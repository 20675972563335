import React, { useState } from "react";

import { GroupType, GroupedReportItem } from "../../../types/IReport";

import styles from "./Table.module.scss";
import { isReady } from "../../../utils/valueState";
import { formatNumber } from "../../../utils/formatNumber";

interface IProps {
  report: GroupedReportItem[];
  label: string;
  type: GroupType;
}

const needToFormatCurrency = (label: string) => ['Dollars Built', 'Dollars Booked'].find(el => label.includes(el));

export const AdminRow: React.FC<IProps> = ({ type, report, label }) => {
  const [expand, setExpand] = useState<boolean>(false);

  const onClickExpand = () => setExpand(!expand);

  const renderTotalOfActual = () => {
    let minColumns = 0;
    switch (type) {
      case GroupType.day:
        minColumns = 7;
        break;
      case GroupType.month:
        minColumns = 12;
        break;
      case GroupType.week:
        minColumns = 4;
        break;
    }
    if (report.length < minColumns) {
      return report.concat(new Array(minColumns - report.length).fill(0)).map((data) => {
        if(report) {
          if(needToFormatCurrency(label)) {
            return <p className={styles.column}>{(data as any) !== 0 ? `${formatNumber(data.totalActual, 0, '.', ',')}` : ''}</p>
          }
          return <p className={styles.column}>{data.totalActual || ''}</p>
        }
        return <p className={styles.column}></p>;
      });
    }
    return report.map((data) => {
      if(needToFormatCurrency(label)) {
        return <p className={styles.column}>{formatNumber(data.totalActual, 0, '.', ',') || ''}</p>
      }
      return <p className={styles.column}>{data.totalActual || '0'}</p>
    });
  };

  const total = React.useMemo(() => {
    return report.reduce((sum, obj) => sum + obj.totalActual, 0);
  }, [report]);

  return (
    <div className={`${styles.row} ${styles.rowExpand} ${styles.value}`}>
      <p className={styles.column} onClick={onClickExpand}>
        <span className={styles.label} title={label}>
          {label}
        </span>
      </p>
      {isReady(report) && renderTotalOfActual()}
      <p className={styles.column}>
        {needToFormatCurrency(label) ? `${formatNumber(total, 0, '.', ',')}` : total}
      </p>
    </div>
  );
};
