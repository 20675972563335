import React, { Component, SyntheticEvent } from 'react';
import Select from "react-select";
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { FILTER_QUICK_PERIODS } from '../../../constants/Filters';
import { REPORT_EXPORT_TYPES } from '../../../constants/report';

import { IStore } from '../../../types/IStore';
import { IProfile } from '../../../types/IProfile';
import { IFilters } from '../../../types/IFilters';
import { ACCESS_LEVEL, IUser } from '../../../types/IUser';
import { ICompany } from '../../../types/ICompany';

import { goalSettingsFiltersActions } from '../../../sagas/goalSettingsFilters.saga';

import { isReady } from '../../../utils/valueState';
import { isCompanyAdmin, isCompanyAdminOrLower, isSeniorSalesManager, isSeniorSalesManagerOrHigher, isSuperAdmin } from '../../../utils/user.util';
import moment from '../../../utils/moment';
import { getQuickFilterDate } from '../../../utils/dates';
import { exportGoal } from '../../../utils/goal.util';

import styles from './Filters.module.scss';
import { IOption } from '../../../types/ISelect';
import { IGoalSetting } from '../../../types/IGoal';
interface IOuterProps {
  label: string;
  groupType: string;
  yearOptions: IOption[];
  goal: IGoalSetting;
  selectedYear: IOption;
  usersIDs: string[],
  companiesIDs: string[],
  time: { label: string, value: number }
  setGoalSettingState: (nextState: Partial<any>) => void;
}

interface IPropsFromStore {
  profile: IProfile;
  users: IUser[];
  companies: ICompany[];
}

interface IDispatchProps {
  setGoalSettingsFilters: (payload: IFilters) => void;
}

const mapStateToProps = ({ profile, users, companies}: IStore): IPropsFromStore => ({
  profile,
  users,
  companies,
});

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  setGoalSettingsFilters: (payload: IFilters) =>
    dispatch(goalSettingsFiltersActions.set.update(payload)),
});

type IProps = IOuterProps & IPropsFromStore & IDispatchProps;

interface IState {
  increased: boolean;
  companiesField: string;
  usersField: string;
  selectedCompanies: ICompany[];
  isShowSelected: boolean;
  isExcludeDeactivated: boolean;
  companies: ICompany[];
  companiesIDs: string[],
  pdfExportEnabled: boolean | null;
}

class Filters extends Component<IProps, IState> {
  public state: Readonly<IState> = {
    increased: false,
    companiesField: "",
    usersField: "",
    selectedCompanies: [],
    isShowSelected: false,
    companies: [],
    companiesIDs: [],
    isExcludeDeactivated: true,
    pdfExportEnabled: null,
  };
  private companiesParticipantsAdded: boolean = false;

  public componentDidMount(): void {
    const { companies, users, profile, companiesIDs, setGoalSettingState } = this.props;

    if (isReady(users)) {
      if (isSuperAdmin(profile)) {
        if (isReady(companies)) {
          this.calcCompaniesParticipants();
        }
      } else {
        this.calcCompaniesParticipants();
      }
    }
    if (isReady(profile) && isReady(users)) {
      if(companiesIDs.length === 0) {
        const defaultSelectedUsers = profile.company?.participants?.filter(
          (participant: string) => {
            const user = this.props.users.find((user) => user._id === participant);
            if (user) {
              return ![ACCESS_LEVEL.COMPANY_ADMIN, ACCESS_LEVEL.SUPER_ADMIN].includes(
                user.accessLevel
              )
            }
            return true;
          }
        ) || [];

        let renderedUsers: IUser[] = [];
        if (isSuperAdmin(profile)) {
          renderedUsers = users;
        } else if (isCompanyAdmin(profile)) {
          renderedUsers = users.filter(
            (user) => user.company === profile.company?._id
          );
        } else if (isSeniorSalesManager(profile)) {
          const assignedUsers = profile.assignedUsers;
          renderedUsers = assignedUsers
            ? users.filter((user) => assignedUsers.includes(user._id))
            : [];
        }
        const userForFilter = defaultSelectedUsers.filter(user => renderedUsers.find(u => u._id === user));
        this.props.setGoalSettingsFilters({
          users: userForFilter,
          time: this.props.selectedYear?.value || new Date().getFullYear(),
          excludeDeactivated: this.state.isExcludeDeactivated
        });

        setGoalSettingState({
          usersIDs: userForFilter,
          companiesIDs: [profile.company?._id]
        });
        this.setState({
          companiesIDs: [profile.company?._id || '']
        });
      } else {
        this.props.setGoalSettingsFilters({
          time: this.props.selectedYear?.value || new Date().getFullYear(),
        });
      }
      this.setState({
        pdfExportEnabled: !isSuperAdmin(profile),
      });

    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { companies, users, companiesIDs, usersIDs, profile } = this.props;

    if (isReady(users) && !this.companiesParticipantsAdded) {
      if (isSuperAdmin(profile)) {
        if (isReady(companies)) {
          this.calcCompaniesParticipants();
        }
      } else {
        this.calcCompaniesParticipants();
      }
    }
    if (isReady(profile) && this.state.pdfExportEnabled === null) {
      this.setState({
        pdfExportEnabled: !isSuperAdmin(profile),
      });
    }
  }

  public componentWillUnmount(): void {
    this.props.setGoalSettingsFilters({
      time: null,
      users: [],
      excludeDeactivated: false,
    });
  }

  private calcCompaniesParticipants = () => {
    const { users, profile } = this.props;

    let companies: ICompany[] = [];
    if (isSuperAdmin(profile)) {
      companies = [...this.props.companies];
    } else {
      companies = profile.company ? [profile.company] : [];
    }

    companies.forEach((company) => {
      company.participants = users
        .filter((user) => user.company === company._id)
        .map(({ _id }) => _id);
    });

    this.setState(
      {
        companies,
      },
      () => (this.companiesParticipantsAdded = true)
    );
  };

  private increasedToggle = () =>
    this.setState({
      increased: !this.state.increased,
    });

  private setTime = (option: any) => {
    this.props.setGoalSettingState({
      time: option,
    });
  } 

  private onSelectAllUsers = () => {
    const { users, profile } = this.props;
    let renderedUsers: IUser[] = [];

    if (isSuperAdmin(profile)) {
      renderedUsers = users;
    } else if (isCompanyAdmin(profile)) {
      renderedUsers = users.filter(
        (user) => user.company === profile.company?._id
      );
    } else if (isSeniorSalesManager(profile)) {
      const assignedUsers = profile.assignedUsers;
      renderedUsers = assignedUsers
        ? users.filter((user) => assignedUsers.includes(user._id))
        : [];
    }
    const userForFilter = renderedUsers.filter(u => ![ACCESS_LEVEL.COMPANY_ADMIN, ACCESS_LEVEL.SUPER_ADMIN].includes(u.accessLevel)).map(u => u._id);
    this.props.setGoalSettingState({
      usersIDs: userForFilter,
    });
  }

  private onClickApply = () => {
    const { isExcludeDeactivated, companiesIDs } = this.state;
    const { groupType, time, usersIDs } = this.props;
    
    this.props.setGoalSettingState(
      // @ts-ignore
      {
        usersIDs,
        companiesIDs,
      }
    );

    this.props.setGoalSettingsFilters({
      time: time ? time.value : null,
      groupType,
      users: usersIDs,
      excludeDeactivated: isExcludeDeactivated,
    });
  };

  private onChangeSearchField = (e: SyntheticEvent, field: string) => {
    const { target } = e;

    this.setState(
      // @ts-ignore
      {
        [field]: (target as HTMLInputElement).value as string,
      }
    );
  };

  private resetFilter = (field: string) => () => {
    this.props.setGoalSettingState(
      // @ts-ignore
      {
        [field]: "",
        usersIDs: [],
        companiesIDs: [],
      }
    );
    this.setState({
      companiesIDs: []
    });
  };

  private onUserSelect = (user: IUser) => () => {
    const usersIDs: string[] = [...this.props.usersIDs];
    const userIDIndex = usersIDs.findIndex((id) => id === user._id);

    userIDIndex >= 0
      ? usersIDs.splice(userIDIndex, 1)
      : usersIDs.push(user._id);

    this.props.setGoalSettingState({
      usersIDs,
    });
    const companiesIDs: string[] = [...this.state.companiesIDs];

    this.state.companies.forEach( company => {
      // @ts-ignore
      const isUsersFromCompany = company.participants.every(participant => this.props.usersIDs.includes(participant));
      const companyIDIndex = companiesIDs.findIndex(id => id === company._id);

      if ( isUsersFromCompany && companyIDIndex < 0 ) {
        companiesIDs.push(company._id);
      } else if ( !isUsersFromCompany && companyIDIndex >= 0 ) {
        companiesIDs.splice(companyIDIndex, 1);
      }
    });

    this.setState({
      companiesIDs,
      pdfExportEnabled: this.props.usersIDs.length === 1,
    })
  };

  private onCompanySelect = (company: ICompany) => () => {
    const companiesIDs: string[] = [...this.state.companiesIDs];
    const companyIDIndex = companiesIDs.findIndex((id) => id === company._id);

    companyIDIndex >= 0
      ? companiesIDs.splice(companyIDIndex, 1)
      : companiesIDs.push(company._id);

    // @ts-ignore
    const notSelectedUsers = company.participants.filter(
      (participant: string) => {
        const user = this.props.users.find((user) => user._id === participant);
        if (user) {
          return (
            ![ACCESS_LEVEL.COMPANY_ADMIN, ACCESS_LEVEL.SUPER_ADMIN].includes(
              user.accessLevel
            ) && !this.props.usersIDs.some((id) => participant === id)
          );
        }
        return !this.props.usersIDs.some((id) => participant === id);
      }
    );

    if(companyIDIndex < 0) {
      this.props.setGoalSettingState({
        usersIDs: [...this.props.usersIDs, ...notSelectedUsers],
      });
    } else {
      this.props.setGoalSettingState({
        usersIDs: this.props.usersIDs.filter((id) => !company.participants?.find((participant) => participant === id))
      });
    }
    this.setState({
      companiesIDs
    });
  };

  private checkIsUserActive = (user: IUser): boolean =>
    this.props.usersIDs.some((id) => id === user._id);

  private checkIsCompanyActive = (company: ICompany): boolean =>
    this.state.companiesIDs.some((id) => id === company._id);

  private setQuickFilter = (period: string) => () => {
    const { LAST_MONTH, LAST_YEAR, THIS_YEAR } = FILTER_QUICK_PERIODS;

    switch(period) {
      case LAST_MONTH.name:
        this.props.setGoalSettingState({
          time: new Date(getQuickFilterDate(LAST_MONTH)),
          groupType: LAST_MONTH.name,
        });
        break;
      case LAST_YEAR.name:
        this.props.setGoalSettingState({
          time: new Date().getFullYear() - 1,
          groupType: LAST_YEAR.name,
        });
      case THIS_YEAR.name:
        this.props.setGoalSettingState({
          time: new Date().getFullYear(),
          groupType: THIS_YEAR.name,
        });
        break;
      default:
        break;
    }
  };

  private onChangeShowSelected = (e: SyntheticEvent) => {
    const target = e.target as HTMLInputElement;

    this.setState({
      isShowSelected: target?.checked || false,
    });
  };

  private onChangeExcludeDeactivated = (e: SyntheticEvent) => {
    const target = e.target as HTMLInputElement;

    this.setState({
      isExcludeDeactivated: target?.checked || false,
    })
  };

  private exportGoal = (type: string) => () => {
    const { time } = this.props;
    const year = time.value || new Date().getFullYear();
    const date = moment([year, 1, 1]);
    const from = date.startOf("year").format("YYYY-MM-DD");
    const to = date.endOf("year").format("YYYY-MM-DD");
    exportGoal(type, this.state.isExcludeDeactivated, this.props.usersIDs, year).then(({ data }) =>
      window.open(data, "_self")
    );
  };

  private renderUsers = (users: IUser[], filterField: string) => {
    const { profile } = this.props;
    let renderedUsers: IUser[] = [];
    if (isSuperAdmin(profile)) {
      renderedUsers = users;
    } else if (isCompanyAdmin(profile)) {
      renderedUsers = users.filter(
        (user) => user.company === profile.company?._id
      );
    } else if (isSeniorSalesManager(profile)) {
      const assignedUsers = profile.assignedUsers;
      renderedUsers = assignedUsers
        ? users.filter((user) => assignedUsers.includes(user._id))
        : [];
    }
    return renderedUsers
      .filter(u => ![ACCESS_LEVEL.COMPANY_ADMIN, ACCESS_LEVEL.SUPER_ADMIN].includes(u.accessLevel))
      .filter((user) => {
        const userName =
          user.firstName && user.lastName
            ? `${user.firstName} ${user.lastName}`
            : user.username || user.email;

        if (!this.state.isShowSelected) {
          if(!this.state.isExcludeDeactivated) {
            return userName.match(filterField);
          } else {
            return userName.match(filterField) && user.isActive;
          }
        } else {
          const selectedCompanies = this.state.companies.filter((company) =>
            this.state.companiesIDs.some((id) => id === company._id)
          );

          if(!this.state.isExcludeDeactivated) {
            // @ts-ignore
            return userName.match(filterField) && selectedCompanies.some(company => company.participants.includes(user._id));
          } else {
            // @ts-ignore
            return userName.match(filterField) && selectedCompanies.some(company => company.participants.includes(user._id) && user.isActive);
          }
        }
      })
      .map((user) => (
        <li
          key={user._id}
          onClick={this.onUserSelect(user)}
          className={this.checkIsUserActive(user) ? styles.active : ""}
        >
          {user.firstName && user.lastName
            ? `${user.firstName} ${user.lastName}`
            : user.username || user.email}
        </li>
      ));
  };

  private renderCompanies = (companies: ICompany[], filterField: string) =>
    companies
      .filter((company) => company.name.match(filterField))
      .map((company) => (
        <li
          key={company._id}
          onClick={this.onCompanySelect(company)}
          className={this.checkIsCompanyActive(company) ? styles.active : ""}
        >
          {company.name}
        </li>
      ));

  public render() {
    const {
      increased,
      companiesField,
      usersField,
      isShowSelected,
      companies,
      companiesIDs,
      isExcludeDeactivated
    } = this.state;
    
    const { label,time, profile, users, usersIDs, yearOptions } = this.props;

    return (
      <div className={`${styles.filters} ${increased ? styles.increased : ""}`}>
        <div className={styles.header}>
          <div className={styles.timeRange}>
            { label && <p className={styles.label}>{label}</p>}
            <div
              className={`${styles.pickerWrapper} picker--custom ${
                !time ? "empty" : ""
              }`}
            >
              <Select
                defaultValue={time}
                options={yearOptions}
                className="filter-selector color"
                classNamePrefix="filter-selector"
                isSearchable={false}
                onChange={this.setTime}
              />
            </div>
            <button
              className={styles.moreFiltersBtn}
              onClick={this.increasedToggle}
            >
              More filters
            </button>
            <button className={styles.applyBtn} onClick={this.onClickApply}>
              Apply
            </button>
          </div>
          <div className={styles.export}>
            <button
              className={styles.exportBtn}
              onClick={this.exportGoal(REPORT_EXPORT_TYPES.CSV.name)}
            >
              XLSX
            </button>
          </div>
        </div>
        {increased && (
          <>
            <div className={styles.content}>
              {isSuperAdmin(profile) && (
                <div className={styles.area}>
                  <div className={styles.areaSearch}>
                    <label
                      htmlFor="companies"
                      className={styles.areaSearchTitle}
                    >
                      Companies
                    </label>
                    <input
                      id="companies"
                      type="text"
                      value={companiesField}
                      className={styles.areaSearchField}
                      onChange={(e) =>
                        this.onChangeSearchField(e, "companiesField")
                      }
                    />
                    <button
                      disabled={
                        !companiesField.length && companiesIDs.length === 0
                      }
                      onClick={this.resetFilter("companiesField")}
                      className={styles.areaSearchReset}
                    >
                      Reset
                    </button>
                  </div>
                  <ul className={styles.areaList}>
                    {this.renderCompanies(companies, companiesField)}
                  </ul>
                </div>
              )}

              <div className={styles.area}>
                <div className={styles.areaSearch}>
                  <label htmlFor="users" className={styles.areaSearchTitle}>
                    Users
                  </label>
                  <input
                    id="users"
                    type="text"
                    value={usersField}
                    className={styles.areaSearchField}
                    onChange={(e) => this.onChangeSearchField(e, "usersField")}
                  />
                  <button
                    disabled={!usersField.length && usersIDs?.length === 0}
                    onClick={this.resetFilter("usersField")}
                    className={styles.areaSearchReset}
                  >
                    Reset
                  </button>
                </div>
                <ul className={`${styles.areaList} ${styles.areaListCheckbox}`}>
                  {isReady(users) && this.renderUsers(users, usersField)}
                </ul>
                {isReady(companies) && isSuperAdmin(profile) && (
                  <label
                    className={`${styles.fieldCheckbox} ${
                      !companies.length ? styles.disabled : ""
                    }`}
                  >
                    <input
                      checked={isShowSelected}
                      disabled={!companies.length}
                      type="checkbox"
                      onChange={this.onChangeShowSelected}
                    />
                    <span className={styles.checkerCheckbox} />
                    <p className={styles.fieldTitle}>
                      Show users only from selected companies
                    </p>
                  </label>
                )}

                {isReady(companies) && isSeniorSalesManagerOrHigher(profile) && (
                  <label
                    className={`${styles.fieldCheckbox}`}
                  >
                    <input
                      checked={isExcludeDeactivated}
                      type="checkbox"
                      onChange={this.onChangeExcludeDeactivated}
                    />
                    <span className={styles.checkerCheckbox} />
                    <p className={styles.fieldTitle}>
                      Exclude deactivated users
                    </p>
                  </label>
                )}
              </div>
            </div>
            <button
              className={`${styles.applyBtn} ${styles.applyBtnIncreased}`}
              onClick={this.onClickApply}
            >
              Apply
            </button>
            {
              isCompanyAdminOrLower(profile) && (
                <button className={styles.applyBtn} onClick={this.onSelectAllUsers}>
                  Select All
                </button>
              )
            }
          </>
        )}
      </div>
    );
  }
}

export default connect<IPropsFromStore, IDispatchProps, any, any>(
  mapStateToProps,
  dispatchProps
)(Filters);
