import { call, takeEvery, put } from 'redux-saga/effects';

import { API_ENDPOINTS } from '../constants/APi';

import { IGoalQuestion } from '../types/IGoal';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';
import { http } from '../utils/requests';

const resource = 'GOAL_QUESTIONS';
const actions = ['get'];
type reducerType = IGoalQuestion;

export const goalQuestionsTypes = createActionTypes(resource, actions);
export const goalQuestionsActions = createActions<reducerType>(resource, actions);
export const goalQuestionsReducer = createReducers<reducerType>(resource, actions);

function* getGoalQuestions() {
  try {
    const response = yield call(http.get, API_ENDPOINTS.GOAL_QUESTIONS);
    yield put(goalQuestionsActions.get.success(response));
  } catch (err) {
    yield put(goalQuestionsActions.get.fail(err));
  }
}

export function* goalQuestionsSaga() {
  yield takeEvery(goalQuestionsTypes.get.load, getGoalQuestions);
}
