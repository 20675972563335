import { takeEvery, put } from 'redux-saga/effects';

import { ICompany } from '../types/ICompany';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';

import { history } from '../store/store';

import { ROUTES } from '../constants/router';

const resource = 'SELECTED_COMPANY';
const actions = ['set', 'clear'];
type reducerType = ICompany;

export const selectedCompanyTypes = createActionTypes(resource, actions);
export const selectedCompanyActions = createActions<reducerType>(resource, actions);
export const selectedCompanyReducer = createReducers<reducerType>(resource, actions);

function* setCompany({payload}: {[payload: string]: ICompany}) {
  yield put(selectedCompanyActions.set.success(payload));
  history.push(ROUTES.CompanyEditor.path);
}

function* clearCompany() {
  yield put(selectedCompanyActions.clear.success(null));
}

export function* selectedCompanySaga() {
  yield takeEvery(selectedCompanyTypes.set.update, setCompany);
  yield takeEvery(selectedCompanyTypes.clear.update, clearCompany);
}
