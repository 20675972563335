import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IRecentReports } from '../../types/IReport';
import { IStore } from '../../types/IStore';
import { IProfile } from '../../types/IProfile';

import { didNotStartLoading, isReady } from '../../utils/valueState';
import { isSuperAdmin } from '../../utils/user.util';

import { recentReportsActions } from '../../sagas/recentReports.saga';

import { TableRecentReports } from './tableRecentReports/TableRecentReports';
import { CalculationsTable } from '../CalculationsTable/CalculationsTable';

import { CHART_TYPES } from '../../constants/chart.config';

import Chart from '../Chart/Chart';

import styles from './Dashboard.module.scss';
import { usersActions } from '../../sagas/users.saga';
import { IUser } from '../../types/IUser';

interface IPropsFromStore extends Partial<IStore> {
  recentReports: IRecentReports;
  profile: IProfile;
  users: IUser[];
}

interface IDispatchProps {
  getRecentReports: () => void;
  getUsers: () => void;
}

const mapStateToProps = ({
  recentReports,
  profile,
  users
}: IStore): IPropsFromStore => ({
  recentReports,
  profile,
  users
});

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  getRecentReports: () => dispatch(recentReportsActions.get.load()),
  getUsers: () => dispatch(usersActions.get.load())
});

type IProps = IPropsFromStore & IDispatchProps;

class Dashboard extends Component<IProps> {
  public componentDidMount(): void {
    if (didNotStartLoading(this.props.recentReports)) {
      this.props.getRecentReports();
    }
    if (didNotStartLoading(this.props.users)) {
      this.props.getUsers();
    }
  }

  public render() {
    const { recentReports, profile } = this.props;
    return (
      <div className={styles.dashboard}>
        <div className={styles.dashboardLastNotes}>
          {recentReports?.dates?.length > 0 && (
            <p className={styles.dashboardLastNotesTitle}>
              Last{' '}
              {recentReports?.dates?.length > 1
                ? recentReports?.dates?.length + ' days'
                : 'day'}
            </p>
          )}
          {isReady(recentReports) && (
            <TableRecentReports
              {...recentReports}
              admin={isSuperAdmin(profile)}
            />
          )}
        </div>
        <div className={styles.chartsRow}>
          {isReady(recentReports) && (
            <CalculationsTable reports={recentReports.reports} />
          )}
          {isReady(recentReports) && <Chart selectorEnabled={true} reports={recentReports.reports} chartType={CHART_TYPES.BAR_DASHBOARD} />}
        </div>
      </div>
    );
  }
}

export default connect<IPropsFromStore, IDispatchProps, any, any>(
  mapStateToProps,
  dispatchProps
)(Dashboard);
