import { takeEvery, put } from 'redux-saga/effects';

import { IFilters, IFiltersPayload } from '../types/IFilters';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';

import { goalActions } from './goal.saga';

const resource = 'GOAL_SETTINGS_FILTERS';
const actions = ['set'];
type reducerType = IFilters;

export const goalSettingsFiltersTypes = createActionTypes(resource, actions);
export const goalSettingsFiltersActions = createActions<reducerType>(resource, actions);
export const goalSettingsFiltersReducer = createReducers<reducerType>(resource, actions);

function* setGoalSettingsFilters({payload}: IFiltersPayload) {
  yield put(goalSettingsFiltersActions.set.success(payload));
  yield put(goalActions.get.load());
}

export function* goalSettingsFiltersSaga() {
  yield takeEvery(goalSettingsFiltersTypes.set.update, setGoalSettingsFilters);
}
