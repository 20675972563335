import React, { SyntheticEvent, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IStore } from '../../../../types/IStore';
import { IPagination } from '../../../../types/IPagination';

import { paginationActions } from '../../../../sagas/pagination.saga';

import styles from './Pagination.module.scss';

interface IPropsFromStore {
  pagination: IPagination;
}

interface IDispatchProps {
  setActivePage: (payload: number) => void;
}

const mapStateToProps = ({ pagination }: IStore): IPropsFromStore => ({
  pagination
});

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  setActivePage: (payload: number) =>
    dispatch(paginationActions.activePage.update(payload))
});

type IProps = IPropsFromStore & IDispatchProps;

const Pagination: React.FC<IProps> = ({ pagination, setActivePage }) => {
  const { prevPage, nextPage, activePage, pages } = pagination;
  const [page, setPage] = useState<string | number>('');

  const onClickPage = (pageClicked: number) => () => {
    console.log('pageClicked', pageClicked);
    setActivePage(pageClicked === 0 ? 1 : pageClicked);
  };

  const onInputPage = (e: SyntheticEvent) => {
    const value = (e.target as HTMLInputElement).value;

    setPage(+value <= pages ? +value : pages);
  };

  return (
    <div className={styles.pagination}>
      {prevPage && prevPage !== activePage && (
        <button onClick={onClickPage(prevPage)} className={styles.button} />
      )}
      <ul className={styles.list}>
        {prevPage && prevPage > 1 && <li onClick={onClickPage(1)}>1</li>}
        {prevPage && prevPage !== activePage && (
          <li onClick={onClickPage(activePage - 1)}>{activePage - 1}</li>
        )}
        <li className={styles.active}>{activePage}</li>
        {nextPage && nextPage !== activePage && (
          <li onClick={onClickPage(activePage + 1)}>{activePage + 1}</li>
        )}
        {nextPage && nextPage < pages && (
          <li onClick={onClickPage(pages)}>{pages}</li>
        )}
      </ul>
      {nextPage && nextPage !== activePage && (
        <button
          onClick={onClickPage(nextPage)}
          className={`${styles.button} ${styles.next}`}
        />
      )}
      <div className={styles.navigation}>
        <p className={styles.navigationTitle}>Go to</p>
        <input
          type="text"
          placeholder="--"
          max={pages}
          value={page}
          className={styles.navigationField}
          onChange={onInputPage}
        />
        <button
          onClick={onClickPage(+page)}
          className={styles.navigationButton}
        >
          Go
        </button>
      </div>
    </div>
  );
};

export default connect<IPropsFromStore, IDispatchProps, any, any>(
  mapStateToProps,
  dispatchProps
)(Pagination);
