const { NODE_ENV } = process.env;
const { protocol, hostname } = window.location;

const API_PREFIXES = {
  AUTH: 'auth/',
  PROFILE: 'profile/',
  REPORT: 'reports/',
  USERS: 'users/',
  COMPANIES: 'companies/',
  EXPORT: 'exports/',
  RATINGS: 'ratings/',
  NOTIFICATIONS: 'notifications/',
  GOAL: 'goal',
  GOAL_QUESTIONS: 'goalQuestions/',
};

export const API_URL = `${protocol}//${hostname}:${NODE_ENV === 'development' ? 3006 : ''}/`;
// export const API_URL = `http://18.234.4.63/`;
export const API_MAIN_PATH = 'api/';

export const API_ENDPOINTS = {
  LOGIN: API_MAIN_PATH + API_PREFIXES.AUTH + 'login',
  LOGOUT: API_MAIN_PATH + API_PREFIXES.AUTH + 'log-out',
  FORGOT_PASS_STEP_EMAIL: API_MAIN_PATH + API_PREFIXES.AUTH + 'forgot-pass',
  FORGOT_PASS_STEP_RESET: API_MAIN_PATH + API_PREFIXES.AUTH + 'forgot-pass-reset',
  PROFILE: API_MAIN_PATH + API_PREFIXES.PROFILE,
  PROFILE_FIREBASE: API_MAIN_PATH + API_PREFIXES.PROFILE + 'f-token',
  PROFILE_IMG: API_MAIN_PATH + API_PREFIXES.PROFILE + 'image',
  RESET_PASSWORD: API_MAIN_PATH + API_PREFIXES.PROFILE + 'reset-password',
  REPORTS: API_MAIN_PATH + API_PREFIXES.REPORT,
  REPORTS_GROUPED: API_MAIN_PATH + API_PREFIXES.REPORT + 'grouped',
  GET_REPORT: API_MAIN_PATH + API_PREFIXES.REPORT + 'report/',
  TOTALS: API_MAIN_PATH + API_PREFIXES.REPORT + 'totals',
  RECENT_REPORTS: API_MAIN_PATH + API_PREFIXES.REPORT +'recent-reports',
  USERS: API_MAIN_PATH + API_PREFIXES.USERS,
  USERSBYCOMPANY: API_MAIN_PATH + API_PREFIXES.USERS + 'company',
  COMPANIES: API_MAIN_PATH + API_PREFIXES.COMPANIES,
  COMPANY_LOGO: API_MAIN_PATH + API_PREFIXES.COMPANIES + 'logo',
  AGGREGATED_DATA: API_MAIN_PATH + API_PREFIXES.REPORT + 'aggregated',
  PERCENT_ANALYTICS_DATA: API_MAIN_PATH + API_PREFIXES.REPORT + 'percent-analytics',
  EXPORT_REPORTS: API_MAIN_PATH + API_PREFIXES.EXPORT + 'reports',
  RATINGS: API_MAIN_PATH + API_PREFIXES.RATINGS,
  NOTIFICATIONS: API_MAIN_PATH + API_PREFIXES.NOTIFICATIONS,
  NOTIFICATION_CONFIRM: API_MAIN_PATH + API_PREFIXES.NOTIFICATIONS + 'confirm',
  NOTIFICATION_REJECT: API_MAIN_PATH + API_PREFIXES.NOTIFICATIONS + 'reject',
  NOTIFICATION_REMOVE: API_MAIN_PATH + API_PREFIXES.NOTIFICATIONS + 'remove',
  GOAL: API_MAIN_PATH + API_PREFIXES.GOAL,
  GOAL_ALL: API_MAIN_PATH + API_PREFIXES.GOAL + '/findAll',
  GOAL_COMPANY: API_MAIN_PATH + API_PREFIXES.GOAL + '/company',
  GOAL_QUESTIONS: API_MAIN_PATH + API_PREFIXES.GOAL_QUESTIONS,
  EXPORT_GOAL: API_MAIN_PATH + API_PREFIXES.EXPORT + 'goal-settings',
  EXPORT_GOAL_TRACKER: API_MAIN_PATH + API_PREFIXES.EXPORT + 'goal-tracker',
};
