import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import moment from 'moment';

import { EReportType, GroupType, GroupedReportItem, IReportData, IReportsGroupByType } from '../../../types/IReport';
import { IUser } from '../../../types/IUser';
import { IProfile } from '../../../types/IProfile';

import Pagination from './Pagination/Pagination';

import { isReady } from '../../../utils/valueState';

import styles from './Table.module.scss';
import { AdminRow } from './AdminRow';
import { DifferentRow } from './DifferentRow';
import { AdminNeedsRow } from './AdminNeedsRow';
import { IGoal, IGoalSetting } from '../../../types/IGoal';
import { getNeedsValue, mappingGroupedReportObject } from '../../../utils/goal.util';
import { formatNumber } from '../../../utils/formatNumber';
import { getLastDayOfWeeks } from '../../../utils/dates';

const rows = {
  leadsActual: {
    label: "Leads",
  },
  evaluations: {
    label: "Inspect",
  },
  insuranceLooks: {
    label: "Signed Agreements",
  },
  adjusterMeetings: {
    label: "Adjuster Meet",
  },
  approvals: {
    label: "Job Aprvls",
  },
  projectsTurnedIn: {
    label: "Jobs Built",
  },
  dollarsBooked: {
    label: "Dollars Booked",
  },
  dollarsClosed: {
    label: "Dollars Built",
  },
};

interface IProps {
  totals: IReportData;
  users: IUser[];
  profile: IProfile;
  groupedReports: IReportsGroupByType;
  allGoals: IGoal[];
  goalSetting: IGoalSetting;
}

export const Table: React.FC<IProps> = ({ totals, users, profile, groupedReports, allGoals, goalSetting }) => {
  useEffect(() => {
    ReactTooltip.rebuild()
  });

  const renderGroupedReports = () => {
    Object.keys(groupedReports.reports).forEach(key => groupedReports[key] === undefined ? delete groupedReports[key] : {});
    const mappedReports = mappingGroupedReportObject(groupedReports);
    
    return mappedReports.map(({ data, key }, idx) => {
      let totalNeeds = 0;
      let report = data as GroupedReportItem[];
      const diff: any[] = [];
      let minColumns = 0;
      switch (groupedReports.type) {
        case GroupType.day:
          minColumns = 7;
          break;
        case GroupType.month:
          minColumns = 12;
          break;
        case GroupType.week:
          minColumns = 4;
          break;
      }
      let reportData = report;
      if (report.length < minColumns) {
        reportData = report.concat(new Array(minColumns - report.length).fill(0))
      }
      reportData.map((data, idx) => {
          if ((data as any) !== 0) {
            let needs = 0;
            groupedReports.creators.forEach((creator) => {
              // const itemMatch = data.reports.find(
              //   (r) => r.creator === creator?._id
              // );
              // if (itemMatch) {
                const year = new Date(data.firstDay).getFullYear();
                needs += getNeedsValue({
                  type: key as EReportType,
                  questions:
                  allGoals?.find ? (allGoals?.find((goal) => goal.userId === creator?._id && goal.year === year)?.items ||
                    []) : [],
                    firstDay: groupedReports.type !== GroupType.week ? data.firstDay: data.lastDay,
                  groupType: groupedReports.type,
                  dollarBuiltActual: groupedReports.reports.dollarsClosed[
                    idx
                  ].reports.reduce((sum, currVal) => sum + currVal.actual, 0),
                  dollarsClosed: totals.dollarsClosed || 0,
                  projectsTurnedIn: totals.projectsTurnedIn || 0,
                }) || 0;
              // }
            });

            let total = data.totalActual - needs;
            diff.push({
              label: rows[key].label,
              value: Math.round(total) === 0 ?  0 : total,
            });

            report[idx].needs = needs;
            totalNeeds += needs;
          } else {
            diff.push({
              label: rows[key].label,
              value: '',
            });
          }
      });

      return <div className={styles.rowWrapper} key={`row_${key}`}>
        <AdminNeedsRow
          key={key}
          users={users}
          profile={profile}
          allGoals={allGoals || []}
          creators={groupedReports.creators}
          report={report}
          label={`${rows[key].label} Needs`}
          goalType={key as EReportType}
          type={groupedReports.type}
          groupedReports={groupedReports}
          totals={totals}
          totalNeeds={totalNeeds}
          goalSetting={goalSetting}
        />
        <AdminRow
          key={`${key}_${idx}`}
          report={report}
          label={`${rows[key].label} Actual`}
          type={groupedReports.type}
        />

        <DifferentRow diff={diff} type={groupedReports.type} />
      </div>
    })
  };

  const renderGroupedReportsHeader = () => {
    Object.keys(groupedReports.reports).forEach((key) =>
      groupedReports[key] === undefined ? delete groupedReports[key] : {}
    );
    const sampleValue = groupedReports.reports.adjusterMeetings;
    switch (groupedReports.type) {
      case GroupType.day:
        if (sampleValue?.length < 7) {
          return sampleValue.concat(new Array(7 - sampleValue.length).fill(0)).map((report) => {
            if(report) {
              const day = report.firstDay;
              return (
                <p className={`${styles.column} ${styles.headerName}`}>
                  {moment.utc(day).format("MM/DD/YYYY")}
                </p>
              );
            };

            return <p className={`${styles.column} ${styles.headerName}`}></p>
          });
        }
        return sampleValue.map((report) => {
          const day = report.firstDay;
          return (
            <p className={`${styles.column} ${styles.headerName}`}>
              {moment.utc(day).format("MM/DD/YYYY")}
            </p>
          );
        });
      case GroupType.month:
        if (sampleValue?.length < 12) {
          return sampleValue
            .concat(new Array(12 - sampleValue.length).fill(0))
            .map((report) => {
              if (report) {
                const month = report._id.month;

                return (
                  <p className={`${styles.column} ${styles.headerName}`}>
                    {`${moment()
                      .month(month - 1)
                      .format("MMM")} ${moment.utc(report.firstDay).year()}`}
                  </p>
                );
              }

              return (
                <p className={`${styles.column} ${styles.headerName}`}></p>
              );
            });
        }
        return sampleValue?.map((report) => {
          const month = report._id.month;

          return (
            <p className={`${styles.column} ${styles.headerName}`}>
              {`${moment()
                .month(month - 1)
                .format("MMM")} ${moment.utc(report.firstDay).year()}`}
            </p>
          );
        });
      case GroupType.week:
        if (sampleValue?.length < 4) {
          return sampleValue
            .concat(new Array(4 - sampleValue.length).fill(0))
            .map((report) => {
              if (report._id) {
                let weekOfMonth = report?.weekOfMonth;
                const day = report.lastDay;
                if (day && !weekOfMonth) {
                  const weeks = getLastDayOfWeeks(moment.utc(day).year(), moment.utc(day).month() + 1)
                  if (weeks) {
                    const weekIndex = weeks.findIndex(item => item.date === moment.utc(day).format('M/D/YY'))
                    if (weekIndex >= 0) {
                      weekOfMonth = weekIndex + 1;
                    }
                  }
                }
                return (
                  <p className={`${styles.column} ${styles.headerName}`}>
                    <p>{`Week-${weekOfMonth || 1}`}</p>
                    <p className={styles.weekText}>({moment.utc(day).format("MMM Do YYYY")})</p>
                  </p>
                );
              }
              return (
                <p className={`${styles.column} ${styles.headerName}`}></p>
              );
            });
      }
      return sampleValue.map((report) => {
        if (report) {
          let weekOfMonth = report?.weekOfMonth;
          const day = report.lastDay;
          if (day && !weekOfMonth) {
            const weeks = getLastDayOfWeeks(moment.utc(day).year(), moment.utc(day).month() + 1)
            if (weeks) {
              const weekIndex = weeks.findIndex(item => item.date === moment.utc(day).format('M/D/YY'))
              if (weekIndex >= 0) {
                weekOfMonth = weekIndex + 1;
              }
            }
          }
          return (
            <p className={`${styles.column} ${styles.headerName}`}>
              <p>{`Week-${weekOfMonth || 1}`}</p>
              <p className={styles.weekText}>({moment.utc(day).format("MMM Do YYYY")})</p>
            </p>
          );
        }
        return <p className={`${styles.column} ${styles.headerName}`}></p>;
      });
    }
  };

  return (
    <div className={styles.table}>
      <div className={`${styles.row} ${styles.header}`}>
        <p className={`${styles.column} ${styles.headerName}`}>Budget Category</p>
        {isReady(groupedReports) && (
          <>
            {renderGroupedReportsHeader()}
            <p className={`${styles.column} ${styles.headerName}`}>Totals</p>
          </>
        )}
      </div>
      {isReady(profile) && isReady(groupedReports) && (
        <>
          <div className={styles.tableContent}>
            {renderGroupedReports()}
          </div>
          <Pagination />
        </>
      )}
    </div>
  );
};
