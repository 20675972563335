import React, { FC } from 'react';
import styles from '../ConfirmationModal/ConfirmationModal.module.scss';

interface IOuterProps {
  onClose: () => void;
}

const WarningModal: FC<IOuterProps> = ({
  onClose,
}) => {
  return (
    <div className={styles.modal}>
      <div className={styles.content}>
        The percentage of dollars built should be between 99.5% and 100.5%.
      </div>
      <div className={styles.actions}>
        <button type="button" className={`${styles.button} ${styles.no}`} onClick={onClose}>Close</button>
      </div>
    </div>
  )
}

export default WarningModal;