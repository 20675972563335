import React from 'react';

import styles from './Table.module.scss';
import { isReady } from '../../../utils/valueState';
import { GroupType } from '../../../types/IReport';
import { formatNumber } from '../../../utils/formatNumber';

interface IProps {
  diff: { label: string, value: string | number }[];
  type: GroupType;
};

export const DifferentRow: React.FC<IProps> = ({ diff, type }) => {
  const valueColor = (value: number) => value >= 0 ? 'positiveDiff' : 'negativeDiff';
  const needToFormatCurrency = (label: string) => ['Dollars Built', 'Dollars Booked'].find(el => label.includes(el));

  const renderValue = () => {
    if(isReady(diff)) {
      let minColumns = 0;
      switch (type) {
        case GroupType.day:
          minColumns = 7;
          break;
        case GroupType.month:
          minColumns = 12;
          break;
        case GroupType.week:
          minColumns = 4;
          break;
      }
      if (diff.length < minColumns) {
        return diff.concat(new Array(minColumns - diff.length).fill(null)).map((item) => {
          if(typeof item.value === 'number') {
            return (
              <p className={`${styles.column} ${styles[valueColor(item.value)]}`}>{item.value}</p>
            );
          };

          return <p className={`${styles.column}`}></p>
        });
      }
      
      return diff.map((item, idx) => {
        if(typeof item.value === 'number') {
          if(needToFormatCurrency(diff[0].label)) {
            return (
              <p key={`goTrackerDiff_${item}_${idx}`} className={`${styles.column} ${styles[valueColor(item.value)]}`}>{formatNumber(item.value, 0, '.', ',')}</p>
            );
          }
          return (
            <p key={`goTrackerDiff_${item}_${idx}`}  className={`${styles.column} ${styles[valueColor(item.value)]}`}>{item.value.toFixed(0)}</p>
          );
        };

        return (
          <p key={`goTrackerDiff_${item}_${idx}`}  className={`${styles.column} ${styles[valueColor(0)]}`}></p>
        );
      });
    }
  }

  return (
    <div className={`${styles.row} ${styles.needs} ${styles.value}`}>
      <p className={styles.column}>
        <span className={styles.blackText}>Difference</span>
      </p>
      {renderValue()}
    </div>
  );
};
