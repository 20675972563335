export const DAY_RATE_NAME = 'dayRate';
export const AMOUNT_TIME_NAME = 'amountTime';
export const MET_GOALS_NAME = 'metGoals';
export const FEELING_EXPLAIN = 'feelingExplain';

export const DAY_RATE = [
  {
    value: '1',
    name: 'Overwhelmed',
    color: '#FF403F',
  },
  {
    value: '2',
    name: 'Struggling',
    color: '#F56741',
  },
  {
    value: '3',
    name: 'Disappointing',
    color: '#EE8643',
  },
  {
    value: '4',
    name: 'Wish it would be better',
    color: '#E6A544',
  },
  {
    value: '5',
    name: 'Not ok day',
    color: '#DFC446',
  },
  {
    value: '6',
    name: 'Ok Day',
    color: '#CFD54A',
  },
  {
    value: '7',
    name: 'Good Day',
    color: '#B0D452',
  },
  {
    value: '8',
    name: 'Great Day',
    color: '#94D35A',
  },
  {
    value: '9',
    name: 'Excellent Day',
    color: '#77D261',
  },
  {
    value: '10',
    name: 'Next to Perfect',
    color: '#4FD06B',
  },
];

export const AMOUNT_TIME = [
  {
    value: '1',
    name: 'I did not work',
    color: '#FF403F',
  },
  {
    value: '2',
    name: '1 to 2 hours',
    color: '#F56741',
  },
  {
    value: '3',
    name: '2 to 3 hours',
    color: '#EE8643',
  },
  {
    value: '4',
    name: '3 to 4 hours',
    color: '#E6A544',
  },
  {
    value: '5',
    name: '4 to 5 hours',
    color: '#DFC446',
  },
  {
    value: '6',
    name: '5 to 6 hours',
    color: '#CFD54A',
  },
  {
    value: '7',
    name: '6 to 7 hours',
    color: '#B0D452',
  },
  {
    value: '8',
    name: '7 to 8 hours',
    color: '#94D35A',
  },
  {
    value: '9',
    name: '8 to 9 hours',
    color: '#77D261',
  },
  {
    value: '10',
    name: 'Over 9 hours',
    color: '#4FD06B',
  },
];

export const MET_GOALS = {
  NO: {
    name: 'no',
    value: '0',
    color: 'rgba(255, 64, 63, 0.2)',
  },
  YES: {
    name: 'yes',
    value: '1',
    color: '#4FD06B',
  },
};

export const RATINGS_VALUES = [
  {
    value: 1,
    name: 'Bad',
    color: '#d61010',
  },
  {
    value: 2,
    name: 'Below Average',
    color: '#e6e602',
  },
  {
    value: 3,
    name: 'Average',
    color: '#57ca20',
  },
  {
    value: 4,
    name: 'Good',
    color: '#3594E6',
  },
  {
    value: 5,
    name: 'Excellent',
    color: '#0356A7',
  },
];

export const DOORS_KNOCKED = [
  {
    value: 1,
    min: 0,
    max: 24,
  },
  {
    value: 2,
    min: 25,
    max: 32,
  },
  {
    value: 3,
    min: 33,
    max: 44,
  },
  {
    value: 4,
    min: 45,
    max: 62,
  },
  {
    value: 5,
    min: 63,
    max: null,
  },
];

export const HOURS_KNOCKING = [
  {
    value: 1,
    min: 0,
    max: 0.5,
  },
  {
    value: 2,
    min: 0.6,
    max: 1.25,
  },
  {
    value: 3,
    min: 1.26,
    max: 1.75,
  },
  {
    value: 4,
    min: 1.76,
    max: 2.5,
  },
  {
    value: 5,
    min: 2.6,
    max: null,
  },
];

export const EVALUATIONS = [
  {
    value: 1,
    min: 0,
    max: 4,
  },
  {
    value: 2,
    min: 5,
    max: 8,
  },
  {
    value: 3,
    min: 9,
    max: 13,
  },
  {
    value: 4,
    min: 14,
    max: 21,
  },
  {
    value: 5,
    min: 22,
    max: null,
  },
];

export const INSURANCE_LOOKS = [
  {
    value: 1,
    min: 0,
    max: 1,
  },
  {
    value: 2,
    min: 2,
    max: 3,
  },
  {
    value: 3,
    min: 4,
    max: 5,
  },
  {
    value: 4,
    min: 6,
    max: 10,
  },
  {
    value: 5,
    min: 11,
    max: null,
  },
];

export const ADJUSTER_MEETINGS = [
  {
    value: 1,
    min: 0,
    max: 0,
  },
  {
    value: 2,
    min: 1,
    max: 2,
  },
  {
    value: 3,
    min: 3,
    max: 4,
  },
  {
    value: 4,
    min: 5,
    max: 8,
  },
  {
    value: 5,
    min: 9,
    max: null,
  },
];

export const APPROVALS = [
  {
    value: 1,
    min: 0,
    max: 0,
  },
  {
    value: 2,
    min: 1,
    max: 1,
  },
  {
    value: 3,
    min: 2,
    max: 4,
  },
  {
    value: 4,
    min: 5,
    max: 6,
  },
  {
    value: 5,
    min: 7,
    max: null,
  },
];

export const TOTAL_COLOR_MEETINGS = [
  {
    value: 1,
    min: 0,
    max: 0,
  },
  {
    value: 2,
    min: 1,
    max: 1,
  },
  {
    value: 3,
    min: 2,
    max: 3,
  },
  {
    value: 4,
    min: 4,
    max: 6,
  },
  {
    value: 5,
    min: 7,
    max: null,
  },
];

export const PROJECTS_TURNED_IN = [
  {
    value: 1,
    min: 0,
    max: 0,
  },
  {
    value: 2,
    min: 1,
    max: 1,
  },
  {
    value: 3,
    min: 2,
    max: 3,
  },
  {
    value: 4,
    min: 4,
    max: 7,
  },
  {
    value: 5,
    min: 8,
    max: null,
  },
];

export const RATINGS_STACK = {
  DOORS_KNOCKED,
  HOURS_KNOCKING,
  EVALUATIONS,
  INSURANCE_LOOKS,
  ADJUSTER_MEETINGS,
  APPROVALS,
  TOTAL_COLOR_MEETINGS,
  PROJECTS_TURNED_IN,
};
