import { call, takeEvery, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { API_ENDPOINTS } from '../constants/APi';

import { IProfile, IProfileUpdatePayload, IProfilePasswordUpdatePayload } from '../types/IProfile';

import { authActions } from './auth.saga';
import { notificationsActions } from './notifications.saga';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';
import { http } from '../utils/requests';

const resource = 'PROFILE';
const actions = ['get', 'profile', 'password', 'image', 'deleteImage'];
type reducerType = IProfile;

interface IProfileUpdateAction {
  payload: IProfileUpdatePayload;
}
interface IProfilePasswordUpdateAction {
  payload: IProfilePasswordUpdatePayload;
}

export const profileTypes = createActionTypes(resource, actions);
export const profileActions = createActions<reducerType>(resource, actions);
export const profileReducer = createReducers<reducerType>(resource, actions);

function* getProfile() {
  try {
    const { user, company } = yield call(http.get, API_ENDPOINTS.PROFILE);
    yield put(profileActions.get.success({
      ...user,
      company,
    }));
    yield put(notificationsActions.get.load());
  } catch (err) {
    yield put(profileActions.get.fail(err));
    yield put(authActions.logOut.update());
  }
}

function* updateProfile({ payload }: IProfileUpdateAction) {
  try {
    const { user, company } = yield call(http.patch, API_ENDPOINTS.PROFILE, payload);
    yield put(profileActions.get.success({
      ...user,
      company,
    }));

    toast.success('Profile has been updated successfully');
  } catch (err) {
    yield put(profileActions.profile.fail(err));

    toast.error('Profile update failed');
  }
}

function* updateImage({payload}: {[payload: string]: File}) {
  try {
    const formData = new FormData();
    formData.append('img', payload);
    const response = yield call(http.patch, API_ENDPOINTS.PROFILE_IMG, formData, { isAuth: true, isFormData: true });

    yield put(profileActions.image.success({
      profileImg: response,
    }));

    toast.success('Logo has been updated successfully');
  } catch (err) {
    yield put(profileActions.image.fail(err));

    toast.error('Logo update failed');
  }
}

function* deleteImage() {
  try {
    yield call(http.delete, API_ENDPOINTS.PROFILE_IMG);
    yield put(profileActions.deleteImage.success({
      profileImg: null,
    }));

    toast.success('Logo has been removed successfully');
  } catch (err) {
    yield put(profileActions.deleteImage.fail(err));

    toast.error('Logo remove failed');
  }
}

function* updatePassword({ payload }: IProfilePasswordUpdateAction) {
  try {
    yield call(http.patch, API_ENDPOINTS.RESET_PASSWORD, payload);

    toast.success('Password has been updated successfully');
  } catch (err) {
    yield put(profileActions.password.fail(err));

    toast.error('Old password is incorrect');
  }
}

export function* profileSaga() {
  yield takeEvery(profileTypes.get.load, getProfile);
  yield takeEvery(profileTypes.profile.update, updateProfile);
  yield takeEvery(profileTypes.password.update, updatePassword);
  yield takeEvery(profileTypes.image.update, updateImage);
  yield takeEvery(profileTypes.deleteImage.update, deleteImage);
}
