import React from 'react';

import { IReport, IReportCalculation, IReportData } from '../../types/IReport';

import {
  getReportsTotals,
  getReportsCalculations
} from '../../utils/report.util';

import styles from './CalculationsTable.module.scss';

interface IProps {
  reports: IReport[] | IReportData;
}

const renderCalculations = (calculations: IReportCalculation[]) =>
  calculations.map(calculation => (
    <div key={calculation.name} className={styles.item}>
      <p className={styles.itemName}>{calculation.name}</p>
      <p className={styles.itemValue}>{calculation.value}</p>
      <p className={styles.itemUnit}>{calculation.unit}</p>
    </div>
  ));

export const CalculationsTable: React.FC<IProps> = ({ reports }) => {
  const totals: IReportData =
    reports instanceof Array ? getReportsTotals(reports) : reports;

  return (
    <div className={styles.wrapper}>
      {renderCalculations(getReportsCalculations(totals))}
    </div>
  );
};
