import { takeEvery, put } from 'redux-saga/effects';

import { IPagination } from '../types/IPagination';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';

import { companyProjectionGroupedReportsActions } from './companyProjectionGroupedByDateReports.saga';


const resource = 'COMPANY_PROJECTION_PAGINATION';
const actions = ['set', 'activePage', 'reset'];
type reducerType = IPagination;

export const companyProjectionPaginationTypes = createActionTypes(resource, actions);
export const companyProjectionPaginationActions = createActions<reducerType>(resource, actions);
export const companyProjectionPaginationReducer = createReducers<reducerType>(resource, actions);

function* setPagination({payload}: {[payload: string]: IPagination}) {
  yield put(companyProjectionPaginationActions.set.success(payload));
}

function* setActivePage({payload}: {[payload: string]: number}) {
  yield put(companyProjectionPaginationActions.activePage.success({
    activePage: payload,
  }));
  yield put(companyProjectionGroupedReportsActions.get.load());
}

function* reset() {
  yield put(companyProjectionPaginationActions.reset.success({
    activePage: 1,
    pages: 1,
    prevPage: null,
    nextPage: null,
  }));
}

export function* companyProjectionPaginationSaga() {
  yield takeEvery(companyProjectionPaginationTypes.set.update, setPagination);
  yield takeEvery(companyProjectionPaginationTypes.activePage.update, setActivePage);
  yield takeEvery(companyProjectionPaginationTypes.reset.update, reset);
}
