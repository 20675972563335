import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

import { IRating } from '../../../types/IRating';

import { DAY_RATE, AMOUNT_TIME, RATINGS_VALUES } from '../../../constants/rating';

import styles from './RatingAdmin.module.scss';

interface IProps {
  rating: IRating;
  index: number;
  reportContent: {[name: string]: string};
}

export const RatingTableRow: React.FC<IProps> = ({rating, index, reportContent}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const dayRate = DAY_RATE.find( ({value}) => value === rating.rateDay.toString());
  const amountTime = AMOUNT_TIME.find( ({value}) => value === rating.amountTime.toString());
  const overallRating = RATINGS_VALUES.find( ({value}) => value === rating.ratingOverall);

  const expandToggle = () => setExpanded(!expanded);

  return (
    <div className={`${styles.row} ${expanded ? styles.active : ''}`}>
      <div className={styles.column}>
        {index + 1}
      </div>
      <div className={styles.column}>
        {rating.user?.username || rating.user?.email}
      </div>
      <div className={styles.column}>
        {rating.user?.firstName && rating.user?.lastName
          ? rating.user?.firstName + ' ' + rating.user?.lastName : ''}
      </div>
      <div className={`${styles.column} ${styles.biggest}`} style={{color: dayRate?.color}}>
        {dayRate?.value} - {dayRate?.name}
      </div>
      <div className={`${styles.column} ${styles.biggest}`} style={{color: amountTime?.color}}>
        {amountTime?.value} - {amountTime?.name}
      </div>
      <div className={styles.column}>
        {rating.metGoals}/{rating.notMetGoals}/{rating.metGoals + rating.notMetGoals}
      </div>
      <div className={`${styles.column} ${styles.last}`}>
        <div className={styles.rate} data-tip={overallRating?.name} style={{backgroundColor: overallRating?.color}}>
          <span className={`${styles.rateStar} ${rating.ratingOverall >= 1 ? styles.active : ''}`} />
          <span className={`${styles.rateStar} ${rating.ratingOverall >= 2 ? styles.active : ''}`} />
          <span className={`${styles.rateStar} ${rating.ratingOverall >= 3 ? styles.active : ''}`} />
          <span className={`${styles.rateStar} ${rating.ratingOverall >= 4 ? styles.active : ''}`} />
          <span className={`${styles.rateStar} ${rating.ratingOverall >= 5 ? styles.active : ''}`} />
        </div>
        <button className={`${styles.expandBtn} ${expanded ? styles.active : ''}`} onClick={expandToggle} />
      </div>
      <div className={styles.hiddenRow}>
        <div className={styles.hiddenRowColumn}>
          <p className={styles.hiddenRowColumnName}>{reportContent.doorsKnocked}</p>
          <p className={styles.hiddenRowColumnVal}>{rating.doorsKnocked}</p>
        </div>
        <div className={styles.hiddenRowColumn}>
          <p className={styles.hiddenRowColumnName}>{reportContent.hoursKnocking}</p>
          <p className={styles.hiddenRowColumnVal}>{rating.hoursKnocking}</p>
        </div>
        <div className={styles.hiddenRowColumn}>
          <p className={styles.hiddenRowColumnName}>{reportContent.evaluations}</p>
          <p className={styles.hiddenRowColumnVal}>{rating.evaluations}</p>
        </div>
        <div className={styles.hiddenRowColumn}>
          <p className={styles.hiddenRowColumnName}>{reportContent.insuranceLooks}</p>
          <p className={styles.hiddenRowColumnVal}>{rating.insuranceLooks}</p>
        </div>
        <div className={styles.hiddenRowColumn}>
          <p className={styles.hiddenRowColumnName}>{reportContent.adjusterMeetings}</p>
          <p className={styles.hiddenRowColumnVal}>{rating.adjusterMeetings}</p>
        </div>
        <div className={styles.hiddenRowColumn}>
          <p className={styles.hiddenRowColumnName}>{reportContent.approvals}</p>
          <p className={styles.hiddenRowColumnVal}>{rating.approvals}</p>
        </div>
        <div className={styles.hiddenRowColumn}>
          <p className={styles.hiddenRowColumnName}>{reportContent.totalColorMeetings}</p>
          <p className={styles.hiddenRowColumnVal}>{rating.totalColorMeetings}</p>
        </div>
        <div className={styles.hiddenRowColumn}>
          <p className={styles.hiddenRowColumnName}>{reportContent.projectsTurnedIn}</p>
          <p className={styles.hiddenRowColumnVal}>{rating.projectsTurnedIn}</p>
        </div>
      </div>
      {overallRating && (
        <ReactTooltip
          type="light"
          className={styles.tooltip}
          effect="solid"
        />
      )}
    </div>
  )
};
