import React, { Component } from 'react';
import { Router, Switch } from 'react-router';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Dispatch } from 'redux';

import { ROUTES } from './constants/router';
import { toastifyConfig } from './constants/toastify.config';

import { isReady } from './utils/valueState';
import { initializeFirebase, isTokenExist, askNotificationsPermissions, setToken } from './utils/push-notifications';

import Login from './components/Login/Login';
import ForgotPass from './components/ForgotPass/ForgotPass';
import ForgotPassReset from './components/ForgotPass/ForgotPassReset';
import Dashboard from './components/Dashboard/Dashboard';
import Header from './components/Header/Header';
import RouteInterceptor from './containers/RouteInterceptor/RouteInterceptor';
import Sidebar from './components/Sidebar/Sidebar';
import Popup from './containers/Popup/Popup';
import Settings from './components/Settings/Settings';
import Analytics from './components/Analytics/Analytics';
import GoalSettings from './components/GoalSettings/GoalSettings';
import GoalTracker from './components/GoalTracker/GoalTracker';
import CompanyProjection from './components/CompanyProjection/CompanyProjection';
import { ChartTooltip } from './components/ChartTooltip/ChartTooltip';
import AdminPanel from './components/AdminPanel/AdminPanel';
import CompanyEditor from './components/CompanyEditor/CompanyEditor';
import UserEditor from './components/UserEditor/UserEditor';
import Rating from './components/Rating/Rating';

import { IStore } from './types/IStore';
import { IReport } from './types/IReport';
import { IProfile } from './types/IProfile';
import { IChartTooltipParams } from './types/IChartTooltip';

import { history } from './store/store';

import { activeReportActions } from './sagas/activeReport.saga';
import { authActions } from './sagas/auth.saga';

import 'react-toastify/dist/ReactToastify.css';
import 'reactjs-popup/dist/index.css';
import styles from './App.module.scss';

interface IPropsFromStore extends Partial<IStore> {
  auth: string | null;
  activeReport: IReport | boolean;
  profile: IProfile;
  tooltip: IChartTooltipParams | null;
}

interface IDispatchProps {
  openReportForm: (payload: boolean) => void;
  saveFirebaseToken: (payload: string) => void;
}

const mapStateToProps = ({
  auth,
  activeReport,
  profile,
  tooltip,
}: IStore): IPropsFromStore => ({
  auth,
  activeReport,
  profile,
  tooltip,
});

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  openReportForm: (payload: boolean) =>
    dispatch(activeReportActions.openForm.update(payload)),
  saveFirebaseToken: payload => dispatch(authActions.firebaseToken.update(payload)),
});

type IProps = IPropsFromStore & IDispatchProps;

class App extends Component<IProps> {
  public componentDidMount(): void {
    initializeFirebase();
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if ( isReady(this.props.profile) && isReady(prevProps.profile) !== isReady(this.props.profile) ) {
      if ( !isTokenExist() ) {
        askNotificationsPermissions().then( token => {
          if ( token ) {
            setToken(token);
            this.props.saveFirebaseToken(token);
          }
        });
      }
      // set colors and theme here
      if (this.props.profile.company?.primaryColor) {
        document.documentElement.style.setProperty('--primaryColor', this.props.profile.company?.primaryColor);
      }
      if (this.props.profile.company?.regularTextColor) {
        document.documentElement.style.setProperty('--regularTextColor', this.props.profile.company?.regularTextColor);
      }
    }
  }

  private openReportPopup = () => this.props.openReportForm(true);

  public render() {
    const { activeReport, tooltip } = this.props;
    const auth = isReady(this.props.auth);

    return (
      <Router history={history}>
        {tooltip && <ChartTooltip params={tooltip} />}
        {auth && <Header />}
        {auth && (
          <Sidebar onClickReport={this.openReportPopup} blurred={activeReport} />
        )}
        {auth && <Popup />}
        <div
          className={
            auth
              ? `${styles.appContent} ${activeReport ? styles.blurred : ''}`
              : ''
          }
        >
          <Switch>
            <RouteInterceptor {...ROUTES.Root}>
              <Dashboard />
            </RouteInterceptor>
            <RouteInterceptor {...ROUTES.Login}>
              <Login />
            </RouteInterceptor>
            <RouteInterceptor {...ROUTES.ForgotPassStepEmail}>
              <ForgotPass />
            </RouteInterceptor>
            <RouteInterceptor {...ROUTES.ForgotPassStepReset}>
              <ForgotPassReset />
            </RouteInterceptor>
            <RouteInterceptor {...ROUTES.Analytics}>
              <Analytics />
            </RouteInterceptor>
            <RouteInterceptor {...ROUTES.GoalTracker}>
              <GoalTracker />
            </RouteInterceptor>
            <RouteInterceptor {...ROUTES.CompanyProjection}>
              <CompanyProjection />
            </RouteInterceptor>
            <RouteInterceptor {...ROUTES.GoalSettings}>
              <GoalSettings />
            </RouteInterceptor>
            <RouteInterceptor {...ROUTES.Settings}>
              <Settings />
            </RouteInterceptor>
            <RouteInterceptor {...ROUTES.Admin}>
              <AdminPanel />
            </RouteInterceptor>
            <RouteInterceptor {...ROUTES.CompanyEditor}>
              <CompanyEditor />
            </RouteInterceptor>
            <RouteInterceptor {...ROUTES.UserEditor}>
              <UserEditor />
            </RouteInterceptor>
            <RouteInterceptor {...ROUTES.Rating}>
              <Rating />
            </RouteInterceptor>
          </Switch>
        </div>
        <ToastContainer {...toastifyConfig} />
      </Router>
    );
  }
}

export default connect<IPropsFromStore, IDispatchProps, any, any>(
  mapStateToProps,
  dispatchProps
)(App);
