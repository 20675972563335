export const REPORT_CONTENT = [
  {
    type: 'doorsKnocked',
    name: 'Doors Knocked',
  },
  {
    type: 'hoursKnocking',
    name: 'Hours Knocking',
  },
  {
    type: 'leadsActual',
    name: 'Leads Actual',
  },
  {
    type: 'evaluations',
    name: 'Evaluations',
  },
  {
    type: 'insuranceLooks',
    name: 'Insurance Looks',
  },
  {
    type: 'conversations',
    name: 'Conversations',
  },
  {
    type: 'adjusterMeetings',
    name: 'Adjuster Meetings',
  },
  {
    type: 'approvals',
    name: 'Approvals',
  },
  {
    type: 'totalColorMeetings',
    name: 'Total Color Meetings',
  },
  {
    type: 'projectsTurnedIn',
    name: 'Projects Turned in',
  },
  {
    type: 'dollarsBooked',
    name: 'Dollars Booked',
  },
  {
    type: 'dollarsClosed',
    name: 'Dollars Closed',
  },
];

export const REPORT_CONTENT_ALIASES = {
  doorsKnocked: 'DOORS_KNOCKED',
  hoursKnocking: 'HOURS_KNOCKING',
  leadsActual: 'LEADS_ACTUAL',
  evaluations: 'EVALUATIONS',
  insuranceLooks: 'INSURANCE_LOOKS',
  adjusterMeetings: 'ADJUSTER_MEETINGS',
  approvals: 'APPROVALS',
  totalColorMeetings: 'TOTAL_COLOR_MEETINGS',
  projectsTurnedIn: 'PROJECTS_TURNED_IN',
  dollarsBooked: 'DOLLARS_BOOKED',
  dollarsClosed: 'DOLLARS_CLOSED',
};

export const REPORT_CONTENT_COLORS = {
  doorsKnocked: '#FF604C',
  hoursKnocking: '#FF884C',
  leadsActual: '#9c00f6',
  evaluations: '#FFEB56',
  insuranceLooks: '#90CD4C',
  adjusterMeetings: '#4C58B8',
  approvals: '#4C58B8',
  totalColorMeetings: '#8C4CCA',
  projectsTurnedIn: '#EF4C96',
  dollarsBooked: '#450121',
  dollarsClosed: '#0b4f50',
};

export const GROUP_ID_KEYS = {
  DAY: '$dayOfYear',
  WEEK: '$isoWeek',
  MONTH: '$month',
};

export const REPORT_EXPORT_TYPES = {
  CSV: {
    name: 'csv',
    value: 0
  },
  PDF: {
    name: 'pdf',
    value: 1,
  },
};
