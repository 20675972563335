import { call, takeEvery, put } from 'redux-saga/effects';

import { API_ENDPOINTS } from '../constants/APi';

import { IReportData, IAggregatedData } from '../types/IReport';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';
import { http } from '../utils/requests';
import { getStore } from '../store/store';
import { assignFiltersParams } from '../utils/filters.util';

const resource = 'AGGREGATED_DATA';
const actions = ['get'];
type reducerType = (IReportData & IAggregatedData)[];

export const aggregatedDataTypes = createActionTypes(resource, actions);
export const aggregatedDataActions = createActions<reducerType>(resource, actions);
export const aggregatedDataReducer = createReducers<reducerType>(resource, actions);

function* getAggregatedData({ payload }) {
  try {
    const { filters } = getStore();

    const query = filters ? payload ? payload : assignFiltersParams(filters, '?') : '';

    const { reports, groupIDKey } = yield call(http.get, API_ENDPOINTS.AGGREGATED_DATA, undefined, undefined, query);

    yield put(aggregatedDataActions.get.success(reports.map( report => ({...report, groupIDKey}))));
  } catch (err) {
    yield put(aggregatedDataActions.get.fail(err));
  }
}

export function* aggregatedDataSaga() {
  yield takeEvery(aggregatedDataTypes.get.load, getAggregatedData);
}
