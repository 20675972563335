import { call, takeEvery, put } from 'redux-saga/effects';

import { API_ENDPOINTS } from '../constants/APi';

import { IUser, IUserByCompanyPayload, IUserCreatePayload } from '../types/IUser';

import { getStore, history } from '../store/store';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';
import { http } from '../utils/requests';
import {toast} from 'react-toastify';

const resource = 'USERS';
const actions = ['get', 'create', 'update', 'delete', 'changeActive', 'getByCompany'];
type reducerType = IUser;

export const usersTypes = createActionTypes(resource, actions);
export const usersActions = createActions<reducerType>(resource, actions);
export const usersReducer = createReducers<reducerType>(resource, actions);

function* getUsers() {
  try {
    const response = yield call(http.get, API_ENDPOINTS.USERS);
    yield put(usersActions.get.success(response));
  } catch (err) {
    yield put(usersActions.get.fail(err));
  }
}

function* getUsersByCompany({ payload }) {
  try {
    const response = yield call(http.get, `${API_ENDPOINTS.USERSBYCOMPANY}/${payload.company}`);
    yield put(usersActions.getByCompany.success(response));
  } catch (err) {
    yield put(usersActions.getByCompany.fail(err));
  }
}

function* createUser({payload}: IUserCreatePayload) {
  try {
    const user = yield call(http.post, API_ENDPOINTS.USERS, payload);
    const users = getStore().users;


    users.push(user);

    yield put(usersActions.create.success(users));
    history.goBack();
  } catch (err) {
    yield put(usersActions.create.fail(err));
  }
}

function* updateUser({payload}: IUserCreatePayload) {
  try {
    yield call(http.patch, API_ENDPOINTS.USERS, payload);
    const users = getStore().users;
    let updatedUser = users.find( ({_id}) => _id === payload._id);

    if ( updatedUser ) {
      updatedUser.email = payload.email;
      updatedUser.firstName = payload.firstName;
      updatedUser.lastName = payload.lastName;
      updatedUser.username = payload.username;
      updatedUser.phone = payload.phone;
      updatedUser.company = payload.company;
      updatedUser.accessLevel = payload.accessLevel;
      updatedUser.assignedUsers = payload.assignedUsers;
    }

    yield put(usersActions.create.success(users));
    history.goBack();
  } catch (err) {
    yield put(usersActions.create.fail(err));
  }
}

function* deleteUser({payload}: {[payload: string]: string}) {
  try {
    const { users, profile } = getStore();

    if ( profile._id === payload ) {
      return toast.error('Can not be removed own account');
    } else {
      yield call(http.delete, API_ENDPOINTS.USERS, {_id: payload});

      const userFiltered = users.filter( ({ _id }) => _id !== payload);

      yield put(usersActions.delete.success(userFiltered));

      history.goBack();
    }
  } catch (err) {
    yield put(usersActions.delete.fail(err));
  }
}

function* changeActiveUser({payload}: {[payload: string]: string}) {
  try {
    const { users, profile } = getStore();

    if ( profile._id === payload ) {
      return toast.error('Can not change activation of own account');
    } else {
      const user = users.find(({ _id }) => _id === payload) as IUser;
      user.isActive = !user.isActive;

      yield call(http.patch, `${API_ENDPOINTS.USERS}/${payload}`, { isActive: user.isActive });

      yield put(usersActions.changeActive.success(users));
    }
  } catch (err) {
    yield put(usersActions.changeActive.fail(err));
  }
}

export function* usersSaga() {
  yield takeEvery(usersTypes.get.load, getUsers);
  yield takeEvery(usersTypes.getByCompany.update, getUsersByCompany);
  yield takeEvery(usersTypes.create.update, createUser);
  yield takeEvery(usersTypes.delete.update, deleteUser);
  yield takeEvery(usersTypes.update.update, updateUser);
  yield takeEvery(usersTypes.changeActive.update, changeActiveUser);
}
