import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import Select from 'react-select';
import { Dispatch } from 'redux';
import { toast } from 'react-toastify';

import { COMPANY_EDITOR_VALUES } from '../../constants/formsInitialValues';
import { COMPANY_EDITOR_SCHEMA } from '../../constants/formsYupSchemas';
import { THEME_DEFAULT_OPTION, THEME_OPTIONS } from '../../constants/select';

import { ROUTES } from '../../constants/router';

import { isReady } from '../../utils/valueState';

import { Dropzone } from '../Dropzone/Dropzone';
import { CompanyConfirmation } from './CompanyConfirmation/CompanyConfirmation';
import { ColorPicker } from '../ColorPicker/ColorPicker';

import {selectedCompanyActions} from '../../sagas/selectedCompany.saga';
import { usersActions } from '../../sagas/users.saga';
import { companiesActions } from '../../sagas/companies.saga';
import { history } from '../../store/store';

import { ICompany, ICompanyCreate, ICompanyLogoUpdate, ICompanyUpdate, ICompanyDelete } from '../../types/ICompany';
import { IOption } from '../../types/ISelect';
import { IProfile } from '../../types/IProfile';
import { IUser, IUserByCompanyPayload } from '../../types/IUser';

import styles from './CompanyEditor.module.scss';
import {IStore} from '../../types/IStore';
import UsersTab from '../AdminPanel/Tabs/UsersTab/UsersTab';

interface IDispatchProps {
  createCompany: (payload: ICompanyCreate) => void;
  updateCompany: (payload: ICompanyUpdate) => void;
  deleteCompany: (payload: ICompanyDelete) => void;
  updateCompanyLogo: (payload: ICompanyLogoUpdate) => void;
  deleteCompanyLogo: (payload: string) => void;
  clearSelectedCompany: () => void;
  getUsersByCompany: (payload: IUserByCompanyPayload) => void;
}

interface IPropsFromStore {
  companies: ICompany[];
  selectedCompany: ICompany | null;
  profile: IProfile;
  users: IUser[];
}

interface IState {
  primaryColor?: string;
  theme: IOption;
  regularTextColor?: string;
  secondaryTextColor?: string;
}

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  createCompany: (payload: ICompanyCreate) => dispatch(companiesActions.create.update(payload)),
  updateCompany: (payload: ICompanyUpdate) => dispatch(companiesActions.update.update(payload)),
  deleteCompany: (payload: ICompanyDelete) => dispatch(companiesActions.delete.update(payload)),
  updateCompanyLogo: (payload: ICompanyLogoUpdate) => dispatch(companiesActions.logo.update(payload)),
  deleteCompanyLogo: (payload: string) => dispatch(companiesActions.deleteLogo.update(payload)),
  clearSelectedCompany: () => dispatch(selectedCompanyActions.clear.update()),
  getUsersByCompany: (payload: IUserByCompanyPayload) => dispatch(usersActions.getByCompany.update(payload)),
});

const mapStateToProps = ({ companies, selectedCompany, profile, users }: IStore): IPropsFromStore => ({
  companies,
  selectedCompany,
  profile,
  users,
});

type IProps = IPropsFromStore & IDispatchProps;

class CompanyEditor extends Component<IProps> {
  private imgFile: File | null | undefined;

  public state: Readonly<IState> = {
    primaryColor: this.props.selectedCompany?.primaryColor,
    theme: this.props.selectedCompany?.theme 
      ? THEME_OPTIONS.find(option => option.value === this.props.selectedCompany?.theme) || THEME_DEFAULT_OPTION 
      : THEME_DEFAULT_OPTION,
    regularTextColor: this.props.selectedCompany?.regularTextColor,
    secondaryTextColor: this.props.selectedCompany?.secondaryTextColor,
  };

  public componentDidMount(): void {
    if ( isReady(this.props.selectedCompany) && this.props.selectedCompany?._id ) {
      this.props.getUsersByCompany({ company: this.props.selectedCompany?._id });
    }
  }

  // public componentWillUnmount(): void {
  //   this.props.clearSelectedCompany();
  // }

  private imgDropped = (imgFile: File | null) => (this.imgFile = imgFile);

  private onFormSubmit = ({companyName}, { setSubmitting }: FormikHelpers<any>) => {
    const { selectedCompany, profile } = this.props;

    if ( !selectedCompany ) {
      this.props.createCompany({
        name: companyName,
        file: this.imgFile instanceof File ? this.imgFile : null,
        primaryColor: this.state.primaryColor,
        theme: this.state.theme.value,
        regularTextColor: this.state.regularTextColor,
        secondaryTextColor: this.state.secondaryTextColor,
      });
    } else {
      this.props.updateCompany({
        name: companyName,
        _id: selectedCompany._id,
        primaryColor: this.state.primaryColor,
        theme: this.state.theme.value,
        regularTextColor: this.state.regularTextColor,
        secondaryTextColor: this.state.secondaryTextColor,
      });
      // change theme if the current user update his current company
      if (selectedCompany._id === profile.company?._id) {
        // set colors and theme
        if (this.state.primaryColor) {
          document.documentElement.style.setProperty('--primaryColor', this.state.primaryColor);
        }
        if (this.state.regularTextColor) {
          document.documentElement.style.setProperty('--regularTextColor', this.state.regularTextColor);
        }
      }
      if (this.imgFile instanceof File) {
        this.props.updateCompanyLogo({
          file: this.imgFile,
          _id: selectedCompany._id,
        });
      } else if (this.imgFile === null) {
        this.props.deleteCompanyLogo(selectedCompany._id);
      }
    }

    setSubmitting(false);
  };

  private onCancel = () => history.goBack();

  private deleteCompany = () => {
    this.props.deleteCompany({
      _id: this.props.selectedCompany?._id as string,
    });
    toast.dismiss();
  };

  private onDelete = () => toast(() => <CompanyConfirmation title={'Delete this company?'} deleteApproved={this.deleteCompany} />);

  private getInitialValues = () => {
    if ( this.props.selectedCompany ) {
      return {
        companyName: this.props.selectedCompany.name,
      }
    }
    return COMPANY_EDITOR_VALUES;
  };

  private handleChangeTheme = (option) => {
    this.setState({ theme: option });
  }

  private setColor = (color: string, field: string) => {
    this.setState({ [field]: color });
  }

  private goToUserEditor = () => history.push(ROUTES.UserEditor.path);
  
  public render() {
    const { selectedCompany, users } = this.props;
    return (
      <Fragment>
        <div className={styles.editor}>
        <p className={styles.title}>{selectedCompany ? `Edit Company "${selectedCompany.name}"` : 'Add Company'}</p>
        <div className={styles.formWrapper}>
          <Formik
            initialValues={this.getInitialValues()}
            validationSchema={COMPANY_EDITOR_SCHEMA}
            onSubmit={this.onFormSubmit}
          >
            {({ isSubmitting }) => (
              <Form className={`${styles.form} ${styles.columnsForm}`}>
                <div className={styles.formColumn}>
                  <label className={styles.formField}>
                    <span className={styles.formFieldField}>Company name</span>
                    <Field type="text" name="companyName" placeholder="Company name" className={styles.formFieldMaxWidth} />
                    <ErrorMessage
                      name="companyName"
                      component="p"
                      className={styles.formFieldError}
                    />
                  </label>
                  <div className={styles.img}>
                    <Dropzone
                      onImgDropped={this.imgDropped}
                      isImage={true}
                      profileImg={selectedCompany?.logo}
                      title={'Company logo'}
                      alignLeft={true}
                    />
                  </div>
                </div>
                <div className={styles.formColumn}>
                  <label className={styles.formField}>
                    <span className={styles.formFieldField}>Colors</span>
                  </label>
                  <label className={styles.colorField}>
                    <span className={styles.colorFieldLabel}>Primary Color</span>
                    <ColorPicker 
                      defaultColor={this.state.primaryColor} 
                      onSetColor={(color) => this.setColor(color, 'primaryColor')} 
                    />
                  </label>
                  <label className={styles.colorField}>
                    <span className={styles.colorFieldLabel}>Background Theme</span>
                    <Select
                      defaultValue={this.state.theme}
                      options={THEME_OPTIONS}
                      className="settings-selector color"
                      classNamePrefix="settings-selector"
                      isSearchable={false}
                      onChange={this.handleChangeTheme}
                    />
                  </label>
                  <label className={styles.colorField}>
                    <span className={styles.colorFieldLabel}>Regular Text Color</span>
                    <ColorPicker 
                      defaultColor={this.state.regularTextColor} 
                      onSetColor={(color) => this.setColor(color, 'regularTextColor')} 
                    />
                  </label>
                  <label className={styles.colorField}>
                    <span className={styles.colorFieldLabel}>Secondary Text Color</span>
                    <ColorPicker 
                      defaultColor={this.state.secondaryTextColor} 
                      onSetColor={(color) => this.setColor(color, 'secondaryTextColor')} 
                    />
                  </label>
                </div>
                <div className={styles.bottom}>
                  <button type="submit" className={styles.formBtn} disabled={isSubmitting}>{selectedCompany ? 'Update' : 'Save'}</button>
                  <button type="button" className={`${styles.formBtn} ${styles.cancel}`} onClick={this.onCancel}>Cancel</button>
                  {selectedCompany && (
                    <button type="button" className={`${styles.formBtn} ${styles.delete}`} onClick={this.onDelete}>Delete</button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {
        selectedCompany && (
          <div className={styles.editor}>
            <div className={styles.header}>
              <p className={styles.title}>{selectedCompany && `Users`}</p>
              <button onClick={this.goToUserEditor} className={styles.headerBtn}>Add User</button>
            </div>
            <UsersTab users={users} />
          </div>
        )
      }
      </Fragment>
    )
  }
}

export default connect<IPropsFromStore, IDispatchProps, any, any>(
  mapStateToProps,
  dispatchProps
)(CompanyEditor);
