import {IFilters} from '../types/IFilters';

export const assignFiltersParams = (filters: IFilters, innerParams: string = '', withoutUsers: boolean = false): string => {
  const { groupType, time, from, to, users, companies, excludeDeactivated, displayType, averageBy } = filters;

  if ( from ) {
    if(innerParams.length > 0) {
      innerParams += `&from=${from}`;
    } else {
      innerParams += `?from=${from}`;
    }
  }

  if ( time ) {
    if(innerParams.length > 0) {
      innerParams += `&time=${time}`;
    } else {
      innerParams += `?time=${time}`;
    }
  }

  if ( groupType ) {
    if(innerParams.length > 0) {
      innerParams += `&groupType=${groupType}`;
    } else {
      innerParams += `?groupType=${groupType}`;
    }
  }

  if ( to ) {
    if(innerParams.length > 0) {
      innerParams += `&to=${to}`;
    } else {
      innerParams += `?to=${to}`;
    }
  }
  if ( users?.length && !withoutUsers ) {
    if(innerParams.length > 0) {
      innerParams += `&users=${users.join(';')}`;
    } else {
      innerParams += `?users=${users.join(';')}`;
    }
  }

  if ( companies?.length && !withoutUsers ) {
    if(innerParams.length > 0) {
      innerParams += `&companies=${companies.join(';')}`;
    } else {
      innerParams += `?companies=${companies.join(';')}`;
    }
  }

  if ( excludeDeactivated ) {
    if(innerParams.length > 0) {
      innerParams += `&excludeDeactivated=${excludeDeactivated}`;
    } else {
      innerParams += `?excludeDeactivated=${excludeDeactivated}`;
    }
  }

  if ( displayType ) {
    if(innerParams.length > 0) {
      innerParams += `&displayType=${displayType}`;
    } else {
      innerParams += `?displayType=${displayType}`;
    }
  }

  if ( averageBy ) {
    if(innerParams.length > 0) {
      innerParams += `&averageBy=${averageBy}`;
    } else {
      innerParams += `?averageBy=${averageBy}`;
    }
  }

  return innerParams;
};
