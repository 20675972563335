import { call, takeEvery, put } from 'redux-saga/effects';

import { API_ENDPOINTS } from '../constants/APi';

import {IReport, IReportsGroupByDate, IReportsGroupByType} from '../types/IReport';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';
import { http } from '../utils/requests';
import { getStore } from '../store/store';
import { assignFiltersParams } from '../utils/filters.util';
import { isReady } from '../utils/valueState';

import { goalTrackerPaginationActions } from './goalTrackerPagination.saga';

const resource = 'GOAL_TRACKER_REPORTS_GROUP_BY_DATE';
const actions = ['get', 'update', 'delete'];
type reducerType = IReportsGroupByDate[];

export const goalTrackerGroupedReportsTypes = createActionTypes(resource, actions);
export const goalTrackerGroupedReportsActions = createActions<IReportsGroupByType>(resource, actions);
export const goalTrackerGroupedReportsReducer = createReducers<reducerType>(resource, actions);

function* getGoalTrackerGroupedReports() {
  try {
    const { goalTrackerPagination: pagination, goalTrackerFilters } = getStore();

    const query = goalTrackerFilters
      ? assignFiltersParams(goalTrackerFilters, `?periodGrouped=true&page=${pagination.activePage}`)
      : `?periodGrouped=true&page=${pagination.activePage}`;

    const { timeRange, type, reports, pages, prevPage, nextPage, creators } = yield call(http.get, API_ENDPOINTS.REPORTS_GROUPED, undefined, undefined, query);
    yield put(goalTrackerGroupedReportsActions.get.success({ type, reports, creators, timeRange }));
    console.log('type', type);
    yield put(goalTrackerPaginationActions.set.update({
      activePage: pagination.activePage,
      pages,
      prevPage,
      nextPage,
    }))
  } catch (err) {
    yield put(goalTrackerGroupedReportsActions.get.fail(err));
  }
}

function* updateReport({payload}: {payload: IReport}) {
  // const { goalTrackerGroupedReports } = getStore();

  // if ( isReady(goalTrackerGroupedReports) ) {
  //   const reportsGroup = goalTrackerGroupedReports.find(({reports}) => reports.find(({_id}) => _id === payload.reportID));

  //   if ( reportsGroup ) {
  //     const reportIndex = reportsGroup.reports.findIndex( ({_id}) => _id === payload.reportID);
  //     const report = {
  //       ...reportsGroup.reports[reportIndex],
  //       ...payload,
  //     };

  //     reportsGroup.reports.splice(reportIndex, 1, report);

  //     yield put(goalTrackerGroupedReportsActions.update.success(goalTrackerGroupedReports));
  //   }
  // }
}

function* deleteReport({payload}: {payload: string}) {
  // const { goalTrackerGroupedReports } = getStore();

  // if ( isReady(goalTrackerGroupedReports) ) {
  //   const reportsGroupIndex = goalTrackerGroupedReports.findIndex(({reports}) => reports.find(({_id}) => _id === payload));

  //   if ( reportsGroupIndex >= 0 ) {
  //     const reportIndex = goalTrackerGroupedReports[reportsGroupIndex].reports.findIndex( ({_id}) => _id === payload);

  //     goalTrackerGroupedReports[reportsGroupIndex].reports.splice(reportIndex, 1);

  //     if ( goalTrackerGroupedReports[reportsGroupIndex].reports.length === 0 ) {
  //       goalTrackerGroupedReports.splice(reportsGroupIndex, 1);
  //     }

  //     yield put(goalTrackerGroupedReportsActions.delete.success(goalTrackerGroupedReports));
  //   }
  // }
}

export function* goalTrackerGroupedReportsSaga() {
  yield takeEvery(goalTrackerGroupedReportsTypes.get.load, getGoalTrackerGroupedReports);
  yield takeEvery(goalTrackerGroupedReportsTypes.update.update, updateReport);
  yield takeEvery(goalTrackerGroupedReportsTypes.delete.update, deleteReport);
}
