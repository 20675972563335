import React, { useState } from "react";

import { EReportType, GroupType, GroupedReportItem, IReportData, IReportsGroupByType } from "../../../types/IReport";
import { IUser } from "../../../types/IUser";
import { IProfile } from "../../../types/IProfile";

import styles from "./Table.module.scss";
import { isReady } from "../../../utils/valueState";
import { formatNumber } from "../../../utils/formatNumber";
import { getNeedsValue } from "../../../utils/goal.util";
import { IGoal, IGoalSetting } from "../../../types/IGoal";
import { UserNeedsRow } from "./UserNeedsRow";

interface IProps {
  allGoals: IGoal[];
  goalSetting: IGoalSetting;
  report: GroupedReportItem[];
  users: IUser[];
  creators: { company: string, _id: string }[];
  profile: IProfile;
  label: string;
  type: GroupType;
  groupedReports: IReportsGroupByType;
  goalType: EReportType;
  totals: IReportData;
  totalNeeds: number;
}

export const AdminNeedsRow: React.FC<IProps> = ({
  type,
  report,
  creators,
  label,
  allGoals,
  goalType,
  groupedReports,
  totals,
  totalNeeds
}) => {
  const [expand, setExpand] = useState<boolean>(false);
  const needToFormatCurrency = (label: string) => !!['Dollars Built', 'Dollars Booked'].find(el => label.includes(el));

  const onClickExpand = () => setExpand(!expand);

  const renderTotalOfActual = () => {
    let minColumns = 0;
    switch (type) {
      case GroupType.day:
        minColumns = 7;
        break;
      case GroupType.month:
        minColumns = 12;
        break;
      case GroupType.week:
        minColumns = 4;
        break;
    }
    let reportData: GroupedReportItem[] = report;
    if (report.length < minColumns) {
      reportData = report.concat(new Array(minColumns - report.length).fill(0));
    }

    return reportData.map((data, idx) => {
      if(data) {
        let needs = data.needs || 0;
        if(needToFormatCurrency(label)) {
          return <p className={styles.column}>{`${formatNumber(needs, 0, '.', ',')}`}</p>
        }
        return <p className={styles.column}>{needs.toFixed(0) || '0'}</p>
      }
      return <p className={styles.column} key={`total_${label}_company_${idx}`} />;
    });
  };

  return (
    <div className={`${styles.row} ${styles.needs} ${styles.rowExpand} ${styles.value}`}>
      <p className={styles.column} onClick={onClickExpand}>
        <span className={styles.label} title={label}>
          {label}
        </span>{" "}
      </p>
      {isReady(report) && renderTotalOfActual()}
      <p className={styles.column}>
        {needToFormatCurrency(label) ? `${formatNumber(totalNeeds, 0, '.', ',')}` : totalNeeds.toFixed(0)}
      </p>
    </div>
  );
};
