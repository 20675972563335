import Popup from "reactjs-popup";
import moment from "moment";

import React, { FC, useCallback, useEffect, useState } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import { Collapse } from 'react-collapse';

import { ICompanyGoalSetting, IGoal, IGoalSetting } from "../../../types/IGoal";
import { IReportData, IAggregatedData, IGroupId, IPercentAnalyticsData } from "../../../types/IReport";
import { IOption } from "../../../types/ISelect";
import { IStore } from "../../../types/IStore";

import { goalActions } from "../../../sagas/goal.saga";
import { totalsActions } from "../../../sagas/totals.saga";

import ConfirmationModal from "./ConfirmationModal/ConfirmationModal";

import { formatDollarsGoal, getGoalValue } from "../../../utils/goal.util";

import styles from "./Content.module.scss";
import MonthlyTable from "../MonthlyTable/MonthlyTable";
import { AVERAGE_JOB_SIZE_ORDER, DOLLARS_BUILT_YTD_ORDER, JOB_BUILT_ORDER, PERCENT_DOLLAR_BUILT_MONTHLY_QUESTION_FOR_ANALYTICS_ORDER, PERCENT_DOLLAR_BUILT_MONTHLY_QUESTION_ORDER } from "../../../constants/goal";
import WarningModal from "./WarningModal/WarningModal";
import { companyGoalActions } from "../../../sagas/companyGoal.saga";
import { IProfile } from "../../../types/IProfile";
import { IFilters } from "../../../types/IFilters";
interface IPropsFromStore {
  profile: IProfile;
  companyGoal: ICompanyGoalSetting;
  goal: IGoalSetting;
  aggregatedData: (IReportData & IAggregatedData)[];
  totals: IReportData;
  filters: IFilters | null;
}

interface IDispatchProps {
  updateGoal: (payload: IGoalSetting) => void;
  getGoal: () => void;
  getTotals: (payload: IFilters) => void;
  getCompanyGoalSetting: (year: number) => void;
}

interface IPropsFromOutside {
  selectedYear: IOption;
  yearOptions: IOption[];
  setSelectedYear: any;
  usersIDs: string[];
  percentAnalyticsData: IPercentAnalyticsData[];
  companiesIDs: string[];
  time: { label: string, value: number };
  setGoalSettingState: (nextState: Partial<any>) => void;
}

const validPercentTotal = (total: number) => total >= 99.5 && total <= 100.5;

type IProps = IPropsFromStore & IDispatchProps & IPropsFromOutside;

const mapStateToProps = ({
  companyGoal,
  totals,
  goal,
  profile,
  aggregatedData,
  filters,
}: IStore): IPropsFromStore => ({
  totals,
  companyGoal,
  goal,
  profile,
  aggregatedData,
  filters,
});

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  updateGoal: (payload: IGoalSetting) => dispatch(goalActions.update.update(payload)),
  getGoal: () => dispatch(goalActions.get.load()),
  getCompanyGoalSetting: (year: number) => dispatch(companyGoalActions.get.load(year)),
  getTotals: (payload: IFilters) => dispatch(totalsActions.get.load(payload)),
});

const Content: FC<IProps> = ({
  yearOptions,
  selectedYear,
  setSelectedYear,
  goal,
  totals,
  getGoal,
  getTotals,
  updateGoal,
  setGoalSettingState,
  usersIDs,
  time,
  companiesIDs,
  percentAnalyticsData,
  filters
}) => {
  const [editing, setEditing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [goalData, setGoalData] = useState<IGoal>(goal);
  const [collapse, setCollapse] = useState(true);
  const [collapseAnalytics, setCollapseAnalytics] = useState(true);
  const [warning, setWarning] = useState(false);

  useEffect(() => {
    let newPercentDollarData = goalData.items[PERCENT_DOLLAR_BUILT_MONTHLY_QUESTION_FOR_ANALYTICS_ORDER - 1];
    if(newPercentDollarData.values && percentAnalyticsData?.length) {
      newPercentDollarData.values = percentAnalyticsData;

      const newItems = goalData.items;

      newItems[PERCENT_DOLLAR_BUILT_MONTHLY_QUESTION_FOR_ANALYTICS_ORDER -1 ] = newPercentDollarData;
      setGoalData({
        ...goalData,
        items: newItems,
      });

    }
  }, [percentAnalyticsData]);

  const toggleEditing = useCallback(() => {
    if(editing) {
      getGoal();
    }
    setEditing(!editing);
  }, [editing]);

  const onChangeGoal = useCallback(
    (e, goalQuestionOrder) => {
      const value = e.target.value;
      if(goalQuestionOrder === JOB_BUILT_ORDER) {
        const averageJobSizeIndex = goalData.items.findIndex(
          (item) => item.question?.order === AVERAGE_JOB_SIZE_ORDER
        );

        const dollarBuiltYTDIndex = goalData.items.findIndex(
          (item) => item.question?.order === DOLLARS_BUILT_YTD_ORDER
        );

        if(goalData.items[averageJobSizeIndex] && goalData.items[averageJobSizeIndex].value) {
          let newGoalItemsData = goalData.items;
          const newDollarBuilt = Number(value) === 0 ? 0 : (goalData.items[averageJobSizeIndex].value || 1) * Number(value);
          newGoalItemsData[dollarBuiltYTDIndex].value = Number(newDollarBuilt);
          setGoalData({
            ...goalData,
            items: newGoalItemsData,
          });
        } else if(goalData.items[dollarBuiltYTDIndex] && goalData.items[dollarBuiltYTDIndex].value) {
          let newGoalItemsData = goalData.items;
          const newAverageJobSize = (goalData.items[dollarBuiltYTDIndex].value || 1) / Number(value);
          newGoalItemsData[averageJobSizeIndex].value = Number(newAverageJobSize);
          setGoalData({
            ...goalData,
            items: newGoalItemsData,
          });
        }
      }

      if(goalQuestionOrder === AVERAGE_JOB_SIZE_ORDER) {
        const dollarBuiltYTDIndex = goalData.items.findIndex(
          (item) => item.question?.order === DOLLARS_BUILT_YTD_ORDER
        );
        const jobBuiltIndex = goalData.items.findIndex(
          (item) => item.question?.order === JOB_BUILT_ORDER
        );

        if(goalData.items[jobBuiltIndex] && goalData.items[jobBuiltIndex].value) {
          let newGoalItemsData = goalData.items;
          const newDollarBuilt = Number(value) === 0 ? 0 : (goalData.items[jobBuiltIndex].value || 1) * Number(value);
          newGoalItemsData[dollarBuiltYTDIndex].value = Number(newDollarBuilt);
          setGoalData({
            ...goalData,
            items: newGoalItemsData,
          });
        } else if(goalData.items[dollarBuiltYTDIndex] && goalData.items[dollarBuiltYTDIndex].value) {
          let newGoalItemsData = goalData.items;
          const newJobBuilt = (goalData.items[dollarBuiltYTDIndex].value || 1) / Number(value);
          newGoalItemsData[jobBuiltIndex].value = Number(newJobBuilt);
          setGoalData({
            ...goalData,
            items: newGoalItemsData,
          });
        }
      }

      if(goalQuestionOrder === DOLLARS_BUILT_YTD_ORDER) {
        const jobBuiltIndex = goalData.items.findIndex(
          (item) => item.question?.order === JOB_BUILT_ORDER
        );
        const averageJobSizeIndex = goalData.items.findIndex(
          (item) => item.question?.order === AVERAGE_JOB_SIZE_ORDER
        );

        if(goalData.items[jobBuiltIndex] && goalData.items[jobBuiltIndex].value) {
          let newGoalItemsData = goalData.items;
          const newAverageJobSize = Number(value) / (goalData.items[jobBuiltIndex].value || 1);
          newGoalItemsData[averageJobSizeIndex].value = Number(newAverageJobSize);
          setGoalData({
            ...goalData,
            items: newGoalItemsData,
          });
        } else if(goalData.items[averageJobSizeIndex] && goalData.items[averageJobSizeIndex].value) {
          let newGoalItemsData = goalData.items;
          const newJobBuilt = (goalData.items[averageJobSizeIndex].value || 1) / Number(value);
          newGoalItemsData[jobBuiltIndex].value = Number(newJobBuilt);
          setGoalData({
            ...goalData,
            items: newGoalItemsData,
          });
        }
      }
      
      const itemIndex = goalData.items.findIndex(
        (item) => item.question?.order === goalQuestionOrder
      );
      if (itemIndex > -1) {
        let newGoalItemsData = goalData.items;
        newGoalItemsData[itemIndex].value = Number(value);
        setGoalData({
          ...goalData,
          items: newGoalItemsData,
        });
      }
    },
    [goalData]
  );

  const isValidValue = (value) => value === undefined || value === null || value === "" || /^(?!false$)\d*\.?\d*$/.test(value);

  const onChangePercentDollarGoal = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, month: number) => {
      const value = e.target.value;
      let newPercentDollarData = goalData.items[PERCENT_DOLLAR_BUILT_MONTHLY_QUESTION_ORDER - 1];
      if(newPercentDollarData.values) {
        newPercentDollarData.values = newPercentDollarData.values.map(item => {
          if(item.month === month - 1) {
            return {
              ...item,
              value: isValidValue(value) ? value as unknown as number : item.value,
            }
          } else {
            return item
          }
        })

        const newItems = goalData.items;

        newItems[PERCENT_DOLLAR_BUILT_MONTHLY_QUESTION_ORDER -1 ] = newPercentDollarData;
        setGoalData({
          ...goalData,
          items: newItems,
        });
      }
    },
    [goalData]
  );

  const saveGoalSettings = useCallback(() => {
    if(!time) {
      toast.error(`Goal time can not be empty`);
      return;
    };

    if(!goalData.items[AVERAGE_JOB_SIZE_ORDER - 1].value) {
      toast.error(`Average job size can not be empty`);
      return;
    }

    let total = 0;
    const values = goalData.items[PERCENT_DOLLAR_BUILT_MONTHLY_QUESTION_ORDER - 1].values;
    if(values) {
      values.map(el => total += Number(el.value));
    }
    if(!validPercentTotal(total)) {
      setWarning(true);
      return;
    } 
    
    if (
      goalData.items[PERCENT_DOLLAR_BUILT_MONTHLY_QUESTION_ORDER - 1].values
    ) {
      goalData.items[
        PERCENT_DOLLAR_BUILT_MONTHLY_QUESTION_ORDER - 1
      ].values = goalData.items[
        PERCENT_DOLLAR_BUILT_MONTHLY_QUESTION_ORDER - 1
      ].values?.map((el) => ({
        ...el,
        value:  Number(el.value)
      }));
    }
    
    setSubmitting(true);

    const goalDataBody = {
      users: usersIDs.length === 0 ? goal.users : usersIDs,
      companies: companiesIDs,
      items: goalData.items.map((item) => {
        const { question, ...rest } = item;
        return rest;
      }),
      year: time.value,
    };

    updateGoal(goalDataBody);
    setSubmitting(false);
    toggleEditing();

    // setGoalSettingState({
    //   companiesIDs: [],
    //   usersIDs: [],
    // })
  }, [time, goalData, updateGoal, toggleEditing]);

  // useEffect(() => {
  //   getTotals({
  //     users: usersIDs,
  //     averageBy: filters?.averageBy,
  //   })
  // }, []);

  useEffect(() => {
    if (!selectedYear && yearOptions.length) {
      setSelectedYear(yearOptions[0]);
    }
  }, [yearOptions, selectedYear]);

  const toggleCollapse = (order) => () => {
    if (order === PERCENT_DOLLAR_BUILT_MONTHLY_QUESTION_ORDER) {
      setCollapse(prev => !prev)
    } else {
      setCollapseAnalytics(prev => !prev)
    }
  };

  return (
    <div className={styles.goalContent}>
      <div className={styles.goalTable}>
        <div className={styles.goalTableHeader}>
          <div className={`${styles.tableColumn} ${styles.tableColumn1}`}>
            #
          </div>
          <div className={`${styles.tableColumn} ${styles.tableColumn2}`}>
            Key Parameters
          </div>
          <div className={styles.goalFooter}>
            <button
              type="submit"
              className={styles.formBtn}
              disabled={submitting}
              onClick={toggleEditing}
            >
              <span
                className={`${
                  editing ? styles.iconSvgCancel : styles.iconSvgEdit
                } ${styles.icon}`}
              ></span>
            </button>
            <Popup
              modal
              trigger={
                <button
                  type="button"
                  className={`${styles.formBtn} ${styles.save}`}
                  disabled={!editing || submitting}
                >
                  <span
                    className={`${styles.iconSvgSave} ${styles.icon}`}
                  ></span>
                </button>
              }
              contentStyle={{ maxWidth: "498px" }}
            >
              {(close) => (
                <ConfirmationModal onClose={close} onSave={saveGoalSettings} />
              )}
            </Popup>

            <Popup modal open={warning} contentStyle={{ maxWidth: "498px" }}>
              {(close) => <WarningModal onClose={() => setWarning(false)} />}
            </Popup>
          </div>
          <div className={`${styles.tableColumn} ${styles.tableColumn3}`}>
            Goal
          </div>
          <div className={`${styles.tableColumn} ${styles.tableColumn4}`}>
            <p>Analytics</p>
          </div>
        </div>
        <div className={styles.goalTableBody}>
          {goalData.items?.map((goalItem, idx) => {
            if (
              goalItem.question?.order ===
              PERCENT_DOLLAR_BUILT_MONTHLY_QUESTION_ORDER ||
              goalItem.question?.order ===
              PERCENT_DOLLAR_BUILT_MONTHLY_QUESTION_FOR_ANALYTICS_ORDER
            ) {
              return (
                <>
                  <div
                    key={goalItem.question?.order}
                    className={styles.goalTableItem}
                  >
                    <div
                      className={`${styles.tableColumn} ${styles.tableColumn1}`}
                    >
                      {goalItem.question?.order}
                    </div>
                    <div
                      className={`${styles.tableColumn} ${styles.tableColumn2} ${styles.tableValueColumn2}`}
                    >
                      {goalItem.question?.description}
                    </div>
                    <div
                      className={`${styles.tableColumn} ${styles.tableColumn3}`}
                    >
                      <button
                        type="button"
                        className={`${styles.collapseBtn} ${
                          (goalItem.question?.order ===
                            PERCENT_DOLLAR_BUILT_MONTHLY_QUESTION_ORDER ? collapse : collapseAnalytics) ? styles.open : styles.close
                        }`}
                        onClick={toggleCollapse(goalItem.question?.order)}
                      />
                    </div>
                    <div
                      className={`${styles.tableColumn} ${styles.tableColumn4}`}
                    >
                      <input value="" className={styles.goalInput} disabled />
                    </div>
                  </div>
                  <Collapse isOpened={goalItem.question?.order ===
                            PERCENT_DOLLAR_BUILT_MONTHLY_QUESTION_ORDER ? collapse : collapseAnalytics}>
                    <MonthlyTable
                      editing={ goalItem.question?.order ===
                        PERCENT_DOLLAR_BUILT_MONTHLY_QUESTION_ORDER ? editing : false}
                      percentDollars={goalData}
                      indexOrder={goalItem.question?.order}
                      onChange={onChangePercentDollarGoal}
                    />
                  </Collapse>
                </>
              );
            }
            return (
              <div
                key={goalItem.question?.order}
                className={`${styles.goalTableItem} ${[
                  AVERAGE_JOB_SIZE_ORDER,
                  JOB_BUILT_ORDER,
                ].includes(goalItem.question?.order || 0) &&
                  styles.goalTableItemHighlight}`}
              >
                <div className={`${styles.tableColumn} ${styles.tableColumn1}`}>
                  {goalItem.question?.order}
                </div>
                <div
                  className={`${styles.tableColumn} ${styles.tableColumn2} ${styles.tableValueColumn2}`}
                >
                  {goalItem.question?.description}
                </div>
                <div className={`${styles.tableColumn} ${styles.tableColumn3}`}>
                  <input
                    type={editing ? "number" : "text"}
                    value={
                      !isNaN(goalItem.value || 0)
                        ? editing
                          ? goalItem.value
                          : formatDollarsGoal(
                              goalItem.value || 0,
                              goalItem.question?.order || 0,
                              editing
                            )
                        : undefined
                    }
                    className={styles.goalInput}
                    disabled={!editing || submitting}
                    onChange={(e) => onChangeGoal(e, goalItem.question?.order)}
                  />
                </div>
                <div className={`${styles.tableColumn} ${styles.tableColumn4}`}>
                  <input
                    value={getGoalValue(totals, goalItem.question?.order)}
                    className={styles.goalInput}
                    disabled
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default connect<IPropsFromStore, IDispatchProps, any, any>(
  mapStateToProps,
  dispatchProps
)(Content);
