import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';

import { isError } from '../../utils/valueState';

import { IStore } from '../../types/IStore';

import { authActions } from '../../sagas/auth.saga';

import { ROUTES } from '../../constants/router';
import { LOGIN_FORM_VALUES } from '../../constants/formsInitialValues';
import { LOGIN_FORM_SCHEMA } from '../../constants/formsYupSchemas';
import { ERRORS } from '../../constants/errors';
import { ILoginPayload } from '../../types/IPayloads';

import Logo from '../../assets/imgs/logo.svg';

import styles from './Login.module.scss';

interface IStateToProps {
  auth: string | null;
}

interface IDispatchProps {
  login: (payload: ILoginPayload) => void;
}

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  login: (payload: ILoginPayload) => dispatch(authActions.login.load(payload))
});

const mapStateToProps = ({ auth }: IStore): IStateToProps => ({
  auth
});

type IProps = IDispatchProps & IStateToProps;

class Login extends Component<IProps> {
  private toastLoginErrID = 'login-err';

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (isError(this.props.auth) && !toast.isActive(this.toastLoginErrID)) {
      toast.error(ERRORS.WRONG_LOGIN.credentials, {
        toastId: this.toastLoginErrID
      });
    }
  }

  public componentWillUnmount(): void {
    toast.dismiss(this.toastLoginErrID);
  }

  private onSubmit = (values, { setSubmitting }: FormikHelpers<any>) => {
    const { email, password } = values;

    this.props.login({
      email,
      password
    } as ILoginPayload);
    setSubmitting(false);
  };

  public render() {
    return (
      <div className={styles.notAuth}>
        <div className={styles.notAuthLogo}>
          <img src={Logo} alt="Superstorm" />
        </div>
        <Formik
          initialValues={LOGIN_FORM_VALUES}
          validationSchema={LOGIN_FORM_SCHEMA}
          onSubmit={this.onSubmit}
        >
          {({ isSubmitting }) => (
            <Form className={styles.notAuthForm}>
              <div className={styles.notAuthFormField}>
                <Field type="email" name="email" placeholder="Email" />
                <ErrorMessage
                  name="email"
                  component="p"
                  className={styles.notAuthFormError}
                />
              </div>
              <div className={styles.notAuthFormField}>
                <Field type="password" name="password" placeholder="Password" />
                <ErrorMessage
                  name="password"
                  component="p"
                  className={styles.notAuthFormError}
                />
              </div>
              <Link
                to={ROUTES.ForgotPassStepEmail.path}
                className={styles.notAuthLink}
              >
                Forgot your password?
              </Link>
              <button
                type="submit"
                disabled={isSubmitting}
                className={styles.notAuthFormSubmit}
              >
                Sign In
              </button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default connect<IStateToProps, IDispatchProps, any, any>(
  mapStateToProps,
  dispatchProps
)(Login);
