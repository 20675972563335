export interface IUser {
  _id: string;
  email: string;
  username?: string;
  company?: string;
  firstName?: string;
  lastName?: string;
  profileImg?: string;
  accessLevel: number;
  phone?: string;
  isActive: boolean;
  assignedUsers?: Array<string | IAssignedUser>;
}

export interface IAssignedUser {
  _id: string;
  first_name?: string;
  last_name?: string;
}

export interface IUserCreate {
  _id?: string;
  email: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  password: string;
  phone?: string;
  company?: string;
  accessLevel: number;
  assignedUsers?: string[];
}

export interface IUserCreatePayload {
  payload: IUserCreate;
}

export interface IUserByCompanyPayload {
  company?: string;
}

export enum ACCESS_LEVEL {
  EMPLOYEE = 0,
  SUPER_ADMIN = 1,
  COMPANY_ADMIN = 2,
  SENIOR_SALES_MANAGER = 3,
  SALES_MANAGER = 4,
}