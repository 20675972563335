import { IProfile } from '../types/IProfile';
import { APP_CONSTANTS } from '../constants/global';

export const isSuperAdmin = (profile: IProfile): boolean => profile.accessLevel === APP_CONSTANTS.ACCESS_LEVELS.SUPER_ADMIN;
export const isCompanyAdmin = (profile: IProfile): boolean => profile.accessLevel === APP_CONSTANTS.ACCESS_LEVELS.COMPANY_ADMIN;
export const isSeniorSalesManager = (profile: IProfile): boolean => profile.accessLevel === APP_CONSTANTS.ACCESS_LEVELS.SENIOR_SALES_MANAGER;
export const isSalesManager = (profile: IProfile): boolean => profile.accessLevel === APP_CONSTANTS.ACCESS_LEVELS.SALES_MANAGER;
export const isSalesPerson = (profile: IProfile): boolean => profile.accessLevel === APP_CONSTANTS.ACCESS_LEVELS.EMPLOYEE;

export const isAdmin = (profile: IProfile): boolean => [
  APP_CONSTANTS.ACCESS_LEVELS.SUPER_ADMIN,
  APP_CONSTANTS.ACCESS_LEVELS.COMPANY_ADMIN,
].includes(profile.accessLevel);

export const isSeniorSalesManagerOrHigher = (profile: IProfile): boolean => [
  APP_CONSTANTS.ACCESS_LEVELS.SUPER_ADMIN,
  APP_CONSTANTS.ACCESS_LEVELS.COMPANY_ADMIN,
  APP_CONSTANTS.ACCESS_LEVELS.SENIOR_SALES_MANAGER,
].includes(profile.accessLevel);

export const isCompanyAdminOrLower = (profile: IProfile): boolean => [
  APP_CONSTANTS.ACCESS_LEVELS.COMPANY_ADMIN,
  APP_CONSTANTS.ACCESS_LEVELS.SENIOR_SALES_MANAGER,
].includes(profile.accessLevel);
