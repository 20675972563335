import React from 'react';
import styles from './ColorPicker.module.scss';

interface IProps {
  defaultColor?: string;
  onSetColor: (string) => void;
}

export const ColorPicker: React.FC<IProps> = ({ defaultColor, onSetColor }) => {
  const onChangeColor = (e) => {
    const color = e.target.value;
    onSetColor(color);
  }

  return (
    <div className={styles.colorPicker}>
      {defaultColor ? (
        <div className={styles.colorBox} style={{backgroundColor: defaultColor}}></div>
      ) : (
        <div className={styles.noColor}>Not set</div>
      )}
      <div className={styles.picker}>
        <button type="button" className={styles.imgEditIcon} />
          <input type="color" value={defaultColor} className={styles.colorInput} onChange={onChangeColor} />
      </div>
    </div>
  );
};