import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { Notification } from './Notification';

import { INotification } from '../../types/INotification';
import { IStore } from '../../types/IStore';

import { popupsActions } from '../../reducers/popups';
import { notificationsActions } from '../../sagas/notifications.saga';

import styles from './Notifications.module.scss';

interface IPropsFromStore {
  notifications: INotification[];
}

interface IDispatchProps {
  toggleNotifications: () => void;
  confirmNotification: (payload: string) => void;
  rejectNotification: (payload: string) => void;
  removeNotification: (payload: string) => void;
}

const mapStateToProps = ({ notifications }: IStore): IPropsFromStore => ({
  notifications,
});

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  toggleNotifications: () => dispatch(popupsActions.toggleNotifications()),
  confirmNotification: payload => dispatch(notificationsActions.confirm.update(payload)),
  rejectNotification: payload => dispatch(notificationsActions.reject.update(payload)),
  removeNotification: payload => dispatch(notificationsActions.remove.update(payload)),
});

type IProps = IPropsFromStore & IDispatchProps;

class Notifications extends Component<IProps> {
  private onCloseClick = () => this.props.toggleNotifications();

  private onNotificationConfirmed = (_id: string) => this.props.confirmNotification(_id);

  private onNotificationRejected = (_id: string) => this.props.rejectNotification(_id);

  private onNotificationRemoved = (_id: string) => this.props.removeNotification(_id);

  private renderNotifications = () => this.props.notifications.map( notification => (
    <Notification onRejected={this.onNotificationRejected} onRemoved={this.onNotificationRemoved} onConfirmed={this.onNotificationConfirmed} notification={notification} key={notification._id} />
  ));

  public render() {
    return (
      <div className={`${styles.notifications} ${this.props.notifications.length === 0 ? styles.empty : ''}`}>
        <button className={styles.closeBtn} onClick={this.onCloseClick} />
        {this.props.notifications.length > 0 ? (
          <div className={styles.notificationsList}>
            {this.renderNotifications()}
          </div>
        ) : <p className={styles.noData}>No Notifications</p>}
      </div>
    )
  }
}

export default connect<IPropsFromStore, IDispatchProps, any, any>(
  mapStateToProps,
  dispatchProps
)(Notifications);
