import React, { Component, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { Dispatch } from 'redux';

import { PICKER } from '../../../constants/datepicker.config';
import { FILTER_QUICK_PERIODS } from '../../../constants/Filters';
import { REPORT_EXPORT_TYPES } from '../../../constants/report';

import { IStore } from '../../../types/IStore';
import { IProfile } from '../../../types/IProfile';
import { IFilters } from '../../../types/IFilters';
import { ACCESS_LEVEL, IUser } from '../../../types/IUser';
import { ICompany } from '../../../types/ICompany';

import { companyProjectionFiltersActions } from '../../../sagas/companyProjectionFilters.saga';

import { isReady } from '../../../utils/valueState';
import { isCompanyAdmin, isCompanyAdminOrLower, isSeniorSalesManager, isSuperAdmin } from '../../../utils/user.util';
import moment from '../../../utils/moment';
import { getQuickFilterDate } from '../../../utils/dates';
import { exportCompanyProjection } from '../../../utils/goal.util';

import styles from './Filters.module.scss';
import { GroupType, IGroupId } from '../../../types/IReport';

interface IPropsFromStore {
  profile: IProfile;
  users: IUser[];
  companies: ICompany[];
  companyProjectionAggregatedData: any;
}

interface IDispatchProps {
  setCompanyProjectionFilters: (payload: IFilters) => void;
}

const mapStateToProps = ({ profile, users, companies, companyProjectionAggregatedData}: IStore): IPropsFromStore => ({
  profile,
  users,
  companies,
  companyProjectionAggregatedData
});

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  setCompanyProjectionFilters: (payload: IFilters) =>
    dispatch(companyProjectionFiltersActions.set.update(payload)),
});

type IProps = IPropsFromStore & IDispatchProps;

interface IState {
  increased: boolean;
  from: string | number | null;
  to: string | number | null;
  companiesField: string;
  usersField: string;
  selectedCompanies: ICompany[];
  usersIDs: string[];
  companiesIDs: string[];
  isShowSelected: boolean;
  companies: ICompany[];
  pdfExportEnabled: boolean | null;
  displayType: GroupType | null;
}

class Filters extends Component<IProps, IState> {
  public state: Readonly<IState> = {
    increased: false,
    from: "",
    to: "",
    companiesField: "",
    usersField: "",
    selectedCompanies: [],
    usersIDs: [],
    companiesIDs: [],
    isShowSelected: false,
    companies: [],
    displayType: null,
    pdfExportEnabled: null,
  };
  private companiesParticipantsAdded: boolean = false;

  public componentDidMount(): void {
    const { companies, users, profile } = this.props;

    if (isSuperAdmin(profile)) {
      if (isReady(companies) && isReady(users)) {
        this.calcCompaniesParticipants();
      }
      if (isReady(profile)) {
        this.setState({
          pdfExportEnabled: !isSuperAdmin(profile),
        });
        if (isReady(users)) {
          let renderedUsers: IUser[] = [];

          if (isSuperAdmin(profile)) {
            renderedUsers = users;
          } else if (isCompanyAdmin(profile)) {
            renderedUsers = users.filter(
              (user) => user.company === profile.company?._id
            );
          } else if (isSeniorSalesManager(profile)) {
            const assignedUsers = profile.assignedUsers;
            renderedUsers = assignedUsers
              ? users.filter((user) => assignedUsers.includes(user._id))
              : [];
          }
    
          const defaultSelectedUsers = profile.company?.participants?.filter(
            (participant: string) => {
              const user = this.props.users.find((u) => u._id === participant);
              if (user) {
                return ![ACCESS_LEVEL.COMPANY_ADMIN, ACCESS_LEVEL.SUPER_ADMIN].includes(
                  user.accessLevel
                )
              }
              return true;
            }
          ) || [];
          const filtered = defaultSelectedUsers.filter(user => renderedUsers.find(u => u._id === user));
          this.props.setCompanyProjectionFilters({
            users: filtered,
            companies: profile.company?._id ? [profile.company?._id] : [],
          });
        } else {
          this.props.setCompanyProjectionFilters({
            companies: profile.company?._id ? [profile.company?._id] : [],
          });
        }

        this.setState({
          companiesIDs: profile.company?._id ? [profile.company?._id] : [],
        });

      }
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { companies, users, profile, companyProjectionAggregatedData } = this.props;

    if (isSuperAdmin(profile)) {
      if (
        isReady(companies) &&
        isReady(users) &&
        !this.companiesParticipantsAdded
      ) {
        this.calcCompaniesParticipants();
      }
      if (isReady(profile) && this.state.pdfExportEnabled === null) {
        this.setState({
          pdfExportEnabled: !isSuperAdmin(profile),
        });
      }
    }
    if (!this.state.from && !this.state.to && companyProjectionAggregatedData.length) {
      if(prevProps.companyProjectionAggregatedData !== this.props.companyProjectionAggregatedData)  {
        let firstDay = '';
        let lastDay = '';
        if (companyProjectionAggregatedData.length > 1) {
          const main = (companyProjectionAggregatedData[0]._id as IGroupId).main;
          firstDay = main === 2 ? companyProjectionAggregatedData[0].firstDay : companyProjectionAggregatedData[1].firstDay;
          lastDay = companyProjectionAggregatedData[companyProjectionAggregatedData.length - 1].lastDay;
        }

        if (firstDay && lastDay) {
          this.setState({
            from: moment(firstDay).utcOffset(0, true).valueOf(),
            // to: this.getLastDateOfYear(moment(lastDay).utcOffset(0, true).toDate()).getTime(),
          });
        }
      }
    }
  }

  private getLastDateOfYear = (actualDate) => {
    return new Date(actualDate.getFullYear(),12,0);
  }

  private calcCompaniesParticipants = () => {
    const { users } = this.props;
    const companies = [...this.props.companies];

    companies.forEach((company) => {
      company.participants = users
        .filter((user) => user.company === company._id)
        .map(({ _id }) => _id);
    });

    this.setState(
      {
        companies,
      },
      () => (this.companiesParticipantsAdded = true)
    );
  };

  private increasedToggle = () =>
    this.setState({
      increased: !this.state.increased,
    });

  private setFromDate = (date) =>
    this.setState({
      from: moment.utc(date).valueOf(),
    });

  private setToDate = (date) =>
    this.setState({
      to: moment.utc(date).valueOf(),
    });

  private onSelectAllUsers = () => {
    const { users, profile } = this.props;
    let renderedUsers: IUser[] = [];

    if (isSuperAdmin(profile)) {
      renderedUsers = users;
    } else if (isCompanyAdmin(profile)) {
      renderedUsers = users.filter(
        (user) => user.company === profile.company?._id
      );
    } else if (isSeniorSalesManager(profile)) {
      const assignedUsers = profile.assignedUsers;
      renderedUsers = assignedUsers
        ? users.filter((user) => assignedUsers.includes(user._id))
        : [];
    }
    const userForFilter = renderedUsers
      .filter(
        (u) =>
          ![ACCESS_LEVEL.COMPANY_ADMIN, ACCESS_LEVEL.SUPER_ADMIN].includes(
            u.accessLevel
          )
      )
      .map((u) => u._id);
    this.setState({
      usersIDs: userForFilter,
    });
  };

  private onClickApply = () => {
    const { from, to, usersIDs } = this.state;

    this.props.setCompanyProjectionFilters({
      from: from
        ? moment(from)
            .local()
            .format("YYYY-MM-DD")
        : null,
      to: to
        ? moment(to)
            .local()
            .format("YYYY-MM-DD")
        : null,
      users: usersIDs,
      displayType: this.state.displayType,
    });
  };

  private setDisplayFilter = (type: GroupType | null) => () => {
    if (this.state.displayType === type) {
      this.setState({
        displayType: null,
      });
    } else {
      this.setState({
        displayType: type,
      });
    }
  };

  private onChangeSearchField = (e: SyntheticEvent, field: string) => {
    const { target } = e;

    this.setState(
      // @ts-ignore
      {
        [field]: (target as HTMLInputElement).value as string,
      }
    );
  };

  private resetSearchCompaniesFilter = () =>
    this.setState({
      companiesField: "",
      companiesIDs: [],
    });
  private resetSearchUsersFilter = () =>
    this.setState({
      usersField: "",
      usersIDs: [],
    });

  private onCompanySelect = (company: ICompany) => () => {
    const companiesIDs: string[] = [...this.state.companiesIDs];
    const companyIDIndex = companiesIDs.findIndex((id) => id === company._id);

    companyIDIndex >= 0
      ? companiesIDs.splice(companyIDIndex, 1)
      : companiesIDs.push(company._id);

    let newUsers: string[] = [];
    const notSelectedUsers =
      company.participants?.filter((participant: string) => {
        const user = this.props.users.find((user) => user._id === participant);
        if (user) {
          return ![
            ACCESS_LEVEL.COMPANY_ADMIN,
            ACCESS_LEVEL.SUPER_ADMIN,
          ].includes(user.accessLevel);
        }
        return true;
      }) || [];
    if (companyIDIndex >= 0) {
      newUsers = this.state.usersIDs.filter(
        (userId) => !company.participants?.includes(userId)
      );
    } else {
      const companyParticipants = company.participants || [];
      newUsers = [...this.state.usersIDs, ...notSelectedUsers];
    }

    this.setState({
      companiesIDs,
      usersIDs: newUsers,
    });
  };

  private checkIsCompanyActive = (company: ICompany): boolean =>
    this.state.companiesIDs.some((id) => id === company._id);

  private setQuickFilter = (period: string) => () => {
    const { LAST_WEEK, LAST_MONTH, LAST_YEAR } = FILTER_QUICK_PERIODS;
    if (period === LAST_WEEK.name) {
      this.setState({
        from: getQuickFilterDate(LAST_WEEK),
      });
    } else if (period === LAST_MONTH.name) {
      this.setState({
        from: getQuickFilterDate(LAST_MONTH),
      });
    } else {
      this.setState({
        from: getQuickFilterDate(LAST_YEAR),
      });
    }
  };

  private exportCompanyProjection = (type: string) => () => {
    exportCompanyProjection(type).then( ({data}) => window.open(data, '_self'));
  };

  private checkIsUserActive = (user: IUser): boolean =>
    this.state.usersIDs.some((id) => id === user._id);

  private onUserSelect = (user: IUser) => () => {
    const usersIDs: string[] = [...this.state.usersIDs];
    const userIDIndex = usersIDs.findIndex((id) => id === user._id);

    userIDIndex >= 0
      ? usersIDs.splice(userIDIndex, 1)
      : usersIDs.push(user._id);

    this.setState({
      usersIDs,
    });
  };

  private renderCompanies = (companies: ICompany[], filterField: string) =>
    companies
      .filter((company) => company.name.match(filterField))
      .map((company) => (
        <li
          key={company._id}
          onClick={this.onCompanySelect(company)}
          className={this.checkIsCompanyActive(company) ? styles.active : ""}
        >
          {company.name}
        </li>
      ));

  private renderUsers = (users: IUser[], filterField: string) => {
    const { profile } = this.props;
    const assignedUsers = profile.assignedUsers;
    const renderedUsers = assignedUsers
      ? users.filter((user) => assignedUsers.includes(user._id))
      : [];
    return renderedUsers
      .filter(
        (u) =>
          ![ACCESS_LEVEL.COMPANY_ADMIN, ACCESS_LEVEL.SUPER_ADMIN].includes(
            u.accessLevel
          )
      )
      .filter((user) => {
        const userName =
          user.firstName && user.lastName
            ? `${user.firstName} ${user.lastName}`
            : user.username || user.email;

        if (!this.state.isShowSelected) {
          return userName.match(filterField);
        } else {
          const selectedCompanies = this.state.companies.filter((company) =>
            this.state.companiesIDs.some((id) => id === company._id)
          );

          // @ts-ignore
          return (
            userName.match(filterField) &&
            selectedCompanies.some((company) =>
              company.participants?.includes(user._id)
            )
          );
        }
      })
      .map((user) => (
        <li
          key={user._id}
          onClick={this.onUserSelect(user)}
          className={this.checkIsUserActive(user) ? styles.active : ""}
        >
          {user.firstName && user.lastName
            ? `${user.firstName} ${user.lastName}`
            : user.username || user.email}
        </li>
      ));
  };

  public componentWillUnmount(): void {
    this.props.setCompanyProjectionFilters({
      from: null,
      to: null,
      companies: [],
      users: [],
      excludeDeactivated: false,
    });
  }

  public render() {
    const {
      increased,
      from,
      to,
      companiesField,
      usersField,
      companies,
    } = this.state;
    const { profile, users } = this.props;

    return (
      <div className={`${styles.filters} ${increased ? styles.increased : ""}`}>
        <div className={styles.header}>
          <div className={styles.timeRange}>
            <div
              className={`${styles.pickerWrapper} picker--custom ${
                !from ? "empty" : ""
              }`}
            >
              <DatePicker
                {...PICKER}
                selected={from}
                className={`${styles.timeRangePicker} ${
                  !from ? styles.empty : ""
                }`}
                onChange={this.setFromDate}
                placeholderText="--/--/----"
                maxDate={to || Date.now()}
                isClearable={true}
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div
                    style={{
                      margin: 10,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      style={{ marginRight: "5px" }}
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      {"<"}
                    </button>
                    <select
                      value={
                        date instanceof Date
                          ? date.getFullYear()
                          : new Date(date).getFullYear()
                      }
                      onChange={({ target: { value } }) => changeYear(value)}
                      style={{ marginRight: "5px" }}
                    >
                      {[...Array(new Date().getFullYear() - 2019)].map(
                        (_, i) => (
                          <option key={i} value={new Date().getFullYear() - i}>
                            {new Date().getFullYear() - i}
                          </option>
                        )
                      )}
                    </select>

                    <select
                      value={
                        date instanceof Date
                          ? date.getMonth()
                          : new Date(date).getFullYear()
                      }
                      onChange={({ target: { value } }) => changeMonth(value)}
                    >
                      {Array.from({ length: 12 }, (_, i) => (
                        <option key={i} value={i}>
                          {new Date(0, i).toLocaleString("default", {
                            month: "long",
                          })}
                        </option>
                      ))}
                    </select>

                    <button
                      style={{ marginLeft: "5px" }}
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      {">"}
                    </button>
                  </div>
                )}
              />
            </div>
            <span>to</span>
            <div
              className={`${styles.pickerWrapper} picker--custom ${
                !to ? "empty" : ""
              }`}
            >
              <DatePicker
                {...PICKER}
                selected={to || Date.now()}
                className={`${styles.timeRangePicker} ${
                  !to ? styles.empty : ""
                }`}
                onChange={this.setToDate}
                placeholderText="--/--/----"
                minDate={from}
                isClearable={true}
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div
                    style={{
                      margin: 10,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      style={{ marginRight: "5px" }}
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      {"<"}
                    </button>
                    <select
                      value={
                        date instanceof Date
                          ? date.getFullYear()
                          : new Date(date).getFullYear()
                      }
                      onChange={({ target: { value } }) => changeYear(value)}
                      style={{ marginRight: "5px" }}
                    >
                      {[...Array(new Date().getFullYear() - 2019)].map(
                        (_, i) => (
                          <option key={i} value={new Date().getFullYear() - i}>
                            {new Date().getFullYear() - i}
                          </option>
                        )
                      )}
                    </select>

                    <select
                      value={
                        date instanceof Date
                          ? date.getMonth()
                          : new Date(date).getMonth()
                      }
                      onChange={({ target: { value } }) => changeMonth(value)}
                    >
                      {Array.from({ length: 12 }, (_, i) => (
                        <option key={i} value={i}>
                          {new Date(0, i).toLocaleString("default", {
                            month: "long",
                          })}
                        </option>
                      ))}
                    </select>

                    <button
                      style={{ marginLeft: "5px" }}
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      {">"}
                    </button>
                  </div>
                )}
              />
            </div>
            <ul className={styles.quickFiltersList}>
              <li>
                <button
                  onClick={this.setQuickFilter(
                    FILTER_QUICK_PERIODS.LAST_WEEK.name
                  )}
                >
                  last week
                </button>
              </li>
              <li>
                <button
                  onClick={this.setQuickFilter(
                    FILTER_QUICK_PERIODS.LAST_MONTH.name
                  )}
                >
                  last month
                </button>
              </li>
              <li>
                <button
                  onClick={this.setQuickFilter(
                    FILTER_QUICK_PERIODS.LAST_YEAR.name
                  )}
                >
                  last year
                </button>
              </li>
              <li>
                <button onClick={this.setQuickFilter(FILTER_QUICK_PERIODS.THIS_YEAR.name)}>this year</button>
              </li>
            </ul>
            {isSuperAdmin(profile) || isSeniorSalesManager(profile) ? (
              <>
                <button
                  className={styles.moreFiltersBtn}
                  onClick={this.increasedToggle}
                >
                  More filters
                </button>
                <ul
                  className={`${styles.quickFiltersList} ${styles.displayTypeList}`}
                >
                  <li
                    className={`${
                      this.state.displayType === GroupType.day
                        ? styles.active
                        : ""
                    }`}
                  >
                    <button onClick={this.setDisplayFilter(GroupType.day)}>
                      daily
                    </button>
                  </li>
                  <li
                    className={`${
                      this.state.displayType === GroupType.week
                        ? styles.active
                        : ""
                    }`}
                  >
                    <button onClick={this.setDisplayFilter(GroupType.week)}>
                      weekly
                    </button>
                  </li>
                  <li
                    className={`${
                      this.state.displayType === GroupType.month
                        ? styles.active
                        : ""
                    }`}
                  >
                    <button onClick={this.setDisplayFilter(GroupType.month)}>
                      monthly
                    </button>
                  </li>
                </ul>
                <button className={styles.applyBtn} onClick={this.onClickApply}>
                  Apply
                </button>
              </>
            ) : (
              <button className={styles.applyBtn} onClick={this.onClickApply}>
                Apply
              </button>
            )}
          </div>
          <div className={styles.export}>
            <button
              className={styles.exportBtn}
              onClick={this.exportCompanyProjection(
                REPORT_EXPORT_TYPES.CSV.name
              )}
            >
              XLSX
            </button>
          </div>
        </div>
        {isSuperAdmin(profile) && increased && (
          <>
            <div className={styles.content}>
              <div className={styles.area}>
                <div className={styles.areaSearch}>
                  <label htmlFor="companies" className={styles.areaSearchTitle}>
                    Companies
                  </label>
                  <input
                    id="companies"
                    type="text"
                    value={companiesField}
                    className={styles.areaSearchField}
                    onChange={(e) =>
                      this.onChangeSearchField(e, "companiesField")
                    }
                  />
                  <button
                    disabled={
                      !companiesField.length && !this.state.companiesIDs.length
                    }
                    onClick={this.resetSearchCompaniesFilter}
                    className={styles.areaSearchReset}
                  >
                    Reset
                  </button>
                </div>
                <ul className={styles.areaList}>
                  {this.renderCompanies(companies, companiesField)}
                </ul>
              </div>
            </div>
            <button
              className={`${styles.applyBtn} ${styles.applyBtnIncreased}`}
              onClick={this.onClickApply}
            >
              Apply
            </button>
          </>
        )}
        {isSeniorSalesManager(profile) && (
          <>
            <div className={styles.content}>
              <div className={styles.area}>
                <div className={styles.areaSearch}>
                  <label htmlFor="users" className={styles.areaSearchTitle}>
                    Users
                  </label>
                  <input
                    id="users"
                    type="text"
                    value={usersField}
                    className={styles.areaSearchField}
                    onChange={(e) => this.onChangeSearchField(e, "usersField")}
                  />
                  <button
                    disabled={!usersField.length}
                    onClick={this.resetSearchUsersFilter}
                    className={styles.areaSearchReset}
                  >
                    Reset
                  </button>
                </div>
                <ul className={`${styles.areaList} ${styles.areaListCheckbox}`}>
                  {isReady(users) && this.renderUsers(users, usersField)}
                </ul>
              </div>
            </div>
            <button
              className={`${styles.applyBtn} ${styles.applyBtnIncreased}`}
              onClick={this.onClickApply}
            >
              Apply
            </button>
            {isCompanyAdminOrLower(profile) && (
              <button
                className={styles.applyBtn}
                onClick={this.onSelectAllUsers}
              >
                Select All
              </button>
            )}
          </>
        )}
      </div>
    );
  }
}

export default connect<IPropsFromStore, IDispatchProps, any, any>(
  mapStateToProps,
  dispatchProps
)(Filters);
