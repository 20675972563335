import { takeEvery, put } from 'redux-saga/effects';

import { IFilters, IFiltersPayload } from '../types/IFilters';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';

import { goalTrackerTotalsActions } from './goalTrackerTotals.saga';
import { goalTrackerAggregatedDataActions } from './goalTrackerAggregatedData.saga';
import { goalTrackerPaginationActions } from './goalTrackerPagination.saga';
import { goalTrackerGroupedReportsActions } from './goalTrackerGroupedByDateReports.saga';

const resource = 'GOAL_TRACKER_FILTERS';
const actions = ['set'];
type reducerType = IFilters;

export const goalTrackerFiltersTypes = createActionTypes(resource, actions);
export const goalTrackerFiltersActions = createActions<reducerType>(resource, actions);
export const goalTrackerFiltersReducer = createReducers<reducerType>(resource, actions);

function* setGoalTrackerFilters({payload}: IFiltersPayload) {
  yield put(goalTrackerFiltersActions.set.success(payload));
  yield put(goalTrackerPaginationActions.reset.update());
  yield put(goalTrackerGroupedReportsActions.get.load());
  yield put(goalTrackerTotalsActions.get.load());
  yield put(goalTrackerAggregatedDataActions.get.load());
}

export function* goalTrackerFiltersSaga() {
  yield takeEvery(goalTrackerFiltersTypes.set.update, setGoalTrackerFilters);
}
