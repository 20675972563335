import { IProfile } from './IProfile';

export interface IReportContent {
  value: number | null;
  createdAt: string;
  creator: IProfile;
}

export interface IParsedReportsContent {
  type: string;
  content: IReportContent[];
}

export interface IGroupId {
  main: number;
  year: number;
}

export interface IAggregatedData {
  _id: number | IGroupId;
  firstDay: string;
  lastDay: string;
  groupIDKey: string;
}

export interface IPercentAnalyticsData {
  month: number;
  value: number;
}

export enum EReportType {
  doorsKnocked = 'doorsKnocked',
  hoursKnocking = 'hoursKnocking',
  evaluations = 'evaluations',
  insuranceLooks = 'insuranceLooks',
  adjusterMeetings = 'adjusterMeetings',
  approvals = 'approvals',
  projectsTurnedIn = 'projectsTurnedIn',
  dollarsBooked = 'dollarsBooked',
  dollarsClosed = 'dollarsClosed',
  leadsActual = 'leadsActual',
}

export interface IReportData {
  doorsKnocked: number;
  hoursKnocking: number;
  evaluations: number;
  insuranceLooks: number;
  adjusterMeetings: number;
  approvals: number;
  totalColorMeetings: number;
  projectsTurnedIn: number;
  leadsActual?: number;
  dollarsBooked?: number;
  dollarsClosed?: number;
  conversations?: number;
}

export interface IReportRating {
  dayRate?: number;
  amountTime?: number;
  metGoals?: number;
  feelingExplain?: string;
}

export interface IReport extends IReportData, IReportRating {
  _id?: string;
  creator?: string | IProfile;
  createdAt: string;
  updatedAt?: string;
  reportID?: string;
}

export interface IRecentReports {
  dates: string[];
  reports: IReport[];
}

export interface INewReportResponse {
  report: IReport;
  message: string;
}

export interface IReportCalculation {
  name: string;
  value: number;
  unit: string;
}

export enum GroupType {
  day = 'day',
  week = 'week',
  month = 'month',
}

export interface ReportItemByCreator {
  _id: string;
  creator: string;
  actual: number;
}

export interface GroupedReportItem {
  _id: {
    month: number,
    startDay: Date,
    main: number,
    type: string,
    weekOfMonth?: number;
  },
  firstDay: Date,
  lastDay: Date,
  reports: ReportItemByCreator[],
  totalActual: number;
  needs?: number;
  weekOfMonth?: number;
}

export interface IReportsGroupByDate {
  _id: number;
  date?: string;
  firstDay?: string;
  lastDay?: string;
  groupIDKey?: string;
  reports: IReport[];
}

export type TimeRange = {
  min: Date,
  max: Date,
}

export interface IReportsGroupByType {
  type: GroupType;
  creators: { company: string, _id: string }[];
  reports: {
    adjusterMeetings: GroupedReportItem[];
    approvals: GroupedReportItem[];
    dollarsClosed: GroupedReportItem[];
    dollarsBooked: GroupedReportItem[];
    evaluations: GroupedReportItem[];
    insuranceLooks: GroupedReportItem[];
    leadsActual: GroupedReportItem[];
    projectsTurnedIn: GroupedReportItem[];
  }
}
