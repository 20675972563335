import React, { useState } from "react";

import { EReportType, GroupType, GroupedReportItem, IReportData, IReportsGroupByType } from "../../../types/IReport";
import { IUser } from "../../../types/IUser";
import { IProfile } from "../../../types/IProfile";

import styles from "./Table.module.scss";
import { isReady } from "../../../utils/valueState";
import { formatNumber } from "../../../utils/formatNumber";
import { IGoal, IGoalSetting } from "../../../types/IGoal";
import { UserNeedsRow } from "./UserNeedsRow";

interface IProps {
  allGoals: IGoal[];
  goalSetting: IGoalSetting;
  report: GroupedReportItem[];
  users: IUser[];
  creators: { company: string, _id: string }[];
  onUserMouseOver: (user: IUser | IProfile) => void;
  profile: IProfile;
  label: string;
  type: GroupType;
  groupedReports: IReportsGroupByType;
  goalType: EReportType;
  totals: IReportData;
  totalNeeds: number;
}

export const AdminNeedsRow: React.FC<IProps> = ({
  type,
  report,
  profile,
  users,
  creators,
  onUserMouseOver,
  label,
  allGoals,
  goalType,
  groupedReports,
  totals,
  totalNeeds
}) => {
  const [expand, setExpand] = useState<boolean>(false);
  const needToFormatCurrency = (labelRow: string) => !!['Dollars Built', 'Dollars Booked'].find(el => label.includes(el));

  const onClickExpand = () => setExpand(!expand);

  const renderReports = () =>
    creators.map((item) => {
      return (
        <UserNeedsRow
          key={item?._id}
          groupedReport={report}
          groupedReports={groupedReports}
          creator={item}
          profile={profile}
          users={users}
          onUserMouseOver={onUserMouseOver}
          isExpandable={true}
          expand={expand}
          type={type}
          label={label}
          totals={totals}
          goalType={goalType}
          allGoals={allGoals}
        />
      );
    });
  const renderTotalOfActual = () => {
    let minColumns = 0;
    switch (type) {
      case GroupType.day:
        minColumns = 7;
        break;
      case GroupType.month:
        minColumns = 12;
        break;
      case GroupType.week:
        minColumns = 4;
        break;
    }

    if (report.length < minColumns) {
      return report.concat(new Array(minColumns - report.length).fill(0)).map((data, idx) => {
        if(data) {
          let needs = data.needs || 0;
          if(needToFormatCurrency(label)) {
            return <p className={styles.column}>{`${formatNumber(needs, 0, '.', ',')}`}</p>
          }
          return <p className={styles.column}>{needs.toFixed(0) || '0'}</p>
        }
        return <p className={styles.column} key={`total_${label}_goal_${idx}`} />;
      });
    }
    return report.map((data, idx) => {
        let needs = data.needs || 0;
        if(needToFormatCurrency(label)) {
          return <p key={`go_tracker_${idx}`} className={styles.column}>{formatNumber(needs, 0, '.', ',') || '0'}</p>;
        }
        return <p key={`go_tracker_${idx}`} className={styles.column}>{needs.toFixed(0) || '0'}</p>;
    });
  };

  return (
    <div className={`${styles.row} ${styles.rowExpand} ${styles.needs} ${styles.value}`}>
      <p className={styles.column} onClick={onClickExpand}>
        <span className={styles.label} title={label}>
          {label}
        </span>{" "}
        <button className={`${styles.expand} ${expand ? styles.active : ""}`} />
      </p>
      {isReady(report) && renderTotalOfActual()}
      <p className={styles.column}>
        {needToFormatCurrency(label) ? `${formatNumber(totalNeeds, 0, '.', ',')}` : totalNeeds.toFixed(0)}
      </p>
      <div className={`${styles.expandSection} ${expand ? styles.active : ""}`}>
        {expand && renderReports()}
      </div>
    </div>
  );
};
