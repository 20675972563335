import { call, takeEvery, put } from 'redux-saga/effects';

import { API_ENDPOINTS } from '../constants/APi';

import { IReportData } from '../types/IReport';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';
import { http } from '../utils/requests';
import { getStore } from '../store/store';
import { assignFiltersParams } from '../utils/filters.util';

const resource = 'GOAL_TRACKER_TOTALS';
const actions = ['get'];
type reducerType = IReportData;

export const goalTrackerTotalsTypes = createActionTypes(resource, actions);
export const goalTrackerTotalsActions = createActions<reducerType>(resource, actions);
export const goalTrackerTotalsReducer = createReducers<reducerType>(resource, actions);

function* getGoalTrackerTotals() {
  try {
    const { goalTrackerFilters } = getStore();

    const query = goalTrackerFilters ? assignFiltersParams(goalTrackerFilters) : '';
    const response = yield call(http.get, API_ENDPOINTS.TOTALS, undefined, undefined, query);

    yield put(goalTrackerTotalsActions.get.success(response));
  } catch (err) {
    yield put(goalTrackerTotalsActions.get.success({
      leadsActual: 0,
      doorsKnocked: 0,
      hoursKnocking: 0,
      evaluations: 0,
      insuranceLooks: 0,
      adjusterMeetings: 0,
      approvals: 0,
      totalColorMeetings: 0,
      projectsTurnedIn: 0,
      dollarsBooked: 0,
      dollarsClosed: 0,
    }));
  }
}

export function* goalTrackerTotalsSaga() {
  yield takeEvery(goalTrackerTotalsTypes.get.load, getGoalTrackerTotals);
}
