import { ToastContainerProps } from 'react-toastify';

export const toastifyConfig: ToastContainerProps = {
  position: 'bottom-right',
  autoClose: 5000,
  className: 'super-toast',
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  hideProgressBar: true,
  closeOnClick: false,
};
