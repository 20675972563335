import React, { Component, RefObject } from 'react';
import { Chart as ChartJS, ChartOptions } from 'chart.js';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IAggregatedData, IReportData } from '../../../types/IReport';
import { IChartTooltipParams } from '../../../types/IChartTooltip';

import { CHART_DEFAULT_OPTION } from '../../../constants/select';
import { LINE_CHART_CONFIG } from '../../../constants/chart.config';
import { REPORT_CONTENT_COLORS } from '../../../constants/report';

import { formatChartTime } from '../../../utils/dates';

import { tooltipActions } from '../../../reducers/tooltip';

import styles from '../Chart.module.scss';
import { getRandomColor } from '../../../utils/report.util';

interface IOuterProps {
  dataType: string;
  aggregatedData: (IReportData & IAggregatedData)[];
  onChartReady: (chart: Chart) => void;
  onChartLegend: (legend: string) => void;
}

interface IDispatchProps {
  setTooltipParams: (params: IChartTooltipParams) => void;
}

const dispatchProps = (dispatch: Dispatch): IDispatchProps => ({
  setTooltipParams: (payload: IChartTooltipParams) => dispatch(tooltipActions.set(payload)),
});

type IProps = IOuterProps & IDispatchProps;

class ChartLine extends Component<IProps> {
  private chartRef: RefObject<HTMLCanvasElement> = React.createRef();
  private chart: any;
  private chartConfig: ChartOptions = LINE_CHART_CONFIG;

  public componentDidMount(): void {
    this.drawChart();
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if ( prevProps.dataType !== this.props.dataType ) {
      this.clearChart();
      this.updateChart();
    }
  }

  private drawChart = () => {
    this.prepareData();
    if (this.chartRef.current) {
      const ctx: CanvasRenderingContext2D = this.chartRef.current.getContext(
        '2d'
      ) as CanvasRenderingContext2D;

      this.chart = new ChartJS(ctx, {
        type: 'line',
        data: this.prepareData(),
        options: this.getChartOptions(),
      });
      this.props.onChartReady(this.chart);
      this.props.onChartLegend(this.chart.generateLegend());
    }
  };

  private clearChart() {
    this.chart.data.datasets = [];
    this.chart.data.labels = [];
  }

  private updateChart = () => {
    this.chart.data = this.prepareData(this.props.dataType);
    this.chart.update();
  };

  private getChartOptions = (): ChartOptions => ({
    ...this.chartConfig,
    tooltips: {
      intersect: false,
      enabled: false,
      custom: (tooltip: any) => {
        // @ts-ignore
        const chartClientRect: DOMRect = this.chartRef.current.getBoundingClientRect() as DOMRect;

        if (tooltip.opacity === 0) {
          return this.props.setTooltipParams({
            time: '',
            value: '',
            style: {
              opacity: 0,
            },
          });
        }

        if ( tooltip.body ) {
          const positionX = chartClientRect.x;
          const positionY = chartClientRect.y;
          const title = tooltip.title[0];
          const body = tooltip.body[0];

          const leftPos = positionX + tooltip.caretX + 'px';
          const topPos = positionY + tooltip.caretY - tooltip.height + 'px';

          this.props.setTooltipParams({
            time: title,
            value: body.lines.join(),
            style: {
              opacity: 1,
              left: leftPos,
              top: topPos,
            },
          });
        }
      }
    }
  });

  private prepareData = (dataType: string = CHART_DEFAULT_OPTION.value) => {
    const aggregatedData = Object.values(this.props.aggregatedData);
    const colors = aggregatedData.map( () => getRandomColor());

    return {
      labels: aggregatedData.map( data => formatChartTime(data.firstDay, data.groupIDKey)),
      datasets: [{
        data: aggregatedData.map( data => data[dataType]),
        color: colors,
        borderColor: REPORT_CONTENT_COLORS[dataType],
        pointBorderColor: REPORT_CONTENT_COLORS[dataType],
        pointBackgroundColor: REPORT_CONTENT_COLORS[dataType],
      }]
    };
  };

  public render() {
    return (
      <div className={styles.chart}>
        <canvas ref={this.chartRef} />
      </div>
    );
  }
}

export default connect<void, IDispatchProps>(null, dispatchProps)(ChartLine);
