import { AxiosResponse } from 'axios';
import { httpPromise } from './requests';
import { API_ENDPOINTS } from '../constants/APi';
import { EReportType, GroupType, IReportData, IReportsGroupByType } from '../types/IReport';
import { getStore } from '../store/store';
import { assignFiltersParams } from './filters.util';
import { IGoalItem } from '../types/IGoal';
import moment, { Moment } from 'moment';
import { countDaysInWeekOfMonth, getLastDayOfWeeks } from './dates';
import { PERCENT_DOLLAR_BUILT_MONTHLY_QUESTION_ORDER, needFormatWithDollars, needRoundedWholeNumb } from '../constants/goal';

const rows = [
  {
    key: 'leadsActual',
    data: [],
  },
  {
    key: 'evaluations',
    data: [],
  },
  {
    key: 'adjusterMeetings',
    data: [],
  },
  {
    key: 'insuranceLooks',
    data: [],
  },
  {
    key: 'approvals',
    data: [],
  },
  {
    key: 'projectsTurnedIn',
    data: [],
  },
  {
    key: 'dollarsBooked',
    data: [],
  },
  {
    key: 'dollarsClosed',
    data: [],
  },
];

export const exportGoal = (type, isExcludeDeactivated: boolean, usersIDs: string[], year: number): Promise<AxiosResponse> => {
  const { filters } = getStore();

  let query = filters ? assignFiltersParams(filters, '?') : '';
  query += `&type=${type}&year=${year}&excludeDeactivatedGoal=${isExcludeDeactivated}&usersGoal=${usersIDs.join(';')}`;
  return httpPromise.get(API_ENDPOINTS.EXPORT_GOAL, undefined, undefined, query);
};

export const exportGoalTracker = (type: string): Promise<AxiosResponse> => {
  const { goalTrackerFilters } = getStore();
  const defaultQuery = `?type=${type}`;

  const query = goalTrackerFilters ? assignFiltersParams(goalTrackerFilters,  defaultQuery, false) : defaultQuery;

  return httpPromise.get(API_ENDPOINTS.EXPORT_GOAL_TRACKER, undefined, undefined, query);
};

export const exportCompanyProjection = (type: string): Promise<AxiosResponse> => {
  const { companyProjectionFilters } = getStore();
  const defaultQuery = `?type=${type}`;

  const query = companyProjectionFilters ? assignFiltersParams(companyProjectionFilters,  defaultQuery, false) : defaultQuery;

  return httpPromise.get(API_ENDPOINTS.EXPORT_GOAL_TRACKER, undefined, undefined, query);
};

export function countDaysInMonth(momentDate: Moment) {
  return momentDate.daysInMonth();
} 

interface IGetNeeds {
  type: EReportType;
  questions: IGoalItem[];
  dollarBuiltActual: number;
  dollarsClosed: number;
  projectsTurnedIn: number;
  firstDay: Date;
  groupType: GroupType;
}

export const getNeedsValue = (props: IGetNeeds) => {
  const { type, firstDay, groupType, dollarBuiltActual, questions, dollarsClosed, projectsTurnedIn } = props;
  // let DAY_OF_WEEK = countDaysInWeekOfMonth(moment(firstDay).format('M/D/YY')) || 1;

  const month = moment.utc(firstDay).month();
  const year = moment.utc(firstDay).year();
  let DAY_OF_MONTH = 1;
  if (isValidDateFormat(firstDay)) {
    DAY_OF_MONTH = countDaysInMonth(moment(firstDay, 'M/D/YY'));
  } else {
    DAY_OF_MONTH = countDaysInMonth(moment.utc(firstDay));
  }
  switch (groupType) {
    case GroupType.day:
      return getNeedsValueByMonth({
        ...props,
        month,
        year
      }) / DAY_OF_MONTH;
    case GroupType.month:
      return getNeedsValueByMonth({
        ...props,
        month,
        year
      })
    case GroupType.week:
      return getWeekNeeds(props);
    default:
      return getNeedsValueByMonth({
        ...props,
        month,
        year
      })
  }
};

export const getWeekNeeds = ({ type, firstDay, groupType, dollarBuiltActual, questions, dollarsClosed, projectsTurnedIn }: IGetNeeds) => {
  if(questions.length === 0) return 0;
  const matchQuestionUnit = questions.map((question, idx) => {
    if([0, 11, 12, 13].includes(idx)) {
      return question;
    }

    if(idx === PERCENT_DOLLAR_BUILT_MONTHLY_QUESTION_ORDER - 1) {
      return {
        ...question,
        values: question.values?.map(el => ({ ...el, value: el.value / 100 })),
      };
    }

    return {
      ...question,
      value: (question.value || 100) / 100
    };
  });
  
  const month = new Date(firstDay).getUTCMonth();

  const AVERAGE_JOB_SIZE = matchQuestionUnit[12].value || 1;
  const DOLLAR_BUILT_YTD = matchQuestionUnit[11].value || 1;
  const PERCENT_DOLLAR_BUILT_MONTHLY = matchQuestionUnit?.[14]?.values?.find(el => el.month === month)?.value || 0;
  
  let DAY_OF_MONTH = 1;
  let DAY_OF_WEEK = 1;

  if (isValidDateFormat(firstDay)) {
    DAY_OF_MONTH = countDaysInMonth(moment(firstDay, 'M/D/YY'));
    DAY_OF_WEEK = countDaysInWeekOfMonth(firstDay as unknown as string) || 1;
  } else {
    if (groupType === GroupType.week) {
      DAY_OF_WEEK = countDaysInWeekOfMonth(moment.utc(firstDay).format('M/D/YY')) || 1;
    } else {
      DAY_OF_WEEK = countDaysInWeekOfMonth(moment(firstDay).format('M/D/YY')) || 1;
    }
    DAY_OF_MONTH = countDaysInMonth(moment(firstDay));
  }

  let result = 0;
  switch (type) {
    case EReportType.leadsActual:
      result =
          DAY_OF_WEEK *
                (((1.1 *
                  PERCENT_DOLLAR_BUILT_MONTHLY *
                  DOLLAR_BUILT_YTD) *
                  ((matchQuestionUnit[5].value || 0) *
                  (1- (matchQuestionUnit[6].value || 0))
                  + (matchQuestionUnit[6].value || 0)))
                  /
                  ((matchQuestionUnit[3].value || 0) *
                    AVERAGE_JOB_SIZE *
                    (matchQuestionUnit[4].value || 1) *
                  (1 - (matchQuestionUnit[2].value || 0)) *
                    (matchQuestionUnit[6].value || 0) *
                    (matchQuestionUnit[1].value || 0) *
                    DAY_OF_MONTH));
      break;
    case EReportType.evaluations:
      result =
        DAY_OF_WEEK *
          ((1.1 * PERCENT_DOLLAR_BUILT_MONTHLY * DOLLAR_BUILT_YTD) /
            ((matchQuestionUnit[4].value || 1) * AVERAGE_JOB_SIZE * (1 - (matchQuestionUnit[2].value || 0)) * (matchQuestionUnit[1].value || 0) * DAY_OF_MONTH * (matchQuestionUnit[3].value || 0)));
      break;
    case EReportType.insuranceLooks:
      result =
        DAY_OF_WEEK *
          ((1.1 * PERCENT_DOLLAR_BUILT_MONTHLY * DOLLAR_BUILT_YTD) /
            ((matchQuestionUnit[4].value || 1) * AVERAGE_JOB_SIZE * (1 - (matchQuestionUnit[2].value || 0)) * DAY_OF_MONTH * (matchQuestionUnit[3].value || 0)));
      break;
    case EReportType.adjusterMeetings:
      result =
        DAY_OF_WEEK *
          ((1.1 * PERCENT_DOLLAR_BUILT_MONTHLY * DOLLAR_BUILT_YTD) /
            ((matchQuestionUnit[4].value || 1) * AVERAGE_JOB_SIZE * DAY_OF_MONTH * (matchQuestionUnit[3].value || 0)));
      break;
    case EReportType.approvals:
      result =
        DAY_OF_WEEK *
          ((1.1 * PERCENT_DOLLAR_BUILT_MONTHLY * DOLLAR_BUILT_YTD) /
          ((matchQuestionUnit[4].value || 1) * AVERAGE_JOB_SIZE * DAY_OF_MONTH));
      break;
    case EReportType.projectsTurnedIn:
      result =
        DAY_OF_WEEK * ((PERCENT_DOLLAR_BUILT_MONTHLY * DOLLAR_BUILT_YTD) / (AVERAGE_JOB_SIZE * DAY_OF_MONTH));
      break;
    case EReportType.dollarsBooked:
      result =
        DAY_OF_WEEK * ((1.1 * PERCENT_DOLLAR_BUILT_MONTHLY * DOLLAR_BUILT_YTD) / DAY_OF_MONTH)
      break;
    case EReportType.dollarsClosed:
      result =
        DAY_OF_WEEK * ((PERCENT_DOLLAR_BUILT_MONTHLY * DOLLAR_BUILT_YTD) / DAY_OF_MONTH)
      break;
  }
  return (result == Number.POSITIVE_INFINITY || result == Number.NEGATIVE_INFINITY) ? 0 : result;
};

export const getNeedsValueByMonth = ({
  year,
  month,
  type,
  firstDay,
  groupType,
  dollarBuiltActual,
  questions,
  dollarsClosed,
  projectsTurnedIn,
}: IGetNeeds & { year: number; month: number }) => {
  const weekDays = getLastDayOfWeeks(year, month + 1);
  let total = 0;

  weekDays.forEach((weekDay) => {
    const need = getWeekNeeds({
      type,
      firstDay: weekDay.date,
      groupType,
      dollarBuiltActual,
      questions,
      dollarsClosed,
      projectsTurnedIn,
    });

    total += need;
  });

  return total;
};

export const getNeedsValueByDay = ({}: IGetNeeds) => {
  
}

export const getGoalValue = (totals: IReportData, goalOrder): number | string => {
  const { leadsActual, hoursKnocking, evaluations, insuranceLooks, projectsTurnedIn, approvals, adjusterMeetings, dollarsClosed } = totals;
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
  });
  
  switch (goalOrder) {
    case 1:
      return hoursKnocking && evaluations ? (hoursKnocking / evaluations).toFixed(2) : 0;
    case 2:
      return insuranceLooks && evaluations ? ((insuranceLooks / evaluations) * 100).toFixed(2) : 0;
    case 3:
      return insuranceLooks && adjusterMeetings ? (((insuranceLooks - adjusterMeetings) / insuranceLooks) * 100).toFixed(2) : 0;
    case 4:
      return approvals && adjusterMeetings ? ((approvals / adjusterMeetings) * 100).toFixed(2) : 0;
    case 5:
      return projectsTurnedIn && approvals ? ((projectsTurnedIn / approvals) * 100).toFixed(2) : 0;
    case 6:
      return '34.00';
    case 7:
      return '67.00';
    case 8:
      return '32.00';
    case 9:
      return '19.00';
    case 10:
      return '19.00';
    case 11:
      return '10.00';
    case 12:
      return dollarsClosed ? USDollar.format(dollarsClosed) : 0;
    case 13:
      return dollarsClosed && projectsTurnedIn ? USDollar.format(Math.round((dollarsClosed / projectsTurnedIn))) : 0;;
    case 14:
      return projectsTurnedIn ? projectsTurnedIn.toFixed(0) : 0;
    default:
      return 0;
  }
}

export const formatDollarsGoal = (goalValue: number, goalOrder: number, editing: boolean) => {
  if(editing) {
    if(needRoundedWholeNumb.includes(goalOrder)) {
      return Number(Math.round(goalValue));
    };

    return goalValue;
  }
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
  });

  if(needFormatWithDollars.includes(goalOrder)) {
    return USDollar.format(Math.round(goalValue))
  } else if(needRoundedWholeNumb.includes(goalOrder)) {
    return Number(Math.round(goalValue));
  };

  return goalValue;
}

export const mappingGroupedReportObject = (groupedReports: IReportsGroupByType) => {
  const reports = groupedReports.reports;
  let result: any[] = [];
  result = rows.map(row => ({
    ...row,
    data: reports[row.key],
  }))

  return result;
}

const isValidDateFormat = (dateString) => {
  // Regular expression to check format date M/D/YY
  const dateFormatRegex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{2}$/;
  return dateFormatRegex.test(dateString);
}