import { IOption } from '../types/ISelect';
import { THEME } from './color';
import { APP_CONSTANTS } from './global';

export const CHART_SELECTOR_OPTIONS: IOption[] = [
  {
    value: 'hoursKnocking',
    label: 'Hours Knocking',
  },
  {
    value: 'doorsKnocked',
    label: 'Doors Knocked',
  },
  {
    value: 'conversations',
    label: 'Conversations',
  },
  {
    value: 'leadsActual',
    label: 'Leads Actual',
  },
  {
    value: 'evaluations',
    label: 'Evaluations',
  },
  {
    value: 'insuranceLooks',
    label: 'Insurance Looks',
  },
  {
    value: 'adjusterMeetings',
    label: 'Adjuster Meetings',
  },
  {
    value: 'approvals',
    label: 'Approvals',
  },
  {
    value: 'totalColorMeetings',
    label: 'Total Color Meetings',
  },
  {
    value: 'projectsTurnedIn',
    label: 'Projects Turned in',
  },
  {
    value: 'dollarsBooked',
    label: 'Dollars Booked',
  },
  {
    value: 'dollarsClosed',
    label: 'Dollars Closed',
  },
];

export const ACCESS_LEVEL_SELECTOR_OPTIONS: IOption[] = [
  {
    value: APP_CONSTANTS.ACCESS_LEVELS.EMPLOYEE,
    label: 'Sales Person',
  },
  {
    value: APP_CONSTANTS.ACCESS_LEVELS.SUPER_ADMIN,
    label: 'Super Admin',
  },
  {
    value: APP_CONSTANTS.ACCESS_LEVELS.COMPANY_ADMIN,
    label: 'Company Admin',
  },
  {
    value: APP_CONSTANTS.ACCESS_LEVELS.SENIOR_SALES_MANAGER,
    label: 'Senior Sales Manager',
  },
  {
    value: APP_CONSTANTS.ACCESS_LEVELS.SALES_MANAGER,
    label: 'Sales Manager',
  },
];

export const CHART_DEFAULT_OPTION: IOption = CHART_SELECTOR_OPTIONS[0];
export const ACCESS_LEVEL_DEFAULT_OPTION: IOption = ACCESS_LEVEL_SELECTOR_OPTIONS[0];

export const REPORT_SCHEDULE_OPTIONS: IOption[] = [
  {
    value: 0,
    label: 'Daily',
  },
  {
    value: 1,
    label: 'Weekly',
  },
  {
    value: 2,
    label: 'Monthly',
  }
];

export const THEME_OPTIONS: IOption[] = [
  {
    value: THEME.LIGHT,
    label: 'Light',
  },
  {
    value: THEME.DARK,
    label: 'Dark',
  },
];

export const THEME_DEFAULT_OPTION: IOption = THEME_OPTIONS[0];