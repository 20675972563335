import * as firebase from 'firebase/app';
import '@firebase/messaging';

import { APP_CONSTANTS } from '../constants/global';

const firebaseConfig = {
  apiKey: "AIzaSyAm8y1MoF_-Top8xV1QSZ5znL7cKHcoTXA",
  projectId: "superstorm-performance-tracker",
  messagingSenderId: '524075937049',
  appId: "1:524075937049:web:0874201b8d8f1ea2c36988"
};

export const initializeFirebase = () => {
  firebase.initializeApp(firebaseConfig);
};

export const askNotificationsPermissions = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();

    return await messaging.getToken();
  } catch (error) {}
};

export const deleteFirebaseToken = async () => {
  if ( isTokenExist() ) {
    try {
      const messaging = firebase.messaging();

      await messaging.deleteToken(localStorage.getItem(APP_CONSTANTS.FIREBASE_STORAGE_TOKEN_NAME) as string);

      localStorage.removeItem(APP_CONSTANTS.FIREBASE_STORAGE_TOKEN_NAME);
    } catch (error) {}
  }
};

export const setToken = (token: string): void =>
  localStorage.setItem(APP_CONSTANTS.FIREBASE_STORAGE_TOKEN_NAME, token);

export const isTokenExist = (): boolean => !!localStorage.getItem(APP_CONSTANTS.FIREBASE_STORAGE_TOKEN_NAME);
