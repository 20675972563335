export const TABS = {
  COMPANIES: {
    number: 0,
    name: 'Companies',
  },
  USERS: {
    number: 1,
    name: 'Users',
  },
};

export const ADMIN_TIME_FORMAT = 'MM.DD.YYYY';
