import React from 'react';

import { IUser } from '../../../types/IUser';
import { GroupType, GroupedReportItem } from '../../../types/IReport';
import { IProfile } from '../../../types/IProfile';

import { isReady } from '../../../utils/valueState';
import { isSuperAdmin } from '../../../utils/user.util';

import styles from './Table.module.scss';
import { formatNumber } from '../../../utils/formatNumber';

interface IProps {
  users: IUser[];
  onUserMouseOver: (user: IUser | IProfile) => void;
  profile: IProfile;
  creator: { company: string, _id: string};
  groupedReport: GroupedReportItem[];
  isExpandable?: boolean;
  expand: boolean;
  type: GroupType;
  label: string;
}

export const UserRow: React.FC<IProps> = ({ label, type, users, creator, groupedReport, onUserMouseOver, profile, isExpandable, expand }) => {
  const user =
    (isReady(users) && users.find(user => user._id === creator?._id)) || profile;

  const needToFormatCurrency = (label: string) => ['Dollars Built', 'Dollars Booked'].find(el => label.includes(el));

  const renderValue = () => {
    if(isReady(creator) && isReady(groupedReport)) {
      let minColumns = 0;
      switch (type) {
        case GroupType.day:
          minColumns = 7;
          break;
        case GroupType.month:
          minColumns = 12;
          break;
        case GroupType.week:
          minColumns = 4;
          break;
      }
      if(groupedReport.length < minColumns) {
        return groupedReport.concat(new Array(minColumns - groupedReport.length).fill(0)).map((groupedItem) => {
          if(groupedItem) {
            const itemMatch = groupedItem.reports.find(r => r.creator === creator?._id);
            if(itemMatch) {
              if(needToFormatCurrency(label)) {
                return <p className={styles.column}>{itemMatch.actual ? `${formatNumber(itemMatch.actual, 0, '.', ',')}` : ''}</p>
              }
              return (<p className={styles.column}>{itemMatch.actual || 0}</p>);
            } else {
              return (<p className={styles.column}>0</p>);
            }
          }
          return (<p className={styles.column}></p>);
        });
      }
      return groupedReport.map((groupedItem) => {
        const itemMatch = groupedItem.reports.find(r => r.creator === creator?._id);
        if(itemMatch) {
          if(needToFormatCurrency(label)) {
            return <p className={styles.column}>{itemMatch.actual ? `${formatNumber(itemMatch.actual, 0, '.', ',')}` : '0'}</p>
          }
          return (<p className={styles.column}>{itemMatch.actual || 0}</p>);
        } else {
          return (<p className={styles.column}>0</p>);
        }
      })
    }
  };

  const total = React.useMemo(() => {
    let initial = 0;
    if(isReady(creator) && isReady(groupedReport)) {
      groupedReport.map((groupedItem) => {
        const itemMatch = groupedItem.reports.find(r => r.creator === creator?._id);
        if(itemMatch) {
          initial += itemMatch.actual;
        }
      })
    };

    return initial;
  }, []);

  return (
    <div className={`${styles.row} ${styles.value}`}>
      <p className={styles.column}>
        {/* {!isExpandable && formatReportTimeShort(report.createdAt)} */}
        {!isExpandable && isSuperAdmin(profile) && user && (
          <span
            className={styles.user}
            data-tip="user"
            onMouseOver={() => onUserMouseOver(user)}
          >
            {user.profileImg ? (
              <img src={user.profileImg} alt={user.profileImg} />
            ) : (
              <span>
                {user.firstName
                  ? user.firstName.charAt(0)
                  : user.username
                  ? user.username.charAt(0)
                  : user.email.charAt(0)}
              </span>
            )}
          </span>
        )}
        {isExpandable && user && (
          <span
            className={styles.userMail}
            data-tip="user"
            onMouseOver={() => onUserMouseOver(user)}
          >
            {user.email}
          </span>
        )}
      </p>
      {expand ? (
        <>
          {renderValue()}
          <p className={styles.column}>{total}</p>
        </>
      ) : null}
    </div>
  );
};
