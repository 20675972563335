import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import styles from './Dropzone.module.scss';

interface IProps {
  onImgDropped: (file: File | null) => void;
  isImage: boolean;
  profileImg: string | null | undefined;
  title?: string;
  alignLeft?: boolean;
}

const IMG_TYPES = 'image/jpeg, image/svg, image/png';

export const Dropzone: React.FC<IProps> = ({
  onImgDropped,
  isImage,
  profileImg,
  title,
  alignLeft,
}) => {
  const profileImgLink = profileImg || '';

  const [preview, setPreview] = useState(profileImgLink);
  const accept = isImage ? IMG_TYPES : '';

  const onDrop = useCallback(
    files => {
      const file = files[0];

      if (file) {
        const reader = new FileReader();

        reader.onabort = () => toast.error('File reading was aborted');
        reader.onerror = () => toast.error('File reading has failed');
        reader.onload = () => onImgDropped(file);

        if (isImage) {
          const readerPreview = new FileReader();

          readerPreview.onload = () => {
            // @ts-ignore
            setPreview(readerPreview.result);
          };

          readerPreview.readAsDataURL(file);
        }

        reader.readAsDataURL(file);
      }
    },
    [onImgDropped, isImage]
  );

  const onDropRejected = files =>
    toast.error(`${files[0]?.name} can not be uploaded`);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    onDropRejected
  });

  const buttonClickFix = event => event.stopPropagation();

  const resetDropzone = () => {
    setPreview('');
    onImgDropped(null);
  };

  return (
    <div className={`${styles.dropzone} ${alignLeft ? styles.alignLeft : ''}`}>
      <p className={styles.title}>{title || 'Your photo'}</p>
      {preview ? (
        <div className={`${styles.imgArea} ${styles.preview}`}>
          <img src={preview} alt="Preview" />
        </div>
      ) : (
        <label
          className={`${styles.imgArea} ${isDragActive ? styles.active : ''}`}
          {...getRootProps()}
          onClick={buttonClickFix}
        >
          <input type="file" {...getInputProps({ multiple: false })} />
          <p className={styles.imgAreaPlaceholder}>drag or select your image</p>
          <span className={styles.imgAreaPlusIcon} />
        </label>
      )}
      <button
        disabled={!preview}
        className={styles.btn}
        onClick={resetDropzone}
      >
        <span className={styles.btnIcon} />
        Delete
      </button>
    </div>
  );
};
