export const GOAL_QUESTIONS = [
  {
    order: 1,
    description: 'How many hours of door knocking does it take to generate one Inspection? Standard: 1 per hour',
  },
  {
    order: 2,
    description: 'What is your Closing Percentage? Signed Contracts divided by Inspections.',
  },
  {
    order: 3,
    description: 'What percentage of your Signed Contracts do not get an Adjuster Meeting?',
  },
  {
    order: 4,
    description: 'What is your Adjuster Approval Percentage? Approved jobs divided by the number of Adjuster Meetings ran. Standard: Fresh Storm 70% Marginal/Older Storm 50%',
  },
  {
    order: 5,
    description: 'What is your Net Job Percentage? Built Jobs Turned In divided by Adjuster Approved Jobs. Standard: 90%',
  },
  {
    order: 6,
    description: 'What percentage of your Inspection Appointments will come from PAID MARKETING? (all non-door knocking leads only) Standard: 30%',
  },
  {
    order: 7,
    description: 'What is your Appointment Set up Percentage? Total Inspections (all non-door knocking leads only) divided by Appointments Set. Standard: 35%',
  },
  {
    order: 8,
    description: 'What is your Materials percentage? Total Materials divided by Gross Sales (include all permit and other misc costs. DO NOT include Eagle View.',
  },
  {
    order: 9,
    description: 'What is your labor percentage? Total labor divided by Gross Sales (include anything paid to subcontractor IE: gas card).',
  },
  {
    order: 10,
    description: 'What is the percentage of gross sales you charge for Overhead on your Capp Sheets?',
  },
  {
    order: 11,
    description: 'Percentage of coverage or carryover of $ booked but not built',
  },
  {
    order: 12,
    description: 'Dollars Built YTD',
  },
  {
    order: 13,
    description: 'Average Job Size',
  },
  {
    order: 14,
    description: 'Jobs Built',
  },
  {
    order: 15,
    description: 'Goal: Percent Dollars Built Monthly',
  },
  {
    order: 16,
    description: 'Analytics: Percent Dollar Built Monthly',
  },
];

export const PERCENT_DOLLAR_BUILT_MONTHLY_QUESTION_ORDER = 15;
export const PERCENT_DOLLAR_BUILT_MONTHLY_QUESTION_FOR_ANALYTICS_ORDER = 16;
export const DOLLARS_BUILT_YTD_ORDER = 12;
export const AVERAGE_JOB_SIZE_ORDER = 13;
export const JOB_BUILT_ORDER = 14;

export const needRoundedWholeNumb = [DOLLARS_BUILT_YTD_ORDER, AVERAGE_JOB_SIZE_ORDER, JOB_BUILT_ORDER];
export const needFormatWithDollars = [DOLLARS_BUILT_YTD_ORDER, AVERAGE_JOB_SIZE_ORDER];