import { call, takeEvery, put } from 'redux-saga/effects';

import { API_ENDPOINTS } from '../constants/APi';

import { IReportData } from '../types/IReport';

import { createActions, createReducers, createActionTypes } from '../utils/reduxBoilerplate';
import { http } from '../utils/requests';
import { getStore } from '../store/store';
import { assignFiltersParams } from '../utils/filters.util';

const resource = 'COMPANY_PROJECTION_TOTALS';
const actions = ['get'];
type reducerType = IReportData;

export const companyProjectionTotalsTypes = createActionTypes(resource, actions);
export const companyProjectionTotalsActions = createActions<reducerType>(resource, actions);
export const companyProjectionTotalsReducer = createReducers<reducerType>(resource, actions);

function* getCompanyProjectionTotals() {
  try {
    const { companyProjectionFilters } = getStore();

    const query = companyProjectionFilters ? assignFiltersParams(companyProjectionFilters) : '';
    const response = yield call(http.get, API_ENDPOINTS.TOTALS, undefined, undefined, query);

    yield put(companyProjectionTotalsActions.get.success(response));
  } catch (err) {
    yield put(companyProjectionTotalsActions.get.success({
      leadsActual: 0,
      doorsKnocked: 0,
      hoursKnocking: 0,
      evaluations: 0,
      insuranceLooks: 0,
      adjusterMeetings: 0,
      approvals: 0,
      totalColorMeetings: 0,
      projectsTurnedIn: 0,
      dollarsBooked: 0,
      dollarsClosed: 0,
    }));
  }
}

export function* companyProjectionTotalsSaga() {
  yield takeEvery(companyProjectionTotalsTypes.get.load, getCompanyProjectionTotals);
}
