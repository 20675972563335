export const APP_CONSTANTS = {
  LOCAL_STORAGE_TOKEN_NAME: btoa('u-tok'),
  ACCESS_LEVELS: {
    EMPLOYEE: 0,
    SUPER_ADMIN: 1,
    COMPANY_ADMIN: 2,
    SENIOR_SALES_MANAGER: 3,
    SALES_MANAGER: 4,
  },
  TIME_ZONE: 'America/Chicago',
  MAX_RECENT_REPORTS_ON_DASHBOARD: 7,
  FIREBASE_STORAGE_TOKEN_NAME: btoa('f-tok'),
};
